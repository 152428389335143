import axios from 'axios';
import React, { useState } from 'react'
import { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom'
import { API_BASE_LINK } from '../API_BASE_PATH';
import { format, parseISO } from 'date-fns';
import OTPInput from 'react-otp-input';
import Timer from './Timer';
import { Checkbox, Snackbar } from '@mui/material';
export default function TicketDetails() {
    const params = useParams();
    const navigate = useNavigate();
    const [ticketDetails, setTicketDetails] = useState(null);
    const [PageParamsData, setPageParamsData] = useState(null);
    const [IsOptBoxOpen, setIsOptBoxOpen] = useState(false);
    const [OTP_Value, setOTP_Value] = useState(null);
    const [UPI_ID, setUPI_ID] = useState('');
    const [IsOTPverify, setIsOTPverify] = useState(null);

    const [IsChecked, setIsChecked] = useState(true);

    useEffect(() => {

        setPageParamsData(JSON.parse(window.atob(params?.id)));
        let p_data = JSON.parse(window.atob(params?.id));
        // console.log(p_data?.i)
        axios.get(`${API_BASE_LINK}flight/details/${p_data?.i}`)
            .then(function (response) {
                if (response?.status === 200) {
                    setTicketDetails(response?.data);
                    console.log(response?.data);
                }
            })
            .catch(function (error) {
                // handle error
                console.log(error);
            });

    }, []);


    const getOtp = (data) => {
        console.log(data);
        setIsOptBoxOpen(true);
        axios.post(`${API_BASE_LINK}flight/request_cancel`, {
            "booking_id": data.id,
            "pnr": data.pnr
        })
            .then((response) => {
                console.log(response);
                if (response.status === 200) {
                    // setEnyterOtp(true);
                    // setIsOptRecived(true)
                } else {

                }

            })
            .catch((error) => {

            });
    }

    useEffect(() => {
        console.log(OTP_Value);

    }, [OTP_Value]);


    const FlightCancel = (data) => {
        // console.log(otp);



        if (OTP_Value.length === 6) {
            setIsOptBoxOpen(false);
            setIsOTPverify('Your request was sent. Process ongoing, please wait...');
            let p_data = JSON.parse(window.atob(params?.id));
            axios.post(`${API_BASE_LINK}flight/cancel`, {
                "booking_id": p_data?.i,
                "type": p_data?.t,
                "otp": OTP_Value,
                "upi": UPI_ID
            })
                .then((response) => {
                    console.log(response);
                    if (response?.status === 200) {
                        setIsOTPverify('Your ticket has been cancelled. Your money will be refunded within 7 working days.');

                        window.location.reload();
                    } else {
                        setIsOTPverify(response?.data?.error);
                        setIsOptBoxOpen(true);
                    }

                })
                .catch((error) => {
                    console.log(error);
                    setIsOTPverify(error?.response?.data?.error);
                    setIsOptBoxOpen(true);
                });
        }
    }

    return (
        <section className='Ticket_details py-4 py-md-5'>
            <div className="container">
                <div className="row">
                    <div className="col-md-6 mx-auto">
                        {ticketDetails === null ?
                            <><p className='text-center'>Loading...</p></>
                            :
                            <>
                                <div className="ticket_card">
                                    <div className="part-1">
                                        {ticketDetails?.is_journey_complete === true && <h3 className='rounded text-center bg-success py-3'>
                                            Journey completed

                                        </h3>
                                        }
                                        <h5>Dear {ticketDetails?.Passangers[0]?.first_name}</h5>
                                        <p>Your Booking is  <b>{ticketDetails?.status}</b></p>
                                        {ticketDetails?.status === 'PENDING_REFUND' && <p className='bg-danger p-2 rounded text-center'>
                                            This flight has been cancelled</p>}

                                    </div>
                                    <div className="part-2">
                                        <div className="d-flex justify-content-between">
                                            <p>
                                                Itinerary generated on: <br /> <small>
                                                    {format(parseISO(ticketDetails?.createdAt), 'EE, MMM dd yyyy, HH:MM')}
                                                </small>
                                            </p>
                                            <p className='text-end'>
                                                Booked on: <br /> <small>
                                                    {format(parseISO(ticketDetails?.createdAt), 'EE, MMM dd yyyy, HH:MM')}
                                                </small>
                                            </p>
                                        </div>
                                    </div>

                                    <div className="part-3">
                                        <div className="part-3-inner">
                                            <div className="part_head">
                                                <div className="d-flex justify-content-between">
                                                    <div className=''>
                                                        {/* {ticketDetails?.airline_code}   {data?.craft} */}
                                                        {PageParamsData?.t === 1 && ticketDetails?.from_city + '-' + ticketDetails?.to_city}
                                                        {PageParamsData?.t === 2 && ticketDetails?.to_city + '-' + ticketDetails?.from_city}
                                                    </div>
                                                    <div className=''>

                                                        {PageParamsData?.t === 1 && format(parseISO(ticketDetails?.journey_date), 'EE, dd MMM yyyy')}
                                                        {PageParamsData?.t === 2 && format(parseISO(ticketDetails?.return_date), 'EE, dd MMM yyyy')}


                                                    </div>
                                                    <div className="">
                                                        PNR:
                                                        <b>{PageParamsData?.t === 1 && ticketDetails?.pnr}</b>
                                                        <b>{PageParamsData?.t === 2 && ticketDetails?.return_pnr}</b>
                                                    </div>
                                                </div>
                                            </div>
                                            {ticketDetails?.Segments?.filter((data) => data.result_index === (PageParamsData?.t === 1 ? ticketDetails?.flight_index : ticketDetails?.return_flight_index)).map((data, index) => (
                                                <React.Fragment key={index + 'in'}>
                                                    <div className="part_body">
                                                        <div className="d-flex align-items-center justify-content-between">
                                                            <div className="t1">
                                                                <h6>{format(parseISO(data?.dep_time), 'HH:MM')}</h6>
                                                                <p>{format(parseISO(data?.dep_time), 'dd MMM yyyy')}</p>

                                                                <h4>{data?.origin_airport_code}</h4>
                                                                <p className='mb-0'>{data?.origin_airport_name}</p>
                                                                <p>{data?.origin_city_name}, T-{data?.origin_terminal && data?.origin_terminal}</p>
                                                            </div>
                                                            <div className="tm text-center">
                                                                {/* 2h 0m */}
                                                                {Math.floor(data?.duration / 60)}h {data?.duration % 60}m
                                                                <p><small>{data.airline_name}</small>   <small><small>{data.airline_code} {data.craft}</small></small></p>
                                                            </div>
                                                            <div className="t1 text-end">
                                                                <h6>{format(parseISO(data?.arr_time), 'HH:MM')}</h6>
                                                                <p>{format(parseISO(data?.arr_time), 'dd MMM yyyy')}</p>
                                                                <h4>{data?.destination_airport_code}</h4>
                                                                <p className='mb-0'>{data?.destination_airport_name}</p>
                                                                <p>{data?.destination_city_name}, T-{data?.destination_terminal && data?.destination_terminal}</p>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div className="part_end text-center">
                                                        <p>{data?.cabin_baggage} Cabin and {data?.baggage} Check-in	</p>
                                                    </div>
                                                </React.Fragment>))}

                                        </div>
                                    </div>

                                    <div className="part_footer mt-3">
                                        {ticketDetails?.Passangers?.map((data, index) => (
                                            <p key={index + 'p'}>{index + 1}) {data?.title}. {data?.first_name}  {data?.last_name}</p>

                                        ))}
                                    </div>
                                    {IsOptBoxOpen &&
                                        <div className="part-5 mt-3">
                                            <div className="fadeIn">
                                                <h4>OTP Verification</h4>
                                                <p className='mb-0'>Enter the OTP you recieved at</p>
                                                <h5 className='mb-4'>+91 ******{(ticketDetails?.Passangers[0]?.contact_no).slice(-4)}</h5>
                                            </div>

                                            <div className="fadeIn">
                                                <div className="d-inline-block otpInputBox">
                                                    <OTPInput
                                                        value={OTP_Value}
                                                        onChange={setOTP_Value}
                                                        numInputs={6}
                                                        renderSeparator={<span className='px-2'>&#160;</span>}
                                                        renderInput={(props) => <input  {...props} className='form-control otpInput' />}
                                                    />
                                                </div>
                                                {OTP_Value?.length === 6 &&
                                                    <div className="upi pt-4 pb-3 fadeIn">
                                                        <label className='d-block mb-2'>* Enter your UPI ID to get refundable money.</label>
                                                        <input
                                                            type="text"
                                                            className='form-control'
                                                            value={UPI_ID}
                                                            onChange={(e) => setUPI_ID(e.target.value)}
                                                            placeholder='Enter UPI ID'
                                                        />
                                                    </div>}
                                            </div>
                                            <p className='pt-4'>
                                                <Checkbox
                                                    sx={{
                                                        color: '#eee',
                                                        '&.Mui-checked': {
                                                            color: '#fff',
                                                        },
                                                    }}

                                                    checked={IsChecked}

                                                    onChange={(e) => setIsChecked(e.target.checked)}
                                                />

                                                <span>
                                                    <span>I agree with the <a className="ancher_hover" href="/terms-and-conditions" target="_blank">terms &amp; conditions</a>,  <a className="ancher_hover" href="/privacy-policy" target="_blank">privacy policy</a>, <a className="ancher_hover" href="/cancellation-policy" target="_blank">cancel policy</a> and I understand and accept the <a className='ancher_hover' href="/refund-policy" target="_blank">refund policy</a> .</span>
                                                </span>
                                            </p>
                                            <p className='pt-3'>Verify otp in <Timer getDate={300} />.</p>

                                            <div className="d-flex btns justify-content-around py-3">
                                                <div className="rs">
                                                    <span onClick={() => (
                                                        getOtp({
                                                            id: ticketDetails.id, pnr: PageParamsData.t === 1 ?
                                                                ticketDetails.pnr : ticketDetails.return_pnr
                                                        }),
                                                        setOTP_Value(null)
                                                    )}>Resend OTP</span>
                                                </div>
                                                {OTP_Value?.length === 6 && UPI_ID?.length > 4 && IsChecked && <div className="ls">
                                                    <span onClick={() => FlightCancel()}>Send Request</span>
                                                </div>}
                                            </div>


                                        </div>
                                    }

                                    {IsOTPverify !== null && <div className="part-6">
                                        <p>{IsOTPverify}</p>
                                    </div>}
                                    <div className="part-4">
                                        <div className="d-flex justify-content-between">
                                            <div className="a1">
                                                <span onClick={() => navigate(-1)} className='cp'>Back</span>
                                            </div>
                                            <div className="a1">
                                                {ticketDetails?.status == 'Confirmed' &&
                                                    <>
                                                        {IsOptBoxOpen === true ?
                                                            <span onClick={() => (setIsOptBoxOpen(false), setOTP_Value(null), setUPI_ID(null))}>Cancel Request</span>
                                                            :
                                                            ticketDetails?.is_journey_complete === true ? 'Journey completed' : <span className='bg-danger' onClick={() => getOtp({ id: ticketDetails.id, pnr: PageParamsData.t === 1 ? ticketDetails.pnr : ticketDetails.return_pnr })}>Cancel Flight</span>}
                                                    </>}

                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </>
                        }

                    </div>
                </div>
            </div>

            {IsOptBoxOpen &&
                <>
                    {OTP_Value?.length !== 6 && <SnackErrorCard data='Step 1: Enter 6 digit OTP.' />}
                    {OTP_Value?.length === 6 && UPI_ID?.length < 4 && <SnackErrorCard data='Step 2: Enter your UPI ID.' />}
                    {OTP_Value?.length === 6 && UPI_ID?.length > 4 && !IsChecked && <SnackErrorCard data='Step 3: Please click on I agree checkbox. ' />}
                </>
            }
        </section >
    )
}


const SnackErrorCard = ({ data }) => {
    return (
        <>
            <Snackbar
                open={true}
                anchorOrigin={{
                    vertical: "top",
                    horizontal: "right"
                }}
            >
                <p className='bg-warning p-2 text-dark rounded-2'>
                    {data}
                </p>
            </Snackbar>
        </>
    )
}