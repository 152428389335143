import React from 'react'

export default function PrivacyPolicy() {
    return (
        <div className='tc_cms_pages_wrapper  py-5 animate__animated animate__fadeIn'>

            <div className="container">

                <div className='main'>
                    <h1><span>Privacy Policy</span></h1>
                    <h3>Privacy Policy View :</h3>

                    <p>Navchr Online compliments your privacy and identifies the need to protect the personally recognizable information (any info by which you can be recognized, such as name, gender, email Id, personal address, normal fly number, age, mobile number etc.) you share with us. We would like to assure you that we follow suitable values when it comes to defensive your privacy through our diverse networks.</p>
                    <p>Any personal recognizable info you submit while booking with Navchr Online will only be revealed to travel dealers who are linked to your booking. We will not reveal, sell and share or in any way reveal your info to any other third person. We want you to feel confident about using Navchr Online to idea and buying your airlines ticket, so we are dedicated to defensive the info we collect. While no Website can assurance safety, we have applied suitable administrative, technical, and physical safety procedures to help protect the private material you provide to us. To prevent unofficial access, maintain data accuracy, and ensure the correct use of info, we will employ sensible and current Internet safety methods and knowledges.</p>
                    <p>This rule is applicable to persons who buying / intend to buying / query about any product and service made accessible by navchr online user through any of navchr online client interface networks including website, mobile site including our customer care office.</p>
                    <p>By using or editing the Website or other sales Networks, the user hereby agrees with the terms of this Rule and the contents herein. If you affect with this Rule, pl do not use or access our website or other sales networks.</p>
                    <p>This idea does not apply to any site of third parties, even if their websites/products are linked to our website. Users should take note that info and privacy follow of navchr online commercial partners, patrons, backers /other sites to which Navchr Online delivers a link, may be highly diverse from this rule. So, it is optional that you review the privacy reports and plans of any such third parties.</p>

                    <h3>navChr online Collect this Info :</h3>

                    <p>With precise orientation to reservation/e-commerce dealings, Navchr Online collects the next personal sensitive info/ data from you while conduct through Navchr Online :</p>
                    <ul>
                        <li>Name</li>
                        <li>Gender</li>
                        <li>Mobile Number</li>
                        <li>Email ID</li>
                        <li>Address</li>
                        <li>Date of Birth</li>
                    </ul>

                    <h3>How the Info is used:</h3>

                    <p>Navchr Online do not sell or trade upon any of the above foregoing info without the consent of the user/ client. The previous info composed from the users is put to the following use :</p>
                    <ul>
                        <li>User name, address, phone number, user name, and age are shared with applicable facility providers like the airlines etc, for the purpose of booking the facilities for the user.</li>
                        <li>Info of online transition like credit card details, net banking details are usually collected directly by the payment gateways and banks and not share by navchr online, but if ever stored or reserved by us, remain inner and is never shared.</li>
                        <li>This detail is also shared with certain third parties only for the purpose of processing cash back, discounts' and charge backs, if applicable.</li>
                        <li>Info like Mob no, Email Id and postal address may be used for promotional purposes, unless the user optout of such use.</li>
                        <li>If you take not to share this info, you can still visit the Navchr Online website but you may be unable to avail of sure options and facilities.</li>
                    </ul>

                    <h3>Cookies, Discussion Data :</h3>
                    <p>In general, you can visit the navchr online site without telling us who you are or revealing any personal info about yourself. We track the Internet address of the domains from which people visit us and analyse this data for trends and statistics, but the individual user remains anonymous.</p>
                    <p>Some of our web pages use "cookies" so that we can better serve you with modified info when you return to our site. Cookies are identifiers that websites send to the browser on your computer to facilitate your next visit to our site. You can set your browser to notify you when you are sent a cookie, giving you the option to decide whether or not to accept it. The info we collect and analyse is used to improve our service to you.</p>

                    <h3>Auto Login of Session Data :</h3>

                    <p>Each time you access the website your session data gets logged. Session data consists of the user IP address, operating system, and type of browser software being used and the activities conducted by the user while on the website. We collect session data because it helps us analyse user's choices, browsing patterns including the frequency of visits and duration for which a user is log on. It also helps us identify problems with our servers and lets us better administer our schemes. The aforesaid info cannot identify any user personally. However, it is possible to determine a user Internet Service Provider (ISP), and the estimated physical place of the User point of connectivity from the IP address.</p>

                    <h3>Personal Info is Share :</h3>

                    <p>With specific reference to third party non personally recognizable info publicizing, we use third party facility providers to serve ads on our behalf across the Internet and sometimes on this site. They may collect anonymous info about your visits to our website, and your interaction with our products and services. They may also use info about your visits to this and other websites to target ads for goods and services. <b> This unnamed info is collected through the use of a </b> 'pixel ta’,<b> which is industry average knowledge used by most main websites </b>. No personally identifiable info is calm or used in this process. Such third parties do not know your name, phone number, address, email Id, or any personally classifying info.
                        If you would like more info about this practice and to know your choices about not having this unsigned info used by our third party facility provider, please email us at <i><u> support@navchr.com</u></i>.
                    </p>
                    <p><b> <u>Note</u></b> : Even after your account is completed, we retain your data for as long as we have a genuine purpose to do so agreement with applicable law, including to assist with legal duties, resolve disputes, and enforce our arrangements. We may retain and disclose such data pursuant to this Privacy Policy after your acct has been completed.</p>

                    <h3>Discovery of info :</h3>
                    <p>We may, in good trust, in our only choice and without any notice to you , disclose your personal sensitive info to protect ourselves from any obligation or scam or similar condition, to respond to judicial methods, genuine requests from any third parties, warrants or equal by law execution trials or authority, to consider fraud or other crime or as otherwise required or necessary in order to comply with valid law or to protect our legitimate interests or those of users. We may also, disclose personal sensitive info if it is sensibly necessary for our opinion to protect the rights or property of navchr online or any of its associates or any third party or to avoid injury to any person.</p>
                    <p>The all users own info may be released for directing/ inner respect and audit.</p>
                    <p>Navchr online takes suitable steps to protect the info you share with us. We have realized technology and safety features and strict policy rules to protection the privacy of your personally identifiable info from unofficial access and unsuitable use or disclosure. Please contact us on <i><b>support@navchr.com</b></i> to obtain names and addresses of the specific entities that shall have access to your personal info in a given deal.</p>
                    <p>Navchr online will continue to improve its security actions as new technology becomes available and ensures that its security events are compliant with current appropriate rules.</p>
                    <p>If our policy changes in the future, it will be posted here, and a new real date will be shown. You should be access our policy regularly to ensure you understand our current rules.</p>
                    <p>The updated policy stands effective as on sep 2022.</p>

                    <h3>Authorization of Operating System :</h3>

                    <p>In order to have a better knowledge and to use all features in the app, we need below authorizations to be used in our Operating System :</p>
                    <ul>
                        <li><b>Camera</b> :<p>Navchr Online will not access your photos or camera without first getting your approval and we will never scan or import your photo library or camera roll. If you give us consent to access photos or your camera, we will only use images that you exactly choose to share with us.</p></li>
                        <li><b>Contacts </b>:<p>Navchr Online will not scan or import your contacts stored on your phone without first getting your clear approval. We will only use the contact info to help you find friends or contacts who use navchr online website if you choose to do so, and we will not use contact info for any other purpose without first getting your separate clear permission.</p></li>
                        <li><b>Calendar</b>:<p>This support allows us to put your airline travel plan on your calendar.</p></li>
                        <li><b>Location</b>: <p>Navchr Online will not gather or use the specific place of your mobile device by using, for example, GPS/ Bluetooth without first receiving your clear permission. And if you choose to share location info but later change your mind, you will always have the ability to stop sharing. Please note that this does not include the IP address. The consents will benefit the user location specific deals and afford you a modified knowledge with the time zone.</p></li>
                        <li><b>Message service </b>: <p>This website reads, uploads and syntactic analyses text messages from travel providers within the program for any flight PNR booking messages. We use this info to provides updates on PNR status, flight delays. This info is stored in a safe atmosphere and always transmitted using SSL.</p></li>
                        <li><b>Get Accounts </b>: <p>This permission enables us to know about the details of your account on your mobile device. This will help you to get register on the stage by logging into online accounts you may have with Third Party social networking sites (Facebook, Twitter, Google Login) each such account.</p></li>
                        <li><b>Third Party</b>: <p>The user allows the owner to provide its facilities to third party services accounts for showing content from outside stages, SPAM guard, hosting and backend setup, Organization monitoring, Content commenting, Interaction with external social networks and platforms, Location based relations, social features, contacting the user, user database management, targeted advertising and remarketing and behavioural targeting.</p></li>
                    </ul>

                    <h3>Others Info that we collect :</h3>
                    <ul>
                        <li>Analytics <p>Google Analytics, appsflyer, facebook ads conversion tracking, google tag manager.</p><p>moniture cookies, usage data and various types of data as specified in the policy of the deal.</p></li>
                        <li>Infrastructure monitoring <p>Catalytic Geographic position, unique device identifiers for advertising Google Ads ID or IDFA) and various types of data as specified in the policy of the service.</p></li>
                        <li>Place based relatives <p>Physical locations.</p></li>
                        <li>•	Unique device ID <p>This program may track users by storing a unique identifier of their device, for analytics purposes or for storing user preferences.</p></li>
                    </ul>
                    <p>Thanking you Sir/madam, Jai Hind</p>
                    <p> for using <a href="https://www.navchr.com">https://www.navchr.com, www.navchr.in</a> </p>
                </div>

            </div>
        </div>
    )
}
