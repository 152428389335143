import React, { useEffect, useState } from 'react';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';

// import { ThemeProvider } from '@mui/material/styles';
// import { createTheme } from '@material-ui/core/styles';
import { TextField, ThemeProvider, createTheme } from '@mui/material';
import { DatePicker, LocalizationProvider, MobileDatePicker } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { format, subDays, subYears } from 'date-fns';
import CloseIcon from '@mui/icons-material/Close';
import dayjs from 'dayjs';
const darkTheme = createTheme({
    palette: {
        mode: 'dark',
    },
});
const BookingForm = ({ passenger, idx, handleChange }) => {
    const [TITLE, setTITLE] = React.useState(-1);
    // Form Validation
    const titleLOV = ['', "Mr", "Mstr", "Mrs", "Miss"];
    const paxTypeLOV = ['', 'Adult', 'Children', 'Infant']
    const gender = ['', 'Male', 'Female']
    const [C_DOB, setC_DOB] = useState(subYears(new Date(), passenger?.pax_type == 1 ? 12 : passenger.pax_type == 2 ? 2 : 0));

    const TitleHandleChange = (value) => {
        setTITLE(value);
        // console.log(value)
        // switch (value) {
        //     case -1:
        //         setPASSANGER_DETAILS({ ...PASSANGER_DETAILS, title: '', gender: 0 });
        //         break;
        //     case 0:
        //         setPASSANGER_DETAILS({ ...PASSANGER_DETAILS, title: 'Mr.', gender: 0 });
        //         break;
        //     case 1:
        //         setPASSANGER_DETAILS({ ...PASSANGER_DETAILS, title: 'Master', gender: 0 });
        //         break;
        //     case 2:
        //         setPASSANGER_DETAILS({ ...PASSANGER_DETAILS, title: 'Mrs.', gender: 1 });
        //         break;
        //     case 3:
        //         setPASSANGER_DETAILS({ ...PASSANGER_DETAILS, title: 'Miss', gender: 1 });
        //         break;
        //     default:
        // }
    };


    useEffect(() => {
        // props.goParrent({ passenger: PASSANGER_DETAILS, id: props?.formIndex });
        // console.log('PASSANGER_DETAILS', PASSANGER_DETAILS);

        // if (PASSANGER_DETAILS.first_name === '') {
        //     setFirstNameError("Please enter a name");
        //     handleClick();
        //  } else if (!/^[a-zA-Z'-]{2,50}$/.test(PASSANGER_DETAILS.first_name)) {
        //     setFirstNameError("Please enter a valid name. Please avoid space and special characters");
        //      handleClick();
        //  } else {
        //     setFirstNameError(false);
        //  }



    }, []);


    useEffect(() => {
        console.log('-----',);
        // setPASSANGER_DETAILS({
        //     'title': props?.passengerPreset?.title,
        //     'first_name': props?.passengerPreset?.first_name,
        //     'last_name': props?.passengerPreset?.last_name,
        //     'pax_type': props?.passengerPreset?.pax_type,
        //     'date_of_birth': props?.passengerPreset?.date_of_birth,
        //     'gender': props?.passengerPreset?.gender,
        // });
        // if (props?.passengerPreset?.title === '') {
        //     setTITLE(-1)
        // } else if (props?.passengerPreset?.title === 'Mr') {
        //     setTITLE(0)
        // } else if (props?.passengerPreset?.title === 'Master') {
        //     setTITLE(1)
        // } else if (props?.passengerPreset?.title === 'Mrs') {
        //     setTITLE(2)
        // } else {
        //     setTITLE(3)
        // }



    }, []);

    return (
        <div>

            <div className="Layer_Box_Two "  >
                <div className="box form_box_common mb-3 pb-0">
                    <div className="d-flex justify-content-between">
                        <div className="col-auto">
                            <h5 className='mb-0'>
                                Passenger {idx + 1}
                                {/* &#160; {props.formIndex + 1} */}
                            </h5>
                        </div>
                        <div className="col-auto">
                            <h5 className='mb-0'>
                                ({paxTypeLOV[Math.max(passenger?.pax_type, 0)]})
                                {/* {props?.passengerPreset?.pax_type === 1 && 'Adult'}
                                {props?.passengerPreset?.pax_type === 2 && 'Children'}
                                {props?.passengerPreset?.pax_type === 3 && 'Infant'} */}
                            </h5>
                        </div>
                    </div>
                    <hr className='my-2' />
                    <ThemeProvider theme={darkTheme}>
                        <div className="row">
                            <div className="col-md-2 mb-3">
                                <FormControl fullWidth>
                                    <div className='label' >Title</div>
                                    <Select
                                        value={passenger.title}
                                        onChange={(e) => {
                                            console.log("Changing", e.target.value);

                                            // const titleLOV = ['', "Mr", "Mstr", "Mrs", "Miss"];

                                            handleChange(idx, {
                                                ...passenger,
                                                title: e.target.value,
                                                gender: e.target.value == 'Mr' ? 1 : e.target.value == 'Mstr' ? 1 : e.target.value == 'Mrs' ? 2 : e.target.value == 'Miss' ? 2 : 0
                                            })
                                        }}
                                        style={{ height: '3rem' }}
                                    >
                                        {titleLOV.map((e, i) => <MenuItem value={e}  >{e || 'Select'}</MenuItem>)}
                                    </Select>
                                </FormControl>
                            </div>
                            <div className="col-md-4 mb-3">
                                <FormControl fullWidth>
                                    <div className='label' >First name & Middle name</div>
                                    <TextField
                                        InputProps={{ sx: { height: '3rem' } }}
                                        value={passenger.first_name}
                                        variant="outlined" onChange={(e) => {
                                            handleChange(idx, {
                                                ...passenger,
                                                first_name: e.target.value
                                            })
                                            // setPASSANGER_DETAILS({ ...PASSANGER_DETAILS, first_name: e.target.value })
                                        }} />
                                </FormControl>
                            </div>
                            <div className="col-md-3 mb-3">
                                <FormControl fullWidth>
                                    <div className='label' >Last name</div>
                                    <TextField
                                        InputProps={{ sx: { height: '3rem' } }}
                                        value={passenger.last_name}
                                        variant="outlined" onChange={(e) => {
                                            handleChange(idx, {
                                                ...passenger,
                                                last_name: e.target.value
                                            })
                                            //setPASSANGER_DETAILS({ ...PASSANGER_DETAILS, last_name: e.target.value }) 
                                        }} />
                                </FormControl>
                            </div>

                            {/**
                             * 0-2: Infant - Max Date -> Current Date, Min Date - current - 2y
                             * 2 - 12 : Child: Max Date -> current date - 2y, Min - current - 12y
                             * 12+ : Adult: Max date: current date - 12y, min date - current date - 100y
                             */}

                            <div className="col-md mb-3  ">
                                {console.log(passenger.date_of_birth)}
                                <FormControl fullWidth>
                                    <div className='label' >Date of birth</div>
                                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                                        <DatePicker
                                            InputProps={{ sx: { height: '3rem', paddingLeft: '0' } }}
                                            value={C_DOB}
                                            maxDate={subYears(new Date(), passenger?.pax_type == 1 ? 12 : passenger.pax_type == 2 ? 2 : 0)}
                                            minDate={subYears(new Date(), passenger?.pax_type == 1 ? 100 : passenger.pax_type == 2 ? 12 : 2)}
                                            onChange={(newValue) => (
                                                handleChange(idx, {
                                                    ...passenger,
                                                    date_of_birth:newValue == null ? null : newValue["$d"] == 'Invalid Date' ? null : format(newValue["$d"], 'yyyy-MM-dd')
                                                }),
                                                setC_DOB(newValue == null ? null : newValue["$d"] == 'Invalid Date' ? null : newValue["$d"]),
                                                console.log(newValue)
                                              )}
                                            renderInput={(params) => <TextField className='' sx={{ paddingLeft: '0px' }} {...params} />}
                                            format="dd-MM-yyyy"
                                            inputFormat="DD/MM/YYYY"
                                        />
                                    </LocalizationProvider>
                                </FormControl>
                            </div>
                        </div>
                    </ThemeProvider>
                </div>
            </div>



        </div>
    );
}

export default BookingForm;
