import React from 'react'

export default function Refunds_Policy() {
    return (
        <div className='tc_cms_pages_wrapper  py-5 animate__animated animate__fadeIn'>

            <div className="container">

                <div className='main'>
                    <h1><span>Refunds Policy</span></h1>
                    <p>It is mandatory to contact Navchr Online for all refunds, as the airline will not be able to refund your tickets booked at <i><ins>https://www.navchr.com</ins></i>.</p>
                    <p>All cancellations made directly with the Airline company need to be intimated/info to navchr online by Email or mobile no in order to initiate the refunds process.</p>
                    <p>Processing times for cancellation and refund requests vary. But generally, refund will be initiated within 72 hours to your Bank account. In case of refund in Bank account it might take 15-17 business date to reflect in your account.</p>
                    <p>Navchr Online shall refund your cancellation only after the respective Airline/Service Provider processes to us your eligible refund. In case an airline ceases its flight operations, any refund owed by the airline to the passenger will be processed by navchr online only after receiving the same from the airline.</p>
                    <p>In the case of the flight ticket will cancel by act of God, by the airline company and cancel by the user this satiation convince fee per user do not refund to any customer.</p>
                    <p> If the user may be cancelling their flight ticket cancel charge (Rs 100.00 + 18%) (One hundred eighteen rupees only) including GST per passenger and transition charge(2%) may be apply all customer.</p>
                    <p>After flight ticker cancelation your balance amount may be transfer to your bank account for the flight ticket cancel guidelines of airline company. Navchr online not be responsible this instruction of airlines company. Cancelation charge (Rs 100.00 + 18%) (One hundred eighteen rupees only) including GST per passenger and transition charge (2%) may be apply.</p>

                    <h3>No Show :</h3>
                    <p>The Airline reserves the right of admission or boarding for onward or return flight, in case the customer fails to board any segment of the travel for his itinerary. Navchr Online shall not be responsible or liable for such refusal of admission or boarding.</p>


                </div>
            </div>
        </div>
    )
}
