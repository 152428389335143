import { format, parseISO } from 'date-fns'
import moment from 'moment'
import React, { useState } from 'react'

import { AirplanemodeInactive, FlightTakeoff, SentimentDissatisfied } from '@material-ui/icons';
import { AirplaneTicket, Download } from '@mui/icons-material';
import { Link } from 'react-router-dom';

export default function MyTicket(props) {
    const [TicketStatus, setTicketStatus] = useState('Confirmed');
    // const [IsOptRecived, setIsOptRecived] = useState(false);




    return (
        <div className='myTicketList'>

            <div className="ticket_category mb-5">
                <ul className="list-unstyled d-flex row">
                    <li className='col-auto'><button onClick={() => setTicketStatus('Confirmed')} className={`btn border-0  text-dark ${TicketStatus === 'Confirmed' && 'active'}`}><FlightTakeoff /> Upcoming ticket</button></li>
                    <li className='col-auto'><button onClick={() => setTicketStatus('PENDING_REFUND')} className={`btn border-0  text-danger  ${TicketStatus === 'PENDING_REFUND' && 'active'}`}><AirplanemodeInactive /> Canceled ticket</button></li>
                </ul>
            </div>
            <div className="row">

                {props?.SendTicketData?.filter((data) => data?.status === TicketStatus).length > 0 ? (
                    props.SendTicketData.filter((data) => data?.status === TicketStatus).reverse().map((data, index) => (
                        <React.Fragment key={index}>


                            {data?.pnr !== null && <TicketSmallCard data={
                                {
                                    id: data?.id,
                                    from: data?.from,
                                    to: data?.to,
                                    date: format(parseISO(data?.journey_date), 'dd MMM yyyy'),
                                    pnr: data?.pnr,
                                    book_id: data?.booking_id,
                                    ticket_link: data?.ongoing_ticket_url,
                                    status: data?.status,
                                    j_type: 1
                                }} />}

                            {data?.return_pnr !== null && <TicketSmallCard data={
                                {
                                    id: data?.id,
                                    from: data?.to,
                                    to: data?.from,
                                    date: format(parseISO(data?.return_date), 'dd MMM yyyy'),
                                    pnr: data?.return_pnr,
                                    book_id: data?.return_booking_id,
                                    ticket_link: data?.return_ticket_url,
                                    status: data?.status,
                                    j_type: 2
                                }} />}

                        </React.Fragment>
                    ))
                ) : (

                    <div className="col-12">
                        <h4><SentimentDissatisfied /> Ticket not availabe</h4>
                    </div>
                )}




            </div>
            <br />





        </div >
    )
}



const TicketSmallCard = ({ data }) => {
    // console.log(data)
    return (
        <>
            <div className='col-md-6 mb-4' >
                <div className="ticket_small_card">
                    <table className="table table-borderless m-0">
                        <tbody>
                            <tr>
                                <td>
                                    <div className="tx1">
                                        {data?.from} <FlightTakeoff /> {data?.to}
                                    </div>
                                    <div className="tx3">
                                        PNR:  {data?.pnr}
                                    </div>
                                </td>
                                <td className='text-end'>
                                    <div className="tx2">
                                        {data.date}
                                    </div>

                                </td>
                            </tr>


                        </tbody>
                        <tfoot>
                            <tr>
                                <td>
                                    <div className="tx4">
                                        Booking ID: {data?.book_id}
                                    </div>
                                </td>
                                <td className='text-end'>
                                    {data?.status === 'Confirmed' &&
                                        <div className="tx5">{data?.status}</div>}
                                    {data?.status === 'PENDING_REFUND' &&
                                        <div className="tx6">Cancelled</div>}
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <a className="tx7" download target='_blank' href={data?.ticket_link}>
                                        <Download />  Download
                                    </a>
                                </td>
                                <td className='text-end'>
                                    <Link to={`/ticket-details/${window.btoa(JSON.stringify({ i: data?.id, t: data?.j_type }))}`} className="tx7">
                                        <AirplaneTicket />  Details
                                    </Link>
                                </td>
                            </tr>
                        </tfoot>
                    </table>
                </div>
            </div>
        </>

    )
}















