import React, { useState } from "react";
import { makeStyles, ThemeProvider, createMuiTheme } from "@material-ui/core/styles";
import {
    Button,
    IconButton,
    InputAdornment,
    TextField,
    Grid,
    Typography,
    Container
} from "@material-ui/core";
import { SentimentSatisfied, Visibility, VisibilityOff } from "@material-ui/icons";
import OptInSlice from "./OptInSlice";
import axios from "axios";
import { API_BASE_LINK } from "../API_BASE_PATH";
// import darkTheme from "./darkTheme";

const useStyles = makeStyles((theme) => ({
    paper: {
        marginTop: theme.spacing(8),
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
    },
    form: {
        width: "100%",
        marginTop: theme.spacing(3),
    },
    submit: {
        margin: theme.spacing(3, 0, 2),
    },
}));

const darkTheme = createMuiTheme({
    palette: {
        type: "dark",
    },
});
export default function ChangePassword(props) {
    const classes = useStyles();

    const [ChangePassSteps, setChangePassSteps] = useState(1);
    const [password, setPassword] = useState("");
    const [newPassword, setNewPassword] = useState("");
    const [confirmPassword, setConfirmPassword] = useState("");
    const [showPassword, setShowPassword] = useState(false);
    const [showNewPassword, setShowNewPassword] = useState(false);
    const [showConfirmPassword, setShowConfirmPassword] = useState(false);
    const [passwordError, setPasswordError] = useState(null);
    const [OtpRespData, setOtpRespData] = useState({});

    const handleSubmit = (e) => {
        e.preventDefault();
        console.log("password:", password);
        console.log("newPassword:", newPassword);
        console.log("confirmPassword:", confirmPassword);
        // You can implement your password change logic here
        let userData = JSON.parse(sessionStorage.getItem('LoginUserDetails'));
        if (newPassword === confirmPassword) {
            axios.post(`${API_BASE_LINK}users/changePassword
            `, {
                "old_password": password,
                "new_password": confirmPassword,
                // "token": OtpRespData?.resData?.token
            }, {
                headers: {
                    'Authorization': userData?.session?.session_token
                }
            })
                .then((response) => {
                    console.log(response);
                    if (response.status === 200) {
                        setChangePassSteps(3);
                    } else {
                        setChangePassSteps(4);
                    }

                })
                .catch((error) => {
                    setChangePassSteps(4);
                });
        }

        // if(!newPassword || newPassword !== confirmPassword){

        // }
    };

    const handleClickShowPassword = () => {
        setShowPassword(!showPassword);
    };

    const handleClickShowNewPassword = () => {
        setShowNewPassword(!showNewPassword);
    };

    const handleClickShowConfirmPassword = () => {
        setShowConfirmPassword(!showConfirmPassword);


    };

    const validatePassword = (password) => {
        // Regular expression for password validation
        const regex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[a-zA-Z\d]{8,}$/;
        if (!regex.test(password)) {
            setPasswordError(
                "Password must be at least 8 characters long and contain at least one uppercase letter, one lowercase letter, and one number."
            );
        } else {
            setPasswordError(null);
        }
    };

    const handleNewPasswordChange = (e) => {
        const value = e.target.value;
        setNewPassword(value);
        // validatePassword(value);
    };


    const IsOtpVerify = (data) => {
        console.log('//////', data);

        if (data.status === true) {
            setChangePassSteps(1);
            setOtpRespData(data);
        }
    }
    return (
        <div className="changePassword">
            {props.SendPassword}
            {/* {atob(props.SendPassword)} */}

            {ChangePassSteps === 0 && <>
                <ThemeProvider theme={darkTheme}>
                    <OptInSlice sendMnumber={props.UseMobileNumber} GetIsOtpVerify={IsOtpVerify} />
                </ThemeProvider>
            </>}

            {ChangePassSteps === 1 && <>
                <ThemeProvider theme={darkTheme}>
                    <Container component="main" maxWidth="xs">
                        <div className={classes.paper}>
                            <Typography component="h1" variant="h5">
                                Change Password
                            </Typography>
                            <form className={classes.form} onSubmit={handleSubmit}>
                                <Grid container spacing={2}>
                                    <Grid item xs={12}>
                                        <TextField
                                            variant="outlined"
                                            required
                                            fullWidth
                                            name="password"
                                            label="Current Password"
                                            type={showPassword ? "text" : "password"}
                                            id="password"
                                            autoComplete="current-password"
                                            value={password}
                                            onChange={(e) => setPassword(e.target.value)}
                                            InputProps={{
                                                endAdornment: (
                                                    <InputAdornment position="end">
                                                        <IconButton
                                                            aria-label="toggle password visibility"
                                                            onClick={handleClickShowPassword}
                                                            edge="end"
                                                        >
                                                            {showPassword ? (
                                                                <VisibilityOff />
                                                            ) : (
                                                                <Visibility />
                                                            )}
                                                        </IconButton>
                                                    </InputAdornment>
                                                ),

                                            }}
                                        />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <TextField
                                            variant="outlined"
                                            required
                                            fullWidth
                                            name="newPassword"
                                            label="New Password"
                                            type={showNewPassword ? "text" : "password"}
                                            id="newPassword"
                                            autoComplete="new-password"
                                            value={newPassword}
                                            onChange={handleNewPasswordChange}
                                            InputProps={{
                                                endAdornment: (
                                                    <InputAdornment position="end">
                                                        <IconButton
                                                            aria-label="toggle new password visibility"
                                                            onClick={handleClickShowNewPassword}
                                                            edge="end"
                                                        >
                                                            {showNewPassword ? (
                                                                <VisibilityOff />
                                                            ) : (
                                                                <Visibility />
                                                            )}
                                                        </IconButton>
                                                    </InputAdornment>
                                                ),
                                            }}
                                        />
                                        {passwordError && (
                                            <Typography variant="caption" color="error">
                                                {passwordError}
                                            </Typography>
                                        )}
                                    </Grid>
                                    <Grid item xs={12}>
                                        <TextField
                                            variant="outlined"
                                            required
                                            fullWidth
                                            name="confirmPassword"
                                            label="Confirm New Password"
                                            type={showConfirmPassword ? "text" : "password"}
                                            id="confirmPassword"
                                            autoComplete="new-password"
                                            value={confirmPassword}
                                            onChange={(e) => setConfirmPassword(e.target.value)}
                                            InputProps={{
                                                endAdornment: (
                                                    <InputAdornment position="end">
                                                        <IconButton
                                                            aria-label="toggle confirm password visibility"
                                                            onClick={handleClickShowConfirmPassword}
                                                            edge="end"
                                                        >
                                                            {showConfirmPassword ? (
                                                                <VisibilityOff />
                                                            ) : (
                                                                <Visibility />
                                                            )}
                                                        </IconButton>
                                                    </InputAdornment>
                                                ),
                                            }}
                                        />
                                    </Grid>
                                </Grid>
                                <Button
                                    type="submit"
                                    fullWidth
                                    variant="contained"
                                    color="primary"
                                    className={classes.submit}
                                    disabled={!newPassword || newPassword !== confirmPassword}
                                >
                                    Change Password
                                </Button>
                            </form>
                        </div>
                    </Container>
                </ThemeProvider>
            </>}
            {ChangePassSteps === 3 && <>
                <h4 className="text-center py-5"><SentimentSatisfied style={{fontSize:'inherit'}}/> Your Password Successfully Changed.</h4>
            </>}

            {ChangePassSteps === 4 && <>
                <h3 className="text-center py-5">Please try after some time</h3>
            </>}
        </div>
    );
}
