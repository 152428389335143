import React from 'react'

export default function UserAgreement() {
    return (
        <div className='tc_cms_pages_wrapper  py-5 animate__animated animate__fadeIn'>

            <div className="container">

                <div className='main'>
                    <h1><span>User Agreement</span></h1>
                    <p>
                        This user agreement integrates the terms and conditions for www.navchr.com/www.navchr.in (navChr online) and its affiliate companies to provide services to the Users looking for purchasing any of the products and/ or services of navchr online by using its website or other networks like sales persons, all media network, customer support centre, users, information campaigns etc. Users and navchr online are referred as get-together in the agreement while collectively referred as parties.
                    </p>
                    <h3>User Alertness for This Contract :</h3>
                    <p>
                        By accessing or using this site or using any of its services, you are agreed on terms and conditions mentioned below, including any supplementary guidelines and future modifications. navchr online may change, alter or remove any part of these below written Terms and Conditions without any prior notice. All rights and responsibilities of the User and/or www.navchr.com/www.navchr.in with respect to any services to be provided by navchr online will be limited to the range of this agreement. navchr online has all the rights to conclude the access to any of its services at any point of time, without any prior notice, for any reason. There are certain products or services to be provided by third party suppliers. Besides this agreement, Users must understand and agreed to the TOS of such suppliers. Such TOS will be provided and updated by navchr online and shall be considered to be the part of this agreement. The Users should read and accept the relevant TOS for the service/ product availed by him. Services of navchr online are offered to the Users on the condition of accepting it without any modification. For removing any doubt, it is clarified that by availing the Services of nachr online means Users is agreed to this Agreement and the TOS. If Users does not agree with any part of it, he should not avail navchr online services.
                    </p>
                    <h3>Third Party Info :</h3>
                    <p>
                    By using www.navchr.con/www.navchr.in website and program, Users allows navchr online and its agents to access third party sites, including Banks and other payment gateways, designated by them or on their behalf to retrieve requested details. While registration, the Users should choose a password and maintain its privacy. The User is completely responsible for any activity held during using the password or account. It is the duty of the User to intimate navchr online urgently in written in case of experiencing unauthorized use of the account or other security breaching. navchr online will not be responsible for any loss occurred due to unauthorized use of his password or account. Any information of user not to be share any persons/ any company at a time. All user’s personal data safe and secure in this website. So all user’s are request that please trust this website www.navchr.com/ www.navchr.in. make your life safe and secure. 
                    </p>

                </div>
            </div>
        </div>
    )
}
