
import React, { useEffect, useState } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import { Alert, Checkbox, FormControl, FormControlLabel, FormLabel, MenuItem, Radio, RadioGroup, Select } from '@mui/material';
import TextField from '@mui/material/TextField';
import { IconButton, InputAdornment, OutlinedInput } from "@mui/material";
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import Login from '@mui/icons-material/Login';
import axios from "axios";
import { API_BASE_LINK } from "../API_BASE_PATH";
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { format, subDays } from 'date-fns';
import STATE from '../DATA_SET/IndianStates.json';
import { Dialog, DialogTitle, DialogContent, DialogActions, Button } from '@mui/material';

const darkTheme = createTheme({
    palette: {
        mode: 'dark',
    },
});
const RegisterPage = () => {
    const navigate = useNavigate();
    const [GetOTP, setGetOTP] = useState(null);
    const [IsVerifyed, setIsVerifyed] = useState(false);
    const [otpValidation, setotpValidation] = useState('');
    const [isOpen, setIsOpen] = useState(false);
    const [ErrorHandel, setErrorHandel] = useState(null);
    const [ValiDate, setValiDate] = useState('');
    const [TITLE, setTITLE] = React.useState(0);
    const [TITLE_TEXT, setTITLE_TEXT] = React.useState('Mr');
    const [GENDER, setGENDER] = React.useState(1);
    const [GENDER_TEXT, setGENDER_Text] = React.useState('M');
    const [SET_STATE, setSET_STATE] = React.useState(-1);
    const [GetPhoneNumber, setGetPhoneNumber] = useState(null);
    const [ADDRESS_DETAILS, setADDRESS_DETAILS] = React.useState({
        "full_address": "",
        "city": "",
        "state": '',
        "country": "India",
        "pincode": ""
    });
    const [showPassword, setShowPassword] = React.useState(false);
    const [IsChecked, setIsChecked] = useState(true);
    const [ChangeRadio, setChangeRadio] = useState(1);

    const handleClickShowPassword = () => setShowPassword((show) => !show);

    const handleMouseDownPassword = (event) => {
        event.preventDefault();
    };

    const StateHandleChange = (event) => {
        setSET_STATE(event.target.value);
        // STATE.map((data,ind)=>console.log(data))
        console.log(STATE[event.target.value])
        console.log(STATE[event.target.value].name)
        setADDRESS_DETAILS({ ...ADDRESS_DETAILS, state: STATE[event.target.value].name })

        // console.log(ADDRESS_DETAILS)
        console.log(event.target.value)
    };


    const [PASSANGER_DETAILS, setPASSANGER_DETAILS] = React.useState({

        "first_name": "",
        "last_name": "",
        "prefix": TITLE_TEXT,
        "gender": GENDER_TEXT,
        "date_of_birth": null,
        "mobile_number": "",
        "email_id": "",
        "password": "",
        "c_password": "",
        'defense_id_card_number': '',
    });
    useEffect(() => {
        if (sessionStorage.getItem("islogin") === 'true') {
            navigate('/')
        }

    }, []);

    const TitleHandleChange = (event) => {
        setTITLE(event.target.value);
        switch (event.target.value) {
            case 0:
                setPASSANGER_DETAILS({ ...PASSANGER_DETAILS, prefix: 'Mr.' });
                setGENDER(1);
                break;
            case 1:
                setPASSANGER_DETAILS({ ...PASSANGER_DETAILS, prefix: 'Master' });
                setGENDER(1);
                break;
            case 2:
                setPASSANGER_DETAILS({ ...PASSANGER_DETAILS, prefix: 'Mrs.' });
                setGENDER(2);
                break;
            case 3:
                setPASSANGER_DETAILS({ ...PASSANGER_DETAILS, prefix: 'Miss' });
                setGENDER(2);
                break;
            default:
        }
    };

    const GenderHandleChange = (event) => {
        setGENDER(event.target.value);
        switch (event.target.value) {
            case 1:
                setPASSANGER_DETAILS({ ...PASSANGER_DETAILS, gender: 'M' });
                setTITLE(0)
                break;
            case 2:
                setPASSANGER_DETAILS({ ...PASSANGER_DETAILS, gender: 'F' });
                setTITLE(2)
                break;

            default:
        }
    };

    const verify_otp = () => {
        console.log(GetOTP);
        const otp_regex = /^\d{6}$/;

        if (GetOTP === '') {
            setotpValidation('Please enter 6 digit OTP');
        } else if (!otp_regex.test(GetOTP)) {
            setotpValidation('Please enter valid OTP');
        } else {
            setotpValidation('');
            axios.post(`${API_BASE_LINK}users/verifyPhone`, {
                "phone_number": GetPhoneNumber,
                "otp": GetOTP
            })
                .then(function (response) {
                    console.log(response);
                    if (response.status === 200) {
                        setIsVerifyed(true)
                    }
                })
                .catch(function (error) {
                    console.log(error);
                    setotpValidation('Please enter valid OTP');
                });
        }


    }

    const registration_api_call = (data) => {
        axios.post(`${API_BASE_LINK}users/signup`, data)
            .then(function (response) {
                console.log('----------------------', response);
                if (response.status === 200) {
                    setIsOpen(true);
                    setErrorHandel('Registration successfully complete');
                    // verify_otp(response.data.mobile_number)
                    setGetPhoneNumber(response?.data?.dataValues?.mobile_number);

                    setPASSANGER_DETAILS({
                        first_name: "",
                        last_name: "",
                        prefix: TITLE_TEXT,
                        gender: GENDER_TEXT,
                        date_of_birth: null,
                        mobile_number: "",
                        email_id: "",
                        password: "",
                        c_password: "",
                        defense_id_card_number: ''
                    });
                    setADDRESS_DETAILS({
                        "full_address": "",
                        "city": "",
                        "state": '',
                        "country": "India",
                        "pincode": ""
                    });
                    setTimeout(() => {
                        setErrorHandel(null);
                    }, 3000)


                }
            })
            .catch(function (error) {
                console.log(error);
                setErrorHandel(error.response.data.error);
            });
    }

    const FinalLoginPayload = () => {
        let SetAllPayload = {
            "first_name": PASSANGER_DETAILS.first_name,
            "last_name": PASSANGER_DETAILS.last_name,
            "prefix": PASSANGER_DETAILS.prefix,
            "gender": PASSANGER_DETAILS.gender,
            "date_of_birth": PASSANGER_DETAILS?.date_of_birth?.["$d"]
                ? format(PASSANGER_DETAILS.date_of_birth["$d"], 'yyyy-MM-dd')
                : null,
            "mobile_number": PASSANGER_DETAILS.mobile_number,
            "email_id": PASSANGER_DETAILS.email_id,
            "password": PASSANGER_DETAILS.password,
            "address": {
                "full_address": ADDRESS_DETAILS.full_address,
                "city": ADDRESS_DETAILS.city,
                "state": ADDRESS_DETAILS.state,
                "pincode": ADDRESS_DETAILS.pincode
            }
        }
        // console.log('PASSANGER_DETAILS--', SetAllPayload);
        const OnlyAlphabet = /^[a-zA-Z]+$/;
        const Phone_number_regex = /^\D*([2-9]\d{2})\D*\d{3}\D*\d{4}\D*$/;
        const email_verify_regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        const password_validation_regex = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[a-zA-Z])(?!.*\s).{8,}$/;
        const space_regex = /^\s+/;
        const number_only_regex = /^[0-9]*$/;
        let All_Valid = {
            first_name: false,
            last_name: false,
            date_of_birth: false
        }
        if (PASSANGER_DETAILS.first_name === '') {
            setErrorHandel('Please enter first name.')
        }
        //  else if (!OnlyAlphabet.test(PASSANGER_DETAILS.first_name)) {
        //     setErrorHandel('Please enter only letters in first name field.')
        // } 
        else {
            setErrorHandel('');
            All_Valid.first_name = true;

            if (PASSANGER_DETAILS.last_name === '') {
                setErrorHandel('Please enter last name.')
            } /*else if (!OnlyAlphabet.test(PASSANGER_DETAILS.last_name)) {
                setErrorHandel('Please enter only letters in last name field.')
            } */else {
                setErrorHandel('');
                if (PASSANGER_DETAILS.date_of_birth === null) {
                    setErrorHandel('Please enter Dste of birth. Age minimum 18 year')
                } else {
                    setErrorHandel('');
                    if (PASSANGER_DETAILS.mobile_number === '') {
                        setErrorHandel('Please enter phone number  .')
                    } else if (!Phone_number_regex.test(PASSANGER_DETAILS.mobile_number)) {
                        setErrorHandel('Please enter valid phone number. Phone number should any number of non-digit characters. Phone number should 10 digit.');
                    }
                    else {
                        setErrorHandel('');
                        if (PASSANGER_DETAILS.email_id === '') {
                            setErrorHandel('Please enter email address.');
                        } else if (!email_verify_regex.test(PASSANGER_DETAILS.email_id)) {
                            setErrorHandel('Please enter valid email address. eg: jhon@example.com');
                        }
                        else {
                            setErrorHandel('');
                            if (PASSANGER_DETAILS.password === '') {
                                setErrorHandel('Please enter password.');
                            } 
                            // else if (!password_validation_regex.test(PASSANGER_DETAILS.password)) {
                            //     setErrorHandel('Password must be at least 8 characters long, contain at least one lowercase letter, one uppercase letter, one number, and no spaces. eg: Jd123456');
                            // } 
                            else {
                                setErrorHandel('');
                                if (PASSANGER_DETAILS.c_password === '') {
                                    setErrorHandel('Please enter confirm password.');
                                } else if (PASSANGER_DETAILS.c_password !== PASSANGER_DETAILS.password) {
                                    setErrorHandel('Passwords do not match.');
                                } else {
                                    setErrorHandel('');
                                    if (ADDRESS_DETAILS.full_address.length < 2) {
                                        setErrorHandel('Please enter full address.')
                                    } else {
                                        setErrorHandel('');
                                        if (ADDRESS_DETAILS.city === '') {
                                            setErrorHandel('Please enter city.')
                                        } else {
                                            setErrorHandel('');
                                            if (ADDRESS_DETAILS.state === '') {
                                                setErrorHandel('Please enter state.')
                                            } else {
                                                setErrorHandel('');
                                                if (ADDRESS_DETAILS.pincode.length < 4) {
                                                    setErrorHandel('Please enter Pincode.')
                                                } else if (!number_only_regex.test(ADDRESS_DETAILS.pincode)) {
                                                    setErrorHandel('Pincode/ Zip Code must only contain digits')
                                                } else {
                                                    setErrorHandel('All Ok');
                                                    registration_api_call(SetAllPayload);
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            };
        };


    }


    const handleDialogClose = () => {
        setIsOpen(false);


    };




    return (
        <main className='BOOKING_PAGE_MAIN_WRAP'>

            <div className="common_banner mb-5">
                <div className="container">
                    <div className="row">
                        <div className="col-auto">
                            <div className="iconBox"><span><img src="/img/ico13.svg" alt="" /></span></div>
                        </div>
                        <div className="col">
                            <h4>Sign Up</h4>
                            <p className="mb-0">
                              Join navchr.com now. Now we are availabe in india only. We are coming soon world wide.
                            </p>
                        </div>
                    </div>
                </div>
            </div>
            <div className="pt-4"></div>

            <section className="login_wraper">
                <div className="container">
                    <div className="row justify-content-center" >
                        <div className="col-md-9">
                            <div className='login_inner'>
                                <div className="custBg">
                                    <div className="text-center mb-4">
                                        <h3>Create your account</h3>
                                        <p>Please note that email / mobile verification is required for signup. <br />   Your email / mobile will only be used to verify your identity for security purposes.</p>
                                    </div>
                                    <hr />
                                    <div className="pt-3"></div>
                                    <ThemeProvider theme={darkTheme}>
                                        {ValiDate !== '' && <div class="alert alert-danger" role="alert">
                                            <div>   {ValiDate}</div>
                                        </div>}
                                        <div className="row">
                                            <div className="col-md-12">
                                                <div className="row">
                                                    <div className="col-md-2 mb-3">
                                                        <FormControl fullWidth>
                                                            <div className='label' >Title</div>
                                                            <Select
                                                                value={TITLE}
                                                                onChange={(e) => { TitleHandleChange(e); }}
                                                                style={{ height: '3rem' }}
                                                            >
                                                                <MenuItem value={0}>Mr.</MenuItem>
                                                                <MenuItem value={1}>Master</MenuItem>
                                                                <MenuItem value={2}>Mrs.</MenuItem>
                                                                <MenuItem value={3}>Miss</MenuItem>
                                                            </Select>
                                                        </FormControl>
                                                    </div>
                                                    <div className="col-md-5 mb-3">
                                                        <FormControl fullWidth>
                                                            <div className='label' >First name</div>
                                                            <TextField placeholder='First name and Middle Name' InputProps={{ sx: { height: '3rem' } }}
                                                            variant="outlined" value={PASSANGER_DETAILS.first_name} onChange={(e) => { setPASSANGER_DETAILS({ ...PASSANGER_DETAILS, first_name: e.target.value }) }} />
                                                        </FormControl>

                                                    </div>
                                                    <div className="col-md-5 mb-3">
                                                        <FormControl fullWidth>
                                                            <div className='label' >Last name</div>
                                                            <TextField placeholder='Last name'  InputProps={{ sx: { height: '3rem' } }}
                                                            variant="outlined" value={PASSANGER_DETAILS.last_name} onChange={(e) => { setPASSANGER_DETAILS({ ...PASSANGER_DETAILS, last_name: e.target.value }) }} />
                                                        </FormControl>
                                                    </div>
                                                    <div className="col-md-2 mb-3">
                                                        <FormControl fullWidth>
                                                            <div className='label' >Gender</div>
                                                            <Select
                                                                value={GENDER}
                                                                onChange={(e) => { GenderHandleChange(e); }}
                                                                style={{ height: '3rem' }}
                                                            >
                                                                <MenuItem value={1}>Male</MenuItem>
                                                                <MenuItem value={2}>Female</MenuItem>

                                                            </Select>
                                                        </FormControl>
                                                    </div>
                                                    <div className="col-md-5 mb-3">
                                                        <FormControl fullWidth>
                                                            <div className='label' >Date of birth <small>( Min Age 18 year )</small></div>
                                                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                                                                <DatePicker
                                                                    value={PASSANGER_DETAILS.date_of_birth}
                                                                    InputProps={{ sx: { height: '3rem', paddingLeft: '0' } }}

                                                                    maxDate={subDays(new Date(), ((365 * 18) - 1))}
                                                                    onChange={(newValue) => {
                                                                        setPASSANGER_DETAILS({ ...PASSANGER_DETAILS, date_of_birth: newValue })
                                                                        // setPASSANGER_DETAILS({ ...PASSANGER_DETAILS, date_of_birth: format(newValue["$d"], 'dd-MM-yyyy') })

                                                                    }}
                                                                    orientation='portrait'
                                                                    // label="Enter date of birth"
                                                                    renderInput={(params) => <TextField {...params} />}
                                                                    format="dd-MM-yyyy"
                                                                    inputFormat="DD/MM/YYYY"
                                                                />
                                                            </LocalizationProvider>
                                                        </FormControl>
                                                    </div>
                                                    <div className="col-md-5 mb-3">
                                                        <FormControl fullWidth>
                                                            <div className='label' >Phone Number</div>
                                                            <TextField  InputProps={{ sx: { height: '3rem' } }}
                                                            variant="outlined" value={PASSANGER_DETAILS.mobile_number} onChange={(e) => { setPASSANGER_DETAILS({ ...PASSANGER_DETAILS, mobile_number: e.target.value }) }} />
                                                        </FormControl>
                                                    </div>
                                                    <div className="col-md-12 mb-3">
                                                        <FormControl fullWidth>
                                                            <div className='label' >Email Address</div>
                                                            <TextField  InputProps={{ sx: { height: '3rem' } }}
                                                            variant="outlined" value={PASSANGER_DETAILS.email_id} onChange={(e) => { setPASSANGER_DETAILS({ ...PASSANGER_DETAILS, email_id: e.target.value }) }} />
                                                        </FormControl>
                                                    </div>
                                                    {/* <div className="col-md-5 mb-3">
                                                        <FormControl fullWidth>
                                                            <div className='label' >Defense ID Card Number</div>
                                                            <TextField variant="outlined" value={PASSANGER_DETAILS.defense_id_card_number} onChange={(e) => { setPASSANGER_DETAILS({ ...PASSANGER_DETAILS, defense_id_card_number: e.target.value }) }} />
                                                        </FormControl>
                                                    </div> */}
                                                    <div className="col-md-6 mb-3">
                                                        <FormControl fullWidth>
                                                            <div className='label' >Password</div>
                                                            <OutlinedInput
                                                            style={{ height: '3rem' }}
                                                                value={PASSANGER_DETAILS.password}
                                                                type={showPassword ? 'text' : 'password'}
                                                                onChange={(e) => { setPASSANGER_DETAILS({ ...PASSANGER_DETAILS, password: e.target.value }) }}
                                                                endAdornment={
                                                                    <InputAdornment position="end">
                                                                        <IconButton
                                                                            aria-label="toggle password visibility"
                                                                            onClick={handleClickShowPassword}
                                                                            onMouseDown={handleMouseDownPassword}
                                                                            edge="end"
                                                                        >
                                                                            {showPassword ? <VisibilityOff /> : <Visibility />}
                                                                        </IconButton>
                                                                    </InputAdornment>
                                                                }

                                                            />
                                                            {/* <TextField variant="outlined" onChange={(e) => { setPASSANGER_DETAILS({ ...PASSANGER_DETAILS, password: e.target.value }) }} /> */}
                                                        </FormControl>
                                                    </div>
                                                    <div className="col-md-6 mb-3">
                                                        <FormControl fullWidth>
                                                            <div className='label' >Confirm Password</div>
                                                            <OutlinedInput
                                                            style={{ height: '3rem' }}
                                                                value={PASSANGER_DETAILS.c_password}
                                                                onChange={(e) => { setPASSANGER_DETAILS({ ...PASSANGER_DETAILS, c_password: e.target.value }) }}
                                                                type={showPassword ? 'text' : 'password'}
                                                                endAdornment={
                                                                    <InputAdornment position="end">
                                                                        <IconButton
                                                                            aria-label="toggle password visibility"
                                                                            onClick={handleClickShowPassword}
                                                                            onMouseDown={handleMouseDownPassword}
                                                                            edge="end"
                                                                        >
                                                                            {showPassword ? <VisibilityOff /> : <Visibility />}
                                                                        </IconButton>
                                                                    </InputAdornment>
                                                                }

                                                            />
                                                            {/* <TextField variant="outlined" type={showPassword ? 'text' : 'password'} onChange={(e) => { setPASSANGER_DETAILS({ ...PASSANGER_DETAILS, c_password: e.target.value }) }} /> */}
                                                        </FormControl>
                                                    </div>






                                                    <div className="col-md-12 mb-3">
                                                        <FormControl fullWidth>
                                                            <div className='label' >Address Line </div>
                                                            <TextField  InputProps={{ sx: { height: '3rem' } }} variant="outlined" value={ADDRESS_DETAILS.full_address} onChange={(e) => { setADDRESS_DETAILS({ ...ADDRESS_DETAILS, full_address: e.target.value }) }} />
                                                        </FormControl>
                                                    </div>
                                                    <div className="col-md-6 mb-3">
                                                        <FormControl fullWidth>
                                                            <div className='label' >City</div>
                                                            <TextField  InputProps={{ sx: { height: '3rem' } }} variant="outlined" value={ADDRESS_DETAILS.city} onChange={(e) => { setADDRESS_DETAILS({ ...ADDRESS_DETAILS, city: e.target.value }) }} />
                                                        </FormControl>
                                                    </div>
                                                    <div className="col-md-6 mb-3">
                                                        <FormControl fullWidth>
                                                            <div className='label' >State</div>
                                                            <Select
                                                                value={SET_STATE}
                                                                onChange={(e) => { StateHandleChange(e) }}
                                                                style={{ height: '3rem' }}
                                                            >
                                                                <MenuItem value="-1" >Select state</MenuItem>
                                                                {STATE.map((data, ind) => (
                                                                    <MenuItem value={ind} key={ind}>{data?.name}</MenuItem>
                                                                ))}

                                                            </Select>
                                                        </FormControl>
                                                    </div>
                                                    <div className="col-md-6 mb-3">
                                                        <FormControl fullWidth>
                                                            <div className='label'  >Country</div>
                                                            <TextField  InputProps={{ sx: { height: '3rem' } }} variant="outlined" value={ADDRESS_DETAILS.country} disabled onChange={(e) => { setADDRESS_DETAILS({ ...ADDRESS_DETAILS, country: e.target.value }) }} />
                                                        </FormControl>
                                                    </div>
                                                    <div className="col-md-6 mb-3">
                                                        <FormControl fullWidth>
                                                            <div className='label' >Pincode/ Zip Code</div>
                                                            <TextField  InputProps={{ sx: { height: '3rem' } }} variant="outlined" value={ADDRESS_DETAILS.pincode} onChange={(e) => { setADDRESS_DETAILS({ ...ADDRESS_DETAILS, pincode: e.target.value }) }} />
                                                        </FormControl>
                                                    </div>

                                                    <div className="col-12 mb-4">
                                                        <FormControl>
                                                            {/* <FormLabel id="demo-controlled-radio-buttons-group">Gender</FormLabel> */}
                                                            <RadioGroup
                                                                row
                                                                aria-labelledby="demo-controlled-radio-buttons-group"
                                                                name="controlled-radio-buttons-group"
                                                                value={ChangeRadio}
                                                            // onChange={handleChange}
                                                            >
                                                                <FormControlLabel value={1} control={<Radio color='default'   onChange={()=>setChangeRadio(1)} />} label="Defence Forces" />
                                                                <FormControlLabel value={2} control={<Radio color='default'   onChange={()=>setChangeRadio(2)} />} label="Other Persons" />
                                                            </RadioGroup>
                                                        </FormControl>
                                                    </div>

                                                    <div className="col-12 mb-4">
                                                        <p className='text-start'>
                                                            <Checkbox
                                                                color="default"
                                                                checked={IsChecked}
                                                                onChange={(e) => (setIsChecked(e.target.checked))} />
                                                            <span>

                                                                I agree with the <Link className='ancher_hover' target='_blank' to='/terms-and-conditions'>terms & conditions</Link>,  <Link className='ancher_hover' target='_blank' to="/privacy-policy">privacy policy</Link>, <Link className='ancher_hover' target='_blank' to='/booking-policy'>booking policy</Link> and I understand and accept the <Link to='/user-agreement' target='_blank'>user agreement</Link> .
                                                            </span>

                                                        </p>

                                                    </div>

                                                </div>
                                            </div>

                                            <div className="col-12">
                                                <div className="row justify-content-between align-items-center">
                                                    <div className="col-auto">
                                                        <div className="loginBtn">
                                                            <button disabled={IsChecked !== true && true} className="btn cust_btn_one border-0" onClick={FinalLoginPayload}>
                                                                <Login /> &#160; Register
                                                            </button>
                                                        </div>


                                                        {/* <button onClick={()=>setIsOpen(true)}> ontest</button> */}
                                                    </div>
                                                    {/* <div className="col-auto">
                                                        Forgot your
                                                        <Link to="/forgot-password"> <u>password ?</u></Link>
                                                    </div> */}
                                                </div>
                                                <div className="pt-4"></div>

                                                {ErrorHandel !== null && <p className='bg-dark p-3 rounded-2'  >{ErrorHandel}</p>}
                                                {/* <div className='alert alert-red'>
                                                    <p className='m-0'>{ErrorHandel}</p>
                                                </div> */}
                                            </div>
                                            <hr />
                                            <div className="col-12 text-center pt-3">

                                                <p className='m-0'> Already have an account? <Link to='/login'><u>Log in</u></Link></p>
                                            </div>
                                        </div>

                                    </ThemeProvider>
                                </div>
                            </div>

                            <div>

                            </div>


                        </div>

                    </div>
                </div>
            </section>

            <Dialog open={isOpen} onClose={handleDialogClose}   >
                <div className="reg_success_main py-5" style={{ backgroundImage: 'url("img/game.gif")' }} onClick={(event) => { event.stopPropagation(); }}>
                    <DialogTitle className='text-center'>Registration Successful!</DialogTitle>
                    <DialogContent className='text-center'>
                        <p>Your registration has been successfully completed.</p>
                        {/* <figure>
                            <img src="img/game.gif" alt="" className='w-25' />
                        </figure> */}

                        {IsVerifyed === false && <div className="">
                            <p>Please enter OTP for verify your account. <br /> You will get 6 digit OTP in this <b>{GetPhoneNumber}</b> mobile number.</p>
                            <div className="row mx-0 pt-4 align-items-center" >
                                <div className="col-md">
                                    <FormControl fullWidth>
                                        {/* <div className='label' >City</div> */}
                                        <TextField placeholder='Enter 6 digit OTP' variant="outlined" onChange={(e) => { setGetOTP(e.target.value) }} />
                                    </FormControl>
                                </div>
                                <div className="col-auto mx-auto pt-4 pt-md-0" >
                                    <button className="cust_btn_one" onClick={verify_otp}>
                                        Verify Otp
                                    </button>
                                </div>
                            </div>
                            {otpValidation !== '' && <p className='text-start ps-3 pt-2 text-danger'>{otpValidation}</p>}
                        </div>
                        }

                        {IsVerifyed === true && <div className="text-center pt-5">
                            <h5 className='text-success mb-5'>
                                ✓ Your account is verified.
                            </h5>
                            <Link to="/login" className="cust_btn_one"><span className='px-4'>login now</span> </Link>
                        </div>}
                    </DialogContent>
                    <DialogActions>
                        {/* <Button onClick={handleDialogClose}>Close</Button> */}
                    </DialogActions>
                </div>
            </Dialog>



        </main>
    );
}

export default RegisterPage;


// https://dev.to/codebucks/form-validation-in-reactjs-by-building-reusable-custom-hook-1bg7