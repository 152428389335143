import { TopicOutlined } from '@mui/icons-material'
import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import Flight_Search_Section from '../Slices/Flight_Search_Section'
import TOP_DOMESTICE_ROUTES from '../DATA_SET/TopDomesticeRoutes.json'
import DirectorIntro from '../Slices/DirectorIntro'

const OFFER_DEALS_DATA = [
  {
    img: "/img/2.png",
    text1: { from: 'Srinagar', dep: 'SXR', to: 'Delhi', arv: 'DEL' },
    flight_combo_id:1,
    text2: '09 Jan 2023 - 16 Jan 2023',
    text3: 'Economy from',
    text4: '₹ 10,000'
  },
  {
    img: "/img/3.png",
    text1: { from: 'Delhi', dep: 'DEL', to: 'Srinagar', arv: 'SXR' },
    flight_combo_id:2,
    text2: '09 Jan 2023 - 16 Jan 2023',
    text3: 'Economy from',
    text4: '₹ 10,000'
  },
  {
    img: "/img/4.png",
    text1: { from: 'Leh', dep: 'IXL', to: 'Delhi', arv: 'DEL' },
    flight_combo_id:3,
    text2: '09 Jan 2023 - 16 Jan 2023',
    text3: 'Economy from',
    text4: '₹ 10,000'
  },
  {
    img: "/img/5.png",
    text1: { from: 'Delhi', dep: 'DEL', to: 'Leh', arv: 'IXL' },
    flight_combo_id:4,
    text2: '09 Jan 2023 - 16 Jan 2023',
    text3: 'Economy from',
    text4: '₹ 10,000'
  },
  {
    img: "/img/6.png",
    text1: { from: 'Kolkata', dep: 'CCU', to: 'Delhi', arv: 'DEL' },
    flight_combo_id:5,
    text2: '09 Jan 2023 - 16 Jan 2023',
    text3: 'Economy from',
    text4: '₹ 10,000'
  },
]
export default function HomePage() {
  const [SEND_DEAL, setSEND_DEAL] = useState({});
  const [IsSelected, setIsSelected] = useState(0);

  useEffect(() => {
    if (localStorage.getItem("islogin") !== 'true' || null) {
      console.log(localStorage.getItem("islogin"))
    } else {
      console.log(localStorage.getItem("islogin"));
      // window.location.reload();
    }

  });


  const getDeal = (data) => {
    setSEND_DEAL(data);
    setIsSelected(data);
    window.scrollTo(0,0)
  }

  return (
    <main className='HomePageWraper'>
      <section className="hero_section" style={{backgroundImage:'url("/img/banner_plane.png")'}}>
         {/* <figure>
          <img src="/img/banner_plane.png" alt="" className='w-100' />
        </figure> */}

        <div className="floating_content">
          <div className="content_type_one">
            <div className="container">
              <div className="row">
                <div className="col-md-9 col-lg-5 col-xl-5 col-xxl-7 ">
                  <h1 className='mb-md-4'>Make our defence persons
                    life very simple and Joyful</h1>
                  <p>
                    Armed Forces | Paramilitary Forces | ESM or Veterans | Dependence | All Other Persons
                  </p>
                  <div className="d-flex align-items-center pt-md-3">
                    <div className="btnBox me-md-3">
                      <a href="https://wa.me/+919336111631" target='_blank' className='text-white'>
                        <img src="/img/whatsapp-121.svg" alt="" style={{width:'1.6rem'}} />
                     &#160;   Chat on WhatsApp
                      </a>
                      {/* <Link to="/register" className='cust_btn_one'>Sign up</Link> */}
                    </div>
                    <div className="call ps-5 ps-md-0">
                      | &#160;
                      <a href="tel:+919336111631"  className='text-white'>
                        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path d="M15.8868 12.5356C15.9205 12.7936 15.8419 13.018 15.6513 13.2086L13.4134 15.4296C13.3124 15.5418 13.1806 15.6372 13.018 15.7157C12.8553 15.7942 12.6955 15.8447 12.5385 15.8671C12.5273 15.8671 12.4936 15.87 12.4374 15.8756C12.3814 15.8812 12.3085 15.8841 12.2188 15.8841C12.0056 15.8841 11.6606 15.8476 11.1839 15.7747C10.7071 15.7017 10.1239 15.5222 9.43403 15.2362C8.74404 14.9502 7.96164 14.521 7.0867 13.949C6.21176 13.377 5.28068 12.5917 4.29354 11.5933C3.50831 10.8193 2.85769 10.079 2.34169 9.37228C1.82568 8.66555 1.41063 8.01212 1.09654 7.41199C0.782423 6.81185 0.546855 6.2678 0.389809 5.77983C0.232764 5.29187 0.126197 4.87121 0.0701096 4.51786C0.0140219 4.16451 -0.00841315 3.88688 0.00280438 3.68496C0.0140219 3.48304 0.0196307 3.37087 0.0196307 3.34843C0.0420658 3.19139 0.0925447 3.03154 0.171067 2.86888C0.24959 2.70623 0.344939 2.57442 0.457114 2.47347L2.69501 0.235568C2.85206 0.0785227 3.03154 0 3.23345 0C3.37928 0 3.50828 0.0420657 3.62046 0.126197C3.73263 0.210329 3.82798 0.314091 3.90651 0.437484L5.70692 3.85322C5.80788 4.0327 5.83592 4.22901 5.79105 4.44214C5.74618 4.65528 5.65083 4.83476 5.505 4.98058L4.68052 5.80507C4.65808 5.82751 4.63845 5.86397 4.62162 5.91444C4.6048 5.96492 4.59638 6.00699 4.59638 6.04064C4.64125 6.27621 4.74221 6.54543 4.89926 6.8483C5.03387 7.11753 5.24139 7.44564 5.52183 7.83264C5.80227 8.21965 6.20049 8.66552 6.7165 9.17031C7.22129 9.68637 7.66999 10.0873 8.0626 10.3734C8.45513 10.6594 8.78336 10.8698 9.04697 11.0044C9.31058 11.139 9.5125 11.2203 9.65269 11.2483L9.86296 11.2904C9.8854 11.2904 9.92194 11.282 9.97236 11.2652C10.0228 11.2483 10.0593 11.2287 10.0818 11.2062L11.0408 10.2303C11.2429 10.0509 11.4783 9.96111 11.7476 9.96111C11.9383 9.96111 12.0897 9.99474 12.2018 10.0621H12.2186L15.4661 11.9803C15.7017 12.1262 15.8419 12.3112 15.8868 12.5356Z" fill="currentcolor" />
                        </svg>

                           call +91 9336 111631</a>
                    </div>

                   <div className="ps-3">
                   <Link to={'/register'} className="cust_btn_one">Register</Link>
                   </div>
                  </div>
                </div>
              </div>
            </div>


          </div>

          <div className="content_type_two">
            <div className="container">
              <div className="holder_bg">
                <Flight_Search_Section send_deal={SEND_DEAL} />
              </div>
            </div>
          </div>
        </div>

      </section>
      <section className='OFFER_DEALS_WRAP'>
        {/* <div className="round_cercle">
          <svg width="628" height="1165" viewBox="0 0 628 1165" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g opacity="0.4" filter="url(#filter0_f_271_35)">
              <path d="M803.789 340.093C665.716 214.673 455.112 221.492 333.393 355.324C211.673 489.156 224.93 699.321 363.004 824.741C501.077 950.161 711.68 943.342 833.4 809.511C955.119 675.679 941.862 465.514 803.789 340.093Z" fill="url(#paint0_linear_271_35)" />
            </g>
            <defs>
              <filter id="filter0_f_271_35" x="0.113434" y="0.313354" width="1166.57" height="1164.21" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                <feFlood flood-opacity="0" result="BackgroundImageFix" />
                <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
                <feGaussianBlur stdDeviation="125" result="effect1_foregroundBlur_271_35" />
              </filter>
              <linearGradient id="paint0_linear_271_35" x1="771.394" y1="729.06" x2="405.01" y2="370.709" gradientUnits="userSpaceOnUse">
                <stop stop-color="#BD00FF" />
                <stop offset="1" stop-color="#1400FF" />
              </linearGradient>
            </defs>
          </svg>
         </div> */}
        <div className="container mb-5">
          <div className="head mb-3 mb-lg-5 ">
            <h6>OFFER DEALS</h6>
            <h2>Todays Transfer Deals</h2>
          </div>

          <div className="row">
            <div className="col-lg-5 mt-4">
              {OFFER_DEALS_DATA.slice(0, 1).map((data, ind) => (
                <div className="h-100" key={ind}>
                  <div className={`card h-100 ${IsSelected === data.flight_combo_id && 'active'}`}  >
                    <figure><img src={data.img} alt="" /></figure>
                    <h4> {data.text1.from} ({data.text1.dep}) 	&#10230; {data.text1.to} ({data.text1.arv})</h4>
                    <p>Book flight for this location today, you will get exciting price.</p>
                    <div>
                      <button className="btn cust_btn_one" onClick={() => getDeal(data.flight_combo_id)}>Choose deal</button>
                    </div>
                    {/* <p>{data.text2}</p>
                    <h5>{data.text3} <b>{data.text4}</b></h5> */}
                  </div>
                </div>
              ))}
            </div>
            <div className="col-lg-7">
              <div className="row">
                {OFFER_DEALS_DATA.slice(1).map((data, ind) => (
                  <div className="col-md-6 mt-4" key={ind}>
                    <div className={`card  ${IsSelected === data.flight_combo_id && 'active'}`}>
                      <figure><img src={data.img} alt="" /></figure>
                      <h4> {data.text1.from} ({data.text1.dep}) 	&#10230; {data.text1.to} ({data.text1.arv})</h4>

                      <p>Book flight for this location today, you will get exciting price.</p>
                      <div>
                        <button className="btn cust_btn_one" onClick={() => getDeal(data.flight_combo_id)}>Choose deal</button>
                      </div>
                      {/* <p>{data.text2}</p>
                      <h5>{data.text3} <b>{data.text4}</b></h5> */}
                    </div>
                  </div>
                ))}

              </div>
            </div>
          </div>
        </div>

        {/* <div className="round_cercle_two">
          <svg width="880" height="1552" viewBox="0 0 880 1552" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g opacity="0.2" filter="url(#filter0_f_271_5)">
              <path d="M451.27 392.407C232.75 193.912 -100.56 204.704 -293.198 416.511C-485.836 628.318 -464.855 960.934 -246.335 1159.43C-27.8146 1357.92 305.495 1347.13 498.133 1135.33C690.771 923.518 669.79 590.902 451.27 392.407Z" fill="url(#paint0_linear_271_5)" />
            </g>
            <defs>
              <filter id="filter0_f_271_5" x="-674.999" y="0.317505" width="1554.93" height="1551.2" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                <feFlood flood-opacity="0" result="BackgroundImageFix" />
                <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
                <feGaussianBlur stdDeviation="125" result="effect1_foregroundBlur_271_5" />
              </filter>
              <linearGradient id="paint0_linear_271_5" x1="-246.335" y1="1159.43" x2="450.792" y2="391.973" gradientUnits="userSpaceOnUse">
                <stop stop-color="#BD00FF" />
                <stop offset="1" stop-color="#1400FF" />
              </linearGradient>
            </defs>
          </svg>

        </div> */}

        <div className="top_left_flight d-none d-lg-block">
          <figure className=''><img src="/img/8.png" alt="" className='' /></figure>

        </div>
      </section>


      <section className="TOP_DOMESTIC_ROUTES_WRAP">

        <figure className='mainImg d-none d-lg-block'><img src="/img/7.png" alt="" className='w-100' /></figure>

        <div className="floatingSection">
          <div className="container">
            <div className="head mb-5 mb-lg-5">
              <h2>Top Domestic Routes</h2>
            </div>

            <div className="row">
              {TOP_DOMESTICE_ROUTES.map((data, ind) => (
                <div className="col-sm-6 col-lg-4 mb-3">
                  <div className="chips pb-3">
                    <div className={`inside ${IsSelected === data.flight_combo_id && 'active'}`} onClick={() => getDeal(data.flight_combo_id)}>
                      <svg width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M5.41605 8.45895L4.25355 7.29645C4.15887 7.20176 4.15887 7.04824 4.25355 6.95355C4.34824 6.85887 4.50176 6.85887 4.59645 6.95355L5.23395 7.59105C6.07124 8.42835 7.42876 8.42835 8.26605 7.59105L13.0286 2.82855C13.1232 2.73387 13.2768 2.73387 13.3714 2.82855C13.4661 2.92324 13.4661 3.07676 13.3714 3.17145L8.08395 8.45895C7.34723 9.19567 6.15277 9.19566 5.41605 8.45895ZM7.5 14C10.8025 14 13.547 11.5073 13.9493 8.31088C13.9745 8.1104 14.1257 8 14.25 8C14.3208 8 14.3801 8.02866 14.4181 8.0679C14.4541 8.10502 14.4701 8.14914 14.4651 8.199C14.1116 11.7235 11.1124 14.5 7.5 14.5C3.65114 14.5 0.5 11.3489 0.5 7.5C0.5 3.65114 3.65114 0.5 7.5 0.5C8.01196 0.5 8.53073 0.567345 9.04506 0.67966C9.12533 0.697189 9.16998 0.746812 9.18731 0.815143C9.20591 0.888488 9.18931 0.978588 9.11646 1.05144C9.05239 1.11551 8.94127 1.15407 8.81052 1.12739C8.39213 1.04202 7.96441 1 7.5 1C3.92386 1 1 3.92386 1 7.5C1 11.0761 3.92386 14 7.5 14Z" fill="url(#paint0_linear_271_395)" fill-opacity="0.7" stroke="url(#paint1_linear_271_395)" />
                        <defs>
                          <linearGradient id="paint0_linear_271_395" x1="19.4012" y1="11.1877" x2="0.719336" y2="5.4003" gradientUnits="userSpaceOnUse">
                            <stop stop-color="#BD00FF" />
                            <stop offset="1" stop-color="#1400FF" />
                          </linearGradient>
                          <linearGradient id="paint1_linear_271_395" x1="19.4012" y1="11.1877" x2="0.719336" y2="5.4003" gradientUnits="userSpaceOnUse">
                            <stop stop-color="#BD00FF" />
                            <stop offset="1" stop-color="#1400FF" />
                          </linearGradient>
                        </defs>
                      </svg>

                      {data.from} -  {data.to}

 

                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>

        {/* <div className="bottom_right_svg">
          <svg width="689" height="1552" viewBox="0 0 689 1552" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g opacity="0.2" filter="url(#filter0_f_271_6)">
              <path d="M1126.27 392.407C907.75 193.912 574.44 204.704 381.802 416.511C189.164 628.318 210.145 960.934 428.665 1159.43C647.185 1357.92 980.495 1347.13 1173.13 1135.33C1365.77 923.518 1344.79 590.902 1126.27 392.407Z" fill="url(#paint0_linear_271_6)" />
            </g>
            <defs>
              <filter id="filter0_f_271_6" x="0.00115967" y="0.317444" width="1554.93" height="1551.2" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                <feFlood flood-opacity="0" result="BackgroundImageFix" />
                <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
                <feGaussianBlur stdDeviation="125" result="effect1_foregroundBlur_271_6" />
              </filter>
              <linearGradient id="paint0_linear_271_6" x1="428.665" y1="1159.43" x2="1125.79" y2="391.973" gradientUnits="userSpaceOnUse">
                <stop stop-color="#BD00FF" />
                <stop offset="1" stop-color="#1400FF" />
              </linearGradient>
            </defs>
          </svg>

        </div> */}
        <div className="bottom_right_flight d-none d-lg-block">
          <figure className=''><img src="/img/9.png" alt="" className='' /></figure>

        </div>

      </section>


      <section className='py-5'>
        <DirectorIntro />
      </section>

      <section className="">
        <img src="/img/test.png" alt="" className='w-100' />
      </section>
    </main>
  )
}
