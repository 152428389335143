import React, { useState, useEffect } from 'react'
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import { styled } from '@mui/material/styles';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import ExpandMore from '@material-ui/icons/ExpandMore';
import TextField from '@mui/material/TextField';
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';
import Autocomplete from '@mui/material/Autocomplete';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { MobileDatePicker } from '@mui/x-date-pickers/MobileDatePicker';
import { format } from 'date-fns';
import DEFENCE_FORCES_DATA from "../DATA_SET/Passenger_Profetion.json";
import OTHER_PERSONE_DATA from "../DATA_SET/Passenger_Profetion_Other_Persone.json";
import { FormControlLabel, Radio, RadioGroup } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { API_BASE_LINK } from '../API_BASE_PATH';
import { ThemeProvider, } from '@mui/material/styles';
import { createTheme } from '@material-ui/core/styles';
import { Add, Remove } from '@material-ui/icons';

const HtmlTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
        background: `rgba(255, 255, 255, 0.2)`,
        backdropFilter: `blur(25px)`,
        borderadius: `30px`,
        maxWidth: 220,
        fontSize: theme.typography.pxToRem(12),
    },
}));

const PRE_SET_AIRPORT = [{
    airport_code: "BOM",
    airport_name: "Mumbai",
    city_code: "BOM",
    city_name: "Mumbai",
    country_code: "IN ",
    id: 1475
},
{
    id: 1915,
    airport_name: "Indira Gandhi Airport",
    airport_code: "DEL",
    city_name: "Delhi",
    city_code: "DEL",
    country_code: "IN "
},
{
    "id": 4721,
    "airport_name": "Srinagar",
    "airport_code": "SXR",
    "city_name": "Srinagar",
    "city_code": "SXR",
    "country_code": "IN "
},
{
    "id": 2723,
    "airport_name": "Leh",
    "airport_code": "IXL",
    "city_name": "Leh",
    "city_code": "IXL",
    "country_code": "IN "
},
{
    "id": 1644,
    "airport_name": "Calcutta",
    "airport_code": "CCU",
    "city_name": "Kolkata",
    "city_code": "CCU",
    "country_code": "IN "
}
]

const darkTheme = createTheme({
    palette: {
        mode: 'dark',
    },
});
export default function Flight_Search_Section(props) {
    const navigate = useNavigate();
    const [CUST_SWITCH, setCUST_SWITCH] = useState(false);
    const [PASSANGER_CHOOSE, setPASSANGER_CHOOSE] = useState(false);
    const [DEFENCE_FORCES, setDEFENCE_FORCES] = useState(DEFENCE_FORCES_DATA);
    const [OTHER_PERSONE, setOTHER_PERSONE] = useState(OTHER_PERSONE_DATA);
    const [DEFFNCE_FORCE_SLECTED, setDEFFNCE_FORCE_SLECTED] = useState('0');
    const [OTHER_PERSON_SLECTED, setOTHER_PERSON_SLECTED] = useState('');
    const [ADD_PASSANGER_DROP_DOWN, setADD_PASSANGER_DROP_DOWN] = useState(false);
    const [ADULT_COUNT, setADULT_COUNT] = useState(1);
    const [CHILDREN_COUNT, setCHILDREN_COUNT] = useState(0);
    const [INFANT_COUNT, setINFANT_COUNT] = useState(0);
    const [PASSANGER_CLASS, setPASSANGER_CLASS] = useState(2);
    const [loading, setloading] = useState(false);
    const [ALL_FLYING_ZONE_LIST, setALL_FLYING_ZONE_LIST] = useState(PRE_SET_AIRPORT);
    const [GetLastSearch, setGetLastSearch] = useState({});

    const [FLIGHT_SEARCH_PAYLOAD_COLLECTION, setFLIGHT_SEARCH_PAYLOAD_COLLECTION] = useState(
        {
            Origin: ALL_FLYING_ZONE_LIST[0],
            Destination: ALL_FLYING_ZONE_LIST[1],
            PreferredDepartureDate: format(new Date(), 'yyyy-MM-dd'),
            PreferredDepartureTime: format(new Date(), 'hh:mm:ss'),
            PreferredArrivalDate: null,
            PreferredArrivalTime: format(new Date(), 'hh:mm:ss'),
            today: new Date(),
            PassangerClass: 'Economy',
            AdultCount: ADULT_COUNT,
            ChildCount: CHILDREN_COUNT,
            InfantCount: INFANT_COUNT,
            JourneyType: 1,
            personType: {
                "type_id": "0",
                "type_name": "Indian Army",
                "type": "defence"
            }
        }
    );

    useEffect(() => {
        // console.log('props.sendLastSearch', props?.sendLastSearch);
        setGetLastSearch(props?.sendLastSearch);

        if (props?.sendLastSearch !== undefined && props?.sendLastSearch !== null) {
            setFLIGHT_SEARCH_PAYLOAD_COLLECTION({
                ...FLIGHT_SEARCH_PAYLOAD_COLLECTION,
                Origin: props?.sendLastSearch?.Origin,
                Destination: props?.sendLastSearch?.Destination,
                PreferredDepartureDate: props?.sendLastSearch?.PreferredDepartureDate,
                PreferredArrivalDate: props?.sendLastSearch?.PreferredArrivalDate,
                AdultCount: props?.sendLastSearch?.AdultCount,
                ChildCount: props?.sendLastSearch?.ChildCount,
                InfantCount: props?.sendLastSearch?.InfantCount,
                PassangerClass: props?.sendLastSearch?.PassangerClass,
                personType:props?.sendLastSearch?.personType
            })

            setADULT_COUNT(props?.sendLastSearch?.AdultCount);
            setCHILDREN_COUNT(props?.sendLastSearch?.ChildCount);
            setINFANT_COUNT(props?.sendLastSearch?.InfantCount);

            if (props?.sendLastSearch?.PassangerClass === "Economy") {
                setPASSANGER_CLASS(2);
            } else if (props?.sendLastSearch?.PassangerClass === "Premium Economy") {
                setPASSANGER_CLASS(3);
            } else if (props?.sendLastSearch?.PassangerClass === "Business") {
                setPASSANGER_CLASS(4);
            } else {
                setPASSANGER_CLASS(6);
            }
             if (props?.sendLastSearch?.personType?.type === "other") {
                setOTHER_PERSON_SLECTED(props?.sendLastSearch?.personType?.type_id);
                setDEFFNCE_FORCE_SLECTED('')
            } else {
                setOTHER_PERSON_SLECTED('')
                setDEFFNCE_FORCE_SLECTED(props?.sendLastSearch?.personType?.type_id)
            }
            { props?.sendLastSearch?.JourneyType && setCUST_SWITCH(props?.sendLastSearch?.JourneyType === 1 ? false : true) }
        } else {
            // console.log('props?.sendLastSearch === Not found:', props?.sendLastSearch)
        }

    }, [props?.sendLastSearch]);

    useEffect(() => {
        // localStorage.setItem('final_payload', JSON.stringify(FLIGHT_SEARCH_PAYLOAD_COLLECTION));
        // setFLIGHT_SEARCH_PAYLOAD_COLLECTION({ ...FLIGHT_SEARCH_PAYLOAD_COLLECTION, Destination: props.send_deal.dep })
        // console.log('--------------------', props?.send_deal)
        if (props?.send_deal === 1) {
        }

        let flight_combo_id = props?.send_deal;

        switch (flight_combo_id) {
            case 1:
                setFLIGHT_SEARCH_PAYLOAD_COLLECTION({ ...FLIGHT_SEARCH_PAYLOAD_COLLECTION, Origin: ALL_FLYING_ZONE_LIST[2], Destination: ALL_FLYING_ZONE_LIST[1] });
                break;
            case 2:
                setFLIGHT_SEARCH_PAYLOAD_COLLECTION({ ...FLIGHT_SEARCH_PAYLOAD_COLLECTION, Origin: ALL_FLYING_ZONE_LIST[1], Destination: ALL_FLYING_ZONE_LIST[2] });
                break;
            case 3:
                setFLIGHT_SEARCH_PAYLOAD_COLLECTION({ ...FLIGHT_SEARCH_PAYLOAD_COLLECTION, Origin: ALL_FLYING_ZONE_LIST[3], Destination: ALL_FLYING_ZONE_LIST[1] });
                break;
            case 4:
                setFLIGHT_SEARCH_PAYLOAD_COLLECTION({ ...FLIGHT_SEARCH_PAYLOAD_COLLECTION, Origin: ALL_FLYING_ZONE_LIST[1], Destination: ALL_FLYING_ZONE_LIST[3] });
                break;
            case 5:
                setFLIGHT_SEARCH_PAYLOAD_COLLECTION({ ...FLIGHT_SEARCH_PAYLOAD_COLLECTION, Origin: ALL_FLYING_ZONE_LIST[4], Destination: ALL_FLYING_ZONE_LIST[1] });
                break;
            case 6:
                setFLIGHT_SEARCH_PAYLOAD_COLLECTION({ ...FLIGHT_SEARCH_PAYLOAD_COLLECTION, Origin: ALL_FLYING_ZONE_LIST[4], Destination: ALL_FLYING_ZONE_LIST[1] });
                break;
            case 7:
                setFLIGHT_SEARCH_PAYLOAD_COLLECTION({ ...FLIGHT_SEARCH_PAYLOAD_COLLECTION, Origin: ALL_FLYING_ZONE_LIST[4], Destination: ALL_FLYING_ZONE_LIST[1] });
                break;
            case 8:
                setFLIGHT_SEARCH_PAYLOAD_COLLECTION({ ...FLIGHT_SEARCH_PAYLOAD_COLLECTION, Origin: ALL_FLYING_ZONE_LIST[4], Destination: ALL_FLYING_ZONE_LIST[1] });
                break;
            case 9:
                setFLIGHT_SEARCH_PAYLOAD_COLLECTION({ ...FLIGHT_SEARCH_PAYLOAD_COLLECTION, Origin: ALL_FLYING_ZONE_LIST[4], Destination: ALL_FLYING_ZONE_LIST[1] });
                break;
            case 10:
                setFLIGHT_SEARCH_PAYLOAD_COLLECTION({ ...FLIGHT_SEARCH_PAYLOAD_COLLECTION, Origin: ALL_FLYING_ZONE_LIST[4], Destination: ALL_FLYING_ZONE_LIST[1] });
                break;
            case 11:
                setFLIGHT_SEARCH_PAYLOAD_COLLECTION({ ...FLIGHT_SEARCH_PAYLOAD_COLLECTION, Origin: ALL_FLYING_ZONE_LIST[4], Destination: ALL_FLYING_ZONE_LIST[1] });
                break;
            case 12:
                setFLIGHT_SEARCH_PAYLOAD_COLLECTION({ ...FLIGHT_SEARCH_PAYLOAD_COLLECTION, Origin: ALL_FLYING_ZONE_LIST[4], Destination: ALL_FLYING_ZONE_LIST[1] });
                break;
            case 13:
                setFLIGHT_SEARCH_PAYLOAD_COLLECTION({ ...FLIGHT_SEARCH_PAYLOAD_COLLECTION, Origin: ALL_FLYING_ZONE_LIST[4], Destination: ALL_FLYING_ZONE_LIST[1] });
                break;
            case 14:
                setFLIGHT_SEARCH_PAYLOAD_COLLECTION({ ...FLIGHT_SEARCH_PAYLOAD_COLLECTION, Origin: ALL_FLYING_ZONE_LIST[4], Destination: ALL_FLYING_ZONE_LIST[1] });
                break;
            case 15:
                setFLIGHT_SEARCH_PAYLOAD_COLLECTION({ ...FLIGHT_SEARCH_PAYLOAD_COLLECTION, Origin: ALL_FLYING_ZONE_LIST[4], Destination: ALL_FLYING_ZONE_LIST[1] });
                break;
            case 16:
                setFLIGHT_SEARCH_PAYLOAD_COLLECTION({ ...FLIGHT_SEARCH_PAYLOAD_COLLECTION, Origin: ALL_FLYING_ZONE_LIST[4], Destination: ALL_FLYING_ZONE_LIST[1] });
                break;
            case 17:
                setFLIGHT_SEARCH_PAYLOAD_COLLECTION({ ...FLIGHT_SEARCH_PAYLOAD_COLLECTION, Origin: ALL_FLYING_ZONE_LIST[4], Destination: ALL_FLYING_ZONE_LIST[1] });
                break;
            default:
            // console.log("Today is some other day");
        }

    }, [props?.send_deal]);




    const getJ_Type = () => {
        setCUST_SWITCH(!CUST_SWITCH);
    }
    useEffect(() => {
        if (CUST_SWITCH === false) {
            setFLIGHT_SEARCH_PAYLOAD_COLLECTION({ ...FLIGHT_SEARCH_PAYLOAD_COLLECTION, JourneyType: 1 });
        } else {
            setFLIGHT_SEARCH_PAYLOAD_COLLECTION({ ...FLIGHT_SEARCH_PAYLOAD_COLLECTION, JourneyType: 2 });

        }
    }, [CUST_SWITCH]);



    // useEffect(() => {
    //     console.log(DEFENCE_FORCES);
    //     setDEFENCE_FORCES(DEFENCE_FORCES_DATA);
    //     setOTHER_PERSONE(OTHER_PERSONE_DATA);
    // }, [DEFENCE_FORCES_DATA,OTHER_PERSONE_DATA]);

    const handleChange_DEFENCE_FORCES = (event) => {
        setPASSANGER_CHOOSE(false);
        setOTHER_PERSON_SLECTED('');
        setDEFFNCE_FORCE_SLECTED(event.target.value);
        console.log(DEFENCE_FORCES_DATA[event.target?.value]?.text);
        setFLIGHT_SEARCH_PAYLOAD_COLLECTION({ ...FLIGHT_SEARCH_PAYLOAD_COLLECTION, personType: ({ type_id: DEFENCE_FORCES_DATA[event.target?.value]?.id, type_name: DEFENCE_FORCES_DATA[event.target?.value]?.text, type: 'defence' }) });
    };
    const handleChange_OTHER_PERSONE_DATA = (event) => {
        setPASSANGER_CHOOSE(true);
        setDEFFNCE_FORCE_SLECTED('');
        setOTHER_PERSON_SLECTED(event.target.value);
        console.log(event.target.value)
        console.log(OTHER_PERSONE_DATA[event.target?.value]?.text, OTHER_PERSONE_DATA[event.target?.value].id);
        setFLIGHT_SEARCH_PAYLOAD_COLLECTION({ ...FLIGHT_SEARCH_PAYLOAD_COLLECTION, personType: ({ type_id: OTHER_PERSONE_DATA[event.target?.value]?.id, type_name: OTHER_PERSONE_DATA[event.target?.value]?.text, type: 'other' }) });

    };




    useEffect(() => {
        switch (PASSANGER_CLASS) {
            case '2':
                console.log('Economy');
                setFLIGHT_SEARCH_PAYLOAD_COLLECTION({ ...FLIGHT_SEARCH_PAYLOAD_COLLECTION, PassangerClass: 'Economy', JourneyType: 2 })
                break;
            case '3':
                console.log('Premium Economy');
                setFLIGHT_SEARCH_PAYLOAD_COLLECTION({ ...FLIGHT_SEARCH_PAYLOAD_COLLECTION, PassangerClass: 'Premium Economy', JourneyType: 3 })
                break;
            case '4':
                console.log('Business');
                setFLIGHT_SEARCH_PAYLOAD_COLLECTION({ ...FLIGHT_SEARCH_PAYLOAD_COLLECTION, PassangerClass: 'Business', JourneyType: 4 })
                break;
            case '6':
                console.log('Fast');
                setFLIGHT_SEARCH_PAYLOAD_COLLECTION({ ...FLIGHT_SEARCH_PAYLOAD_COLLECTION, PassangerClass: 'Fast', JourneyType: 6 })
                break;
            default:
        }

    }, [PASSANGER_CLASS]);



    const onChangeHandle = async value => {
        if (value?.length >= 2) {
            const AIRPORT_LIST_response = await fetch(`${API_BASE_LINK}searchAirport/${value}`);
            const AIRPORT_LIST = await AIRPORT_LIST_response.json();
            if (AIRPORT_LIST?.airports.length >= 0) {
                setloading(false)
            }
            setALL_FLYING_ZONE_LIST(AIRPORT_LIST.airports);
            console.log('----', AIRPORT_LIST.airports)
        } else {
            setloading(true)
            setALL_FLYING_ZONE_LIST(PRE_SET_AIRPORT);
        }
    };

    useEffect(() => {
        setFLIGHT_SEARCH_PAYLOAD_COLLECTION({ ...FLIGHT_SEARCH_PAYLOAD_COLLECTION, AdultCount: ADULT_COUNT, ChildCount: CHILDREN_COUNT, InfantCount: INFANT_COUNT })

    }, [ADULT_COUNT, CHILDREN_COUNT, INFANT_COUNT]);

    const FINAL_SUBMIT = () => {
        //  localStorage.setItem('final_payload', JSON.stringify(FLIGHT_SEARCH_PAYLOAD_COLLECTION));
        let encodedPayload = window.btoa(JSON.stringify(FLIGHT_SEARCH_PAYLOAD_COLLECTION))
        // props.handleClick('modify_search');
        navigate(`/search/${encodedPayload}`);
    }




    return (
        <div className='Flight_Search_Section'>
            <div className="row flex-nowrap justify-content-between align-items-center mb-4">
                <div className="col-auto">
                    <h4 className="m-0">
                        Search Flight
                    </h4>
                </div>
                <div className="col-auto">
                    <div className="d-flex tripType" onClick={getJ_Type}>
                        <b className={`${CUST_SWITCH === false && 'active'}`}>Single Trip</b>
                        <div className="cust_switch"  >
                            <span>
                                <span className={`${CUST_SWITCH === true && 'right'}`}></span>
                            </span>
                        </div>
                        <b className={`${CUST_SWITCH === true && 'active'}`}>Round Trip</b>
                    </div>
                </div>
            </div>

            <div className="row passanger_profetion mb-4">
                <div className="col-6 col-lg-4">
                    <div className="d-md-flex align-items-center w-100" >
                        {/* <div className={`choose ${PASSANGER_CHOOSE === false && 'selected'}`} >
                            <span></span>
                        </div> */}
                        <div className="text text-nowrap  pb-3 pb-md-0">
                            Defence Forces


                            <div className="tip d-inline-block ms-2 me-2 cp">
                                <HtmlTooltip
                                    title={
                                        <React.Fragment>
                                            <h5 className='border-bottom'>Disclemar</h5>
                                            {"Note: You have a defence serving/retired person of the Indian Armed Forces or Indian Paramilitary Forces. You may also choose this option if you are a valid Dependent (NOK/Child/Parent) and veer Naari of a serving/retired Indian Armed Forces and Indian Paramilitary Forces Personnel. Please carry their valid military ID. This will be required for all passengers at Airline's check-in counter for a boarding pass."}
                                        </React.Fragment>
                                    }
                                >
                                    <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M6 5.25C6.14918 5.25 6.29226 5.30926 6.39775 5.41475C6.50324 5.52024 6.5625 5.66332 6.5625 5.8125V8.4375C6.5625 8.58668 6.50324 8.72976 6.39775 8.83525C6.29226 8.94074 6.14918 9 6 9C5.85082 9 5.70774 8.94074 5.60225 8.83525C5.49676 8.72976 5.4375 8.58668 5.4375 8.4375V5.8125C5.4375 5.66332 5.49676 5.52024 5.60225 5.41475C5.70774 5.30926 5.85082 5.25 6 5.25ZM6 3C5.80109 3 5.61032 3.07902 5.46967 3.21967C5.32902 3.36032 5.25 3.55109 5.25 3.75C5.25 3.94891 5.32902 4.13968 5.46967 4.28033C5.61032 4.42098 5.80109 4.5 6 4.5H6.00525C6.20416 4.5 6.39493 4.42098 6.53558 4.28033C6.67623 4.13968 6.75525 3.94891 6.75525 3.75C6.75525 3.55109 6.67623 3.36032 6.53558 3.21967C6.39493 3.07902 6.20416 3 6.00525 3H6Z" fill="white" />
                                        <path fillRule="evenodd" clipRule="evenodd" d="M0 6C0 4.4087 0.632141 2.88258 1.75736 1.75736C2.88258 0.632141 4.4087 0 6 0C7.5913 0 9.11742 0.632141 10.2426 1.75736C11.3679 2.88258 12 4.4087 12 6C12 7.5913 11.3679 9.11742 10.2426 10.2426C9.11742 11.3679 7.5913 12 6 12C4.4087 12 2.88258 11.3679 1.75736 10.2426C0.632141 9.11742 0 7.5913 0 6ZM6 1.125C4.70707 1.125 3.46709 1.63861 2.55285 2.55285C1.63861 3.46709 1.125 4.70707 1.125 6C1.125 7.29293 1.63861 8.53291 2.55285 9.44715C3.46709 10.3614 4.70707 10.875 6 10.875C7.29293 10.875 8.53291 10.3614 9.44715 9.44715C10.3614 8.53291 10.875 7.29293 10.875 6C10.875 4.70707 10.3614 3.46709 9.44715 2.55285C8.53291 1.63861 7.29293 1.125 6 1.125Z" fill="white" />
                                    </svg>
                                </HtmlTooltip>
                            </div>
                        </div>

                        <div className="slect_box w-100 custom_select_modify">
                            <FormControl fullWidth>
                                <InputLabel id="demo-simple-select-label">Choose Your Category</InputLabel>
                                <Select
                                    labelId="demo-simple-select-label"
                                    id="demo-simple-select"
                                    value={DEFFNCE_FORCE_SLECTED}
                                    onChange={handleChange_DEFENCE_FORCES}
                                    IconComponent={ExpandMore}
                                    sx={{ height: '3.3rem' }}
                                >

                                    {DEFENCE_FORCES?.map((data, ind) => (
                                        <MenuItem key={ind * 3} value={data?.id}>{data?.text}</MenuItem>
                                    ))}


                                </Select>
                            </FormControl>
                        </div>
                    </div>
                </div>
                <div className="col-6 col-lg-4">
                    <div className="d-md-flex align-items-center w-100" >
                        {/* <div className={`choose ${PASSANGER_CHOOSE === true && 'selected'}`}  >
                            <span></span>
                        </div> */}
                        <div className="text text-nowrap pb-3 pb-md-0">
                            Other Persons
                        </div>

                        <div className="slect_box w-100 custom_select_modify">
                            <FormControl fullWidth>
                                <InputLabel id="demo-simple-select-label2">Choose Your Category</InputLabel>
                                <Select
                                    labelId="demo-simple-select-label2"
                                    id="demo-simple-select2"
                                    value={OTHER_PERSON_SLECTED}
                                    onChange={handleChange_OTHER_PERSONE_DATA}
                                    IconComponent={ExpandMore}
                                    sx={{ height: '3.3rem' }}
                                >
                                    {OTHER_PERSONE?.map((data, ind) => (
                                        <MenuItem key={ind * 3} value={data?.id}>{data?.text}</MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </div>
                    </div>
                </div>
            </div>

            <div className="row">
                <div className="col-12 col-lg-auto mb-3 mb-lg-0">
                    <div className="flying_zone_setup">
                        {/* <div className="bothIco">
                            <img src="/img/ico4.svg" alt="" />
                        </div> */}
                        <div className="row mx-0 align-items-center h-100">
                            <div className="col-6">
                                <div className="d-flex align-items-center">
                                    <div className="iconBox">
                                        <span>
                                            <img src="/img/ico1.svg" alt="" />
                                        </span>
                                    </div>
                                    <div className="inputBox flex-grow-1 ps-2">
                                        <p>Flying From</p>

                                        <FormControl fullWidth>

                                            <Autocomplete

                                                // onChange={(event, value) => console.log(value)}
                                                onChange={(event, value) =>
                                                    setFLIGHT_SEARCH_PAYLOAD_COLLECTION({ ...FLIGHT_SEARCH_PAYLOAD_COLLECTION, Origin: value })
                                                }
                                                loading={loading}
                                                // onChange={(event, newValue) => {
                                                //     setAllFieldData({ flying_from: newValue });
                                                // }}
                                                disablePortal
                                                id="flying_from"
                                                options={ALL_FLYING_ZONE_LIST}
                                                value={FLIGHT_SEARCH_PAYLOAD_COLLECTION.Origin || null}
                                                // value={props.value || null}
                                                getOptionLabel={(option) => `${option.city_name} (${option.airport_code})`}
                                                renderOption={(props, option) => (
                                                    <Box component="li"   {...props}>

                                                        <div className='myClassList border-bottom w-100'>
                                                            <h6 className='text-nowrap'><b>{option?.airport_code}</b> {option?.airport_name}</h6>
                                                            <p className='text-nowrap'>{option?.city_name} - {option?.country_code}
                                                            </p>
                                                        </div>
                                                    </Box>
                                                )}
                                                renderInput={(params) => <TextField


                                                    onChange={(e) => { onChangeHandle(e.target.value) }}
                                                    {...params}
                                                    // label="Flying from"
                                                    InputProps={{
                                                        ...params.InputProps,
                                                        endAdornment: (
                                                            <React.Fragment>
                                                                {loading ? <CircularProgress color="inherit" size={20} /> : null}
                                                                {params.InputProps.endAdornment}
                                                            </React.Fragment>
                                                        ),
                                                    }}
                                                />}
                                            />
                                        </FormControl>
                                    </div>
                                </div>
                            </div>
                            <div className="col-6  ">
                                <div className="d-flex align-items-center">
                                    <div className="iconBox">
                                        <span>
                                            <img src="/img/ico5.svg" alt="" />
                                        </span>
                                    </div>
                                    <div className="inputBox flex-grow-1 ps-2">
                                        <p>Flying To</p>

                                        <FormControl fullWidth>

                                            <Autocomplete

                                                // onChange={(event, value) => console.log(value)}
                                                onChange={(event, value) =>
                                                    setFLIGHT_SEARCH_PAYLOAD_COLLECTION({ ...FLIGHT_SEARCH_PAYLOAD_COLLECTION, Destination: value })
                                                }
                                                loading={loading}
                                                // onChange={(event, newValue) => {
                                                //     setAllFieldData({ flying_from: newValue });
                                                // }}
                                                disablePortal
                                                id="flying_To"
                                                options={ALL_FLYING_ZONE_LIST}
                                                value={FLIGHT_SEARCH_PAYLOAD_COLLECTION.Destination || null}
                                                // value={props.value || null}
                                                getOptionLabel={(option) => `${option.city_name} (${option.airport_code})`}
                                                renderOption={(props, option) => (
                                                    <Box component="li"   {...props}>
                                                        <div className='myClassList border-bottom w-100'>
                                                            <h6 className='text-nowrap'><b>{option?.airport_code}</b> {option?.airport_name}</h6>
                                                            <p className='text-nowrap'>{option?.city_name} - {option?.country_code}
                                                            </p>
                                                        </div>
                                                    </Box>
                                                )}
                                                renderInput={(params) => <TextField
                                                    onChange={(e) => { onChangeHandle(e.target.value) }}
                                                    {...params}
                                                    // label="Flying from"
                                                    InputProps={{
                                                        ...params.InputProps,
                                                        endAdornment: (
                                                            <React.Fragment>
                                                                {loading ? <CircularProgress color="inherit" size={20} /> : null}
                                                                {params.InputProps.endAdornment}
                                                            </React.Fragment>
                                                        ),
                                                    }}
                                                />}
                                            />
                                        </FormControl>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-6 col-sm mb-3 mb-sm-0">
                    <div className="Date_Pick_Zone">
                        <div className="d-flex align-items-center h-100">
                            <div className="iconBox">
                                <span>
                                    <img src="/img/ico6.svg" alt="" />
                                </span>
                            </div>
                            <div className="inputBox flex-grow-1 ps-2">
                                <p>Depart date</p>

                                <LocalizationProvider dateAdapter={AdapterDayjs}  >
                                    <MobileDatePicker

                                        //  open={true}
                                        minDate={new Date()}
                                        // label="Depart date"
                                        value={FLIGHT_SEARCH_PAYLOAD_COLLECTION.PreferredDepartureDate}
                                        inputFormat="DD-MM-YYYY"
                                        onChange={(newValue) => {
                                            setFLIGHT_SEARCH_PAYLOAD_COLLECTION({
                                                ...FLIGHT_SEARCH_PAYLOAD_COLLECTION,
                                                today: newValue,
                                                PreferredDepartureDate: format(newValue["$d"], 'yyyy-MM-dd'),
                                                PreferredDepartureTime: format(newValue["$d"], 'hh:mm:ss'),
                                                PreferredArrivalDate: format(newValue["$d"], 'yyyy-MM-dd'),
                                            });
                                        }}
                                        renderInput={(params) => <TextField fullWidth {...params} />}
                                    />
                                </LocalizationProvider>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-6 col-sm mb-3 mb-sm-0">
                    <div className="Date_Pick_Zone"  >
                        <div className="d-flex align-items-center h-100">
                            <div className="iconBox">
                                <span>
                                    <img src="/img/ico6.svg" alt="" />
                                </span>
                            </div>
                            <div className="inputBox flex-grow-1 ps-2" >
                                <p>Return date</p>

                                {CUST_SWITCH === false ?
                                    <div className="disableReturnDate text-white" onClick={() => setCUST_SWITCH(!CUST_SWITCH)}>
                                        <i>Tap to add a return date</i>
                                    </div>
                                    :
                                    <LocalizationProvider dateAdapter={AdapterDayjs} fullWidth>
                                        <MobileDatePicker
                                            minDate={FLIGHT_SEARCH_PAYLOAD_COLLECTION.PreferredDepartureDate}
                                            value={FLIGHT_SEARCH_PAYLOAD_COLLECTION.PreferredArrivalDate}
                                            inputFormat="DD-MM-YYYY"
                                            fullWidth
                                            // disabled={RoundTripIsDesabled}
                                            onChange={(value) => {
                                                setFLIGHT_SEARCH_PAYLOAD_COLLECTION({
                                                    ...FLIGHT_SEARCH_PAYLOAD_COLLECTION,

                                                    // trip_type: 'round_trip',
                                                    // return_date: value,
                                                    PreferredArrivalDate: format(value["$d"], 'yyyy-MM-dd'),
                                                    PreferredArrivalTime: format(value["$d"], 'hh:mm:ss')
                                                });

                                            }}
                                            renderInput={(params) => <TextField placeholder='Choose date' fullWidth {...params} />}
                                        />
                                    </LocalizationProvider>
                                }


                            </div>
                        </div>
                    </div>
                </div>
                <div className="col ">
                    <div className="Traveler_Zone">
                        <div className="d-flex align-items-center h-100">
                            <div className="iconBox">
                                <span>
                                    <img src="/img/ico7.svg" alt="" />
                                </span>
                            </div>
                            <div className="inputBox flex-grow-1 ps-2">
                                <p>Travelers</p>
                                <h5 className='' onClick={() => setADD_PASSANGER_DROP_DOWN(!ADD_PASSANGER_DROP_DOWN)}>
                                    {ADULT_COUNT + CHILDREN_COUNT + INFANT_COUNT} &#160;
                                    {FLIGHT_SEARCH_PAYLOAD_COLLECTION.PassangerClass}
                                </h5>
                                {ADD_PASSANGER_DROP_DOWN === true &&
                                    <>
                                        <div className='ADD_PASSANGER_DROP_DOWN'>
                                            <div className="innerRowOne">
                                                <div className="row align-items-center">
                                                    <div className="col-auto me-auto">
                                                        <b>Adults</b>
                                                        <span>Above 12 years</span>
                                                    </div>
                                                    <div className="col-auto">
                                                        <button className="btn p-0 border-0" onClick={() => { ADULT_COUNT > 1 && setADULT_COUNT(ADULT_COUNT - 1) }}>
                                                            <img src="/img/minus.svg" alt="" />
                                                            <Remove />
                                                        </button>
                                                    </div>
                                                    <div className="col-auto px-0">
                                                        <div className="count">
                                                            {ADULT_COUNT}
                                                        </div>
                                                    </div>
                                                    <div className="col-auto">
                                                        <button className="btn p-0 border-0" onClick={() => { ADULT_COUNT < 5 && setADULT_COUNT(ADULT_COUNT + 1) }}>
                                                            <Add />
                                                        </button>
                                                    </div>

                                                </div>
                                                <div className="hr w-100"><hr /></div>
                                                <div className="row align-items-center">
                                                    <div className="col-auto me-auto">
                                                        <b>Children</b>
                                                        <span>2–12 years</span>
                                                    </div>
                                                    <div className="col-auto">
                                                        <button className="btn p-0 border-0" onClick={() => { CHILDREN_COUNT > 0 && setCHILDREN_COUNT(CHILDREN_COUNT - 1) }}>
                                                            <Remove />
                                                        </button>
                                                    </div>
                                                    <div className="col-auto px-0">
                                                        <div className="count">
                                                            {CHILDREN_COUNT}
                                                        </div>
                                                    </div>
                                                    <div className="col-auto">
                                                        <button className="btn p-0 border-0" onClick={() => { CHILDREN_COUNT < 3 && setCHILDREN_COUNT(CHILDREN_COUNT + 1) }}>
                                                            <Add />
                                                        </button>
                                                    </div>

                                                </div>
                                                <div className="hr w-100"><hr /></div>
                                                <div className="row align-items-center">
                                                    <div className="col-auto me-auto">
                                                        <b>Infants</b>
                                                        <span>Below 2 years</span>
                                                    </div>
                                                    <div className="col-auto">
                                                        <button className="btn p-0 border-0" onClick={() => { INFANT_COUNT > 0 && setINFANT_COUNT(INFANT_COUNT - 1) }}>
                                                            <Remove />
                                                        </button>
                                                    </div>
                                                    <div className="col-auto px-0">
                                                        <div className="count">
                                                            {INFANT_COUNT}
                                                        </div>
                                                    </div>
                                                    <div className="col-auto">
                                                        <button className="btn p-0 border-0" onClick={() => { INFANT_COUNT < 2 && setINFANT_COUNT(INFANT_COUNT + 1) }}>
                                                            <Add />
                                                        </button>
                                                    </div>

                                                </div>
                                            </div>
                                            <div className="innerRowTwo">
                                                <hr />
                                                <ThemeProvider theme={darkTheme}>
                                                    <FormControl>
                                                        <RadioGroup
                                                            aria-labelledby="demo-radio-buttons-group-label"
                                                            value={PASSANGER_CLASS}
                                                            name="radio-buttons-group"
                                                            onChange={(event) => setPASSANGER_CLASS(event.target.value)}
                                                        >
                                                            <div className="row">
                                                                <div className="col-6"><FormControlLabel value="2" control={<Radio color="secondary" defaultChecked />} label="Economy" /></div>
                                                                <div className="col-6"><FormControlLabel value="3" control={<Radio color="secondary" />} label="Premium Economy" /></div>
                                                                <div className="col-6"><FormControlLabel value="4" control={<Radio color="secondary" />} label="Business" /></div>
                                                                <div className="col-6"><FormControlLabel value="6" control={<Radio color="secondary" />} label="Fast" /></div>
                                                            </div>
                                                        </RadioGroup>
                                                    </FormControl>
                                                </ThemeProvider>
                                            </div>
                                            <hr />
                                            <div className="innerRowThree">
                                                <div className="row justify-content-between">
                                                    <div className="col-auto">
                                                        <button className="btn btn-sm closeHit" onClick={() => setADD_PASSANGER_DROP_DOWN(false)}>CLOSE</button>
                                                    </div>
                                                    <div className="col-auto">
                                                        <button className="btn btn-sm done_btn" onClick={() => setADD_PASSANGER_DROP_DOWN(false)}>DONE</button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </>}

                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-auto">
                    <div className="Search_Btn_Zone">
                        <button onClick={() => (FINAL_SUBMIT(), setADD_PASSANGER_DROP_DOWN(false))} className='btn d-flex flex-column align-items-center justify-content-center'>
                            <svg width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M11.0095 0C4.93901 0 0 4.93901 0 11.0095C0 17.0803 4.93901 22.0189 11.0095 22.0189C17.0803 22.0189 22.0189 17.0803 22.0189 11.0095C22.0189 4.93901 17.0803 0 11.0095 0ZM11.0095 19.9865C6.05962 19.9865 2.03252 15.9594 2.03252 11.0095C2.03252 6.05967 6.05962 2.03252 11.0095 2.03252C15.9593 2.03252 19.9864 6.05962 19.9864 11.0095C19.9864 15.9593 15.9593 19.9865 11.0095 19.9865Z" fill="white" />
                                <path d="M24.7021 23.2652L18.8756 17.4386C18.4785 17.0416 17.8356 17.0416 17.4385 17.4386C17.0415 17.8353 17.0415 18.479 17.4385 18.8756L23.2651 24.7022C23.4636 24.9007 23.7235 25 23.9836 25C24.2434 25 24.5036 24.9007 24.7021 24.7022C25.0991 24.3055 25.0991 23.6619 24.7021 23.2652Z" fill="white" />
                            </svg>
                            <span>Search</span>
                        </button>
                    </div>
                </div>
            </div>
        </div>
    )
}
