import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { API_BASE_LINK } from '../API_BASE_PATH';
import OtpInput from 'react-otp-input';
function OptInSlice(props) {
    // const [optedIn, setOptedIn] = useState(false);

    const [OTP_STEPS, setOTP_STEPS] = useState(0);
    const [otp, setOtp] = useState('');
    const [IsOtpInvalid, setIsOtpInvalid] = useState('');
    // function handleOptIn() {
    //     setOptedIn(true);
    //     // Your code here to submit the opt-in form
    // }


    const GetOtp = () => {
        let userData = JSON.parse(localStorage.getItem('LoginUserDetails'));
        axios.post(`${API_BASE_LINK}users/request_verification`, {}, {
            headers: {
                'Authorization': userData?.session?.session_token
            }
        })
            .then((response) => {
                console.log(response);
                if (response.status === 200) {
                    setOTP_STEPS(1)
                } else {
                    alert('Retry again')
                }

            })
            .catch((error) => {
                console.log(error);
                alert('Retry again')
            });

    }

    const ResendOtp = () => {
        let userData = JSON.parse(localStorage.getItem('LoginUserDetails'));
        axios.post(`${API_BASE_LINK}users/request_verification`, {}, {
            headers: {
                'Authorization': userData?.session?.session_token
            }
        })
            .then((response) => {
                console.log(response);
                if (response.status === 200) {
                    setIsOtpInvalid('Successfully sent 6 digit OTP on your mobile number.');
                } else {
                    setIsOtpInvalid('Try again');
                }

            })
            .catch((error) => {
                console.log(error);
                setIsOtpInvalid('Retry after some time.');
            });
    }

    useEffect(() => {
        console.log(otp)
        let userData = JSON.parse(localStorage.getItem('LoginUserDetails'));
        if (otp.length === 6) {
            axios.post(`${API_BASE_LINK}users/verification`, {
                "otp": otp
            }, {
                headers: {
                    'Authorization': userData?.session?.session_token
                }
            })
                .then((response) => {
                    console.log(response);
                    if (response.status === 200) {
                        props?.GetIsOtpVerify({ resData: response?.data, status: true });
                        setIsOtpInvalid('')
                    } else {
                        setIsOtpInvalid('Invalid Otp. Retry again')
                    }

                })
                .catch((error) => {
                    console.log(error);
                    setIsOtpInvalid('OTP expired or not valid. Please try sending OTP again.');
                });
        }
    }, [otp]);

    return (
        <div>



            {OTP_STEPS === 0 && (
                <div className="text-center py-5">
                    <h4 className=''>If want to change your password please click on Get OTP.</h4>
                    <p className='mb-5'>You will get 6 digit OTP on this <b>******{props?.sendMnumber?.slice(-4)}</b> mobile number.</p>
                    <button className='btn cust_btn_one' onClick={GetOtp}>Get OTP</button>


                </div>
            )}
            {OTP_STEPS === 1 && (
                <>
                    <div className="text-center">
                        <h4 className='text-center'>6 digit OTP sent to your mobile number. </h4>
                        <p> This is <b>******{props?.sendMnumber?.slice(-4)}</b> last four digit of your mobile number.</p>
                    </div>


                    <div className="OtpFormFild d-flex justify-content-center pt-5">
                        <OtpInput
                            value={otp}
                            onChange={setOtp}
                            numInputs={6}
                            renderSeparator={<span>-</span>}
                            renderInput={(props) => <input  {...props} className='form-control ' />}
                        />


                    </div>
                    {IsOtpInvalid.length > 0 && (
                        <>
                            <div className="pt-4 text-center text-danger">
                                <h5 className=''>{IsOtpInvalid}</h5>

                            </div>




                            <div className="text-center ">
                                {/* <h4 className='text-center'>You will get 6 digit OTP on your mobile number. </h4> */}
                                <p> Please enter correct OTP or click Resend OTP.</p>
                                <button className='btn cust_btn_one' onClick={ResendOtp}>Resend OTP</button>


                            </div>
                        </>
                    )}

                </>
            )}

            {OTP_STEPS === 2 && (

                <div className="text-center py-5">
                    <h4 className='mb-5'>If want to change your password please click on Get OTP.</h4>
                    <button className='btn cust_btn_one' onClick={GetOtp}>Get OTP</button>


                </div>

            )}


        </div>
    );
}


export default OptInSlice;