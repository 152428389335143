import React from 'react'
import { useState, useEffect } from 'react';
import moment from 'moment/moment';
import { Link, useNavigate, useParams } from 'react-router-dom';
import axios from 'axios';
import { API_BASE_LINK } from '../API_BASE_PATH';
import { Alert, LinearProgress, Snackbar } from '@mui/material';
import { Error, FlightLand, FlightTakeoff, Warning } from '@material-ui/icons';
import { format } from 'date-fns';
import { useSelector } from 'react-redux';


export default function FloatingFlightDetails(props) {
    const [open, setOpen] = React.useState(false);
    const pageParams = useParams();
    const [IsFullLoading, setIsFullLoading] = useState(false);
    const navigate = useNavigate();
    const [ParamData, setParamData] = useState({});
    const [MobileView, setMobileView] = useState(false);

    const [IsAllow, setIsAllow] = useState(false);

    useEffect(() => {
        setParamData(JSON.parse(window.atob(pageParams.id)))
    }, [props]);

    const goToBookPage = () => {
        setIsFullLoading(true);
        let userData = JSON.parse(sessionStorage.getItem('LoginUserDetails'));
        let request_id = sessionStorage.getItem('search_request_id')
        let payload_for_create_booking_single_flight = {
            "flight_index": props.selected_flight_details?.one_way?.ResultIndex,
            "session_id": request_id
            // "trace_id": props.selected_flight_details?.trace_id,
            // "flight_index": props.selected_flight_details?.one_way?.ResultIndex,
            // "is_ilcc": props.selected_flight_details?.one_way?.IsLCC,
            // "from": props.selected_flight_details?.one_way?.Segments[0]?.[0]?.Origin?.Airport?.AirportCode,
            // "to": props.selected_flight_details?.one_way?.Segments[0]?.[props.selected_flight_details?.one_way?.Segments[0].length - 1]?.Destination?.Airport?.AirportCode,
            // "journey_date": moment.utc(props.selected_flight_details?.one_way?.Segments[0]?.[0]?.Destination?.ArrTime).format("YYYY-MM-DD"),
        }
        let payload_for_create_booking_round_trip = {
            "flight_index": props.selected_flight_details?.one_way?.ResultIndex,
            "return_flight_index": props.selected_flight_details?.round_trip?.ResultIndex,
            "session_id": request_id
            // "trace_id": props.selected_flight_details?.trace_id,
            // "flight_index": props.selected_flight_details?.one_way?.ResultIndex,
            // "is_ilcc": props.selected_flight_details?.one_way?.IsLCC,
            // "return_flight_index": props.selected_flight_details?.round_trip?.ResultIndex,
            // "is_olcc": props.selected_flight_details?.round_trip?.IsLCC,
            // "from": props.selected_flight_details?.one_way?.Segments[0]?.[0]?.Origin?.Airport?.AirportCode,
            // "to": props.selected_flight_details?.round_trip?.Segments[0]?.[0]?.Origin?.Airport?.AirportCode,
            // "journey_date": moment.utc(props.selected_flight_details?.one_way?.Segments[0]?.[0]?.Destination?.ArrTime).format("YYYY-MM-DD"),
            // "return_date": moment.utc(props.selected_flight_details?.round_trip?.Segments[0]?.[0]?.Destination?.ArrTime).format("YYYY-MM-DD"),
        }
        let payload_for_create_booking = {};
        if (props.selected_flight_details.round_trip === undefined) {
            payload_for_create_booking = payload_for_create_booking_single_flight;
        } else {
            payload_for_create_booking = payload_for_create_booking_round_trip;
        }
        // payload_for_create_booking.request_id = sessionStorage.getItem('search_request_id');
        if (sessionStorage.getItem('islogin') === 'true') {
            axios.post(`${API_BASE_LINK}flight/createBooking`, payload_for_create_booking, {
                headers: {
                    'Authorization': userData?.session?.session_token,
                    'Content-Type': 'application/json'
                }
            }).then(function (response) {
                if (response?.status === 200) {
                    // sessionStorage.setItem('booking_id', response?.data?.booking?.id);
                    let currentTime = new Date();
                    let sendParam = {
                        b: response?.data?.booking?.id,
                        a: ParamData?.AdultCount,
                        c: ParamData?.ChildCount,
                        i: ParamData?.InfantCount,
                        dt: currentTime
                    }
                    console.log('new Date()', new Date())
                    let getString = window.btoa(JSON.stringify(sendParam));
                    // console.log(getString)
                    // console.log(ParamData?.AdultCount)
                    navigate("/booking/" + getString);
                    console.log(`${API_BASE_LINK}/flight/createBooking`, response);
                }
            })
                .catch(function (error) {
                    console.log(error);
                    setIsFullLoading(false);
                    alert('--', error.code);
                    console.log(payload_for_create_booking)
                });
        } else {
            // alert("Without login we can't proceed. Loging please");
            setOpen(true);
            setIsFullLoading(false);
            // navigate('/login')
        }



    }

    useEffect(() => {
        if (Math.round((new Date(props.selected_flight_details?.round_trip?.Segments[0]?.[0]?.Origin?.DepTime) - new Date(props.selected_flight_details?.one_way?.Segments[0]?.[props.selected_flight_details?.one_way?.Segments[0]?.length - 1]?.Destination?.ArrTime)) / (1000 * 60 * 60)) >= 2) {
            setIsAllow(true)
        } else {
            setIsAllow(false)
        }
        return () => {
            setIsAllow(false)
        };
    }, [props]);
    return (
        <div className='flight_holder_wrap'>
            <Snackbar
                open={open}
                autoHideDuration={600}
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'center',
                }}
                style={{ zIndex: 99999999 }}
            // onClose={handleClose}
            // message="Note archived"

            >
                <div className='bg-dark p-3 rounded-1 shadow-lg text-center'>
                    Without a login, we can't proceed. Logging please!
                    <Link to={'/login'} className='btn border-1 rounded btn-outline-warning ms-3'><b>Login</b></Link>
                </div>
            </Snackbar>
            {IsFullLoading &&
                <div className="fullLoader d-flex align-items-center justify-content-center">
                    <div className='text-center col-md-3'>
                        <p>Please wait...</p>
                        <LinearProgress color="secondary" />
                    </div>
                </div>
            }
            <div className="container">
                <div className="row">
                    <div className="col-12">
                        {props.selected_flight_details.round_trip !== undefined && IsAllow === false &&
                            <div className="HigiLightError">
                                <p className='  text-warning py-3 pb-4 px-3 m-0  '>
                                    <marquee   className="d-block pb-2">
                                        <Error /> This return flight is not allowed to be booked due to this flight not being after 2 hours of arrival. Please choose another return flight; that should be after 2 hours of arrival.
                                    </marquee>
                                </p>
                            </div>
                        }
                    </div>
                    <div className="col-md-12">
                        <div className="flight_holder_inner_wrap">
                            <div className="row align-items-center" >
                                <div className="col">
                                    <div className="row">
                                        <div className="col-md-auto">
                                            <div className="box_d">

                                                <div className="d-flex align-items-center">
                                                    <figure className={`d-inline-flex ${props.selected_flight_details?.one_way?.Segments[0]?.[0]?.Airline?.AirlineName}`}>
                                                        <img src={`/img/AirlineLogo/${props.selected_flight_details?.one_way?.Segments[0]?.[0]?.Airline?.AirlineName}.png`} alt={props.selected_flight_details?.one_way?.Segments[0]?.Airline?.AirlineName} />
                                                    </figure>
                                                    <div className="ps-4">
                                                        <p className='mb-2'><FlightTakeoff /> Departure ・ <i>{props.selected_flight_details?.one_way?.Segments[0]?.[0]?.Airline?.AirlineName}</i></p>
                                                        <h5 className='mb-md-0 text-nowrap  '>{moment.utc(props.selected_flight_details?.one_way?.Segments[0]?.[0]?.Origin?.DepTime).format('DD MMM, HH:mm')} → {moment.utc(props.selected_flight_details?.one_way?.Segments[0]?.[props.selected_flight_details?.one_way?.Segments[0]?.length - 1]?.Destination?.ArrTime).format('DD MMM, HH:mm')}
                                                        </h5>
                                                        <h4 className='mb-md-0  pt-2 text-nowrap'>₹ {Math.round(props.selected_flight_details?.one_way?.Fare?.PublishedFare).toLocaleString()}</h4>


                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-auto border-end me-4 "></div>

                                       {props?.trip_type === 2 && props?.selected_flight_details?.round_trip === undefined && <div className="col-md text-center align-self-center"><Warning /> Choose return flight.</div>} 

                                        {props?.selected_flight_details?.round_trip !== undefined &&
                                            <div className="col-md-auto">
                                                <div className="box_d">

                                                    <div className="d-flex align-items-center">

                                                        <figure className={`d-inline-flex ${props.selected_flight_details?.round_trip?.Segments[0]?.[0]?.Airline?.AirlineName}`}>
                                                            <img src={`/img/AirlineLogo/${props.selected_flight_details?.round_trip?.Segments[0]?.[0]?.Airline?.AirlineName}.png`} alt={props.selected_flight_details?.round_trip?.Segments[0]?.Airline?.AirlineName} />
                                                        </figure>
                                                        <div className="ps-4">
                                                            <p className='mb-2'><FlightLand /> Return ・ <i>{props.selected_flight_details?.round_trip?.Segments[0]?.[0]?.Airline?.AirlineName}</i></p>
                                                            <h5 className='mb-md-0 text-nowrap  '>{moment.utc(props.selected_flight_details?.round_trip?.Segments[0]?.[0]?.Origin?.DepTime).format('DD MMM, HH:mm')} → {moment.utc(props.selected_flight_details?.round_trip?.Segments[0]?.[props.selected_flight_details?.round_trip?.Segments[0]?.length - 1]?.Destination?.ArrTime).format('DD MMM, HH:mm')}</h5>

                                                            <h4 className='mb-md-0  pt-2 text-nowrap'>₹ {Math.round(props.selected_flight_details?.round_trip?.Fare?.PublishedFare).toLocaleString()}</h4>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        }
                                    </div>
                                </div>
                                <div className="col-md-auto pe-4">
                                    <div className="d-flex align-items-center justify-content-between d-md-block">
                                        <div className="l1">
                                            <p className='mb-0'>Total price:</p>
                                            {props.selected_flight_details.round_trip === undefined ?
                                                <h4>₹ {Math.round(props.selected_flight_details?.one_way?.Fare?.PublishedFare).toLocaleString()}</h4>
                                                :
                                                <h4>₹ {Math.round(props.selected_flight_details?.one_way?.Fare?.PublishedFare + props.selected_flight_details?.round_trip?.Fare?.PublishedFare).toLocaleString()}</h4>
                                            }
                                        </div>
                                        <div className="l2">
                                            {props.selected_flight_details.round_trip !== undefined && IsAllow &&
                                                <button className='btn cust_btn_one' onClick={() => goToBookPage()}>proceed</button>
                                            }
                                            {props?.trip_type === 1 &&
                                                <button className='btn cust_btn_one' onClick={() => goToBookPage()}>proceed</button>
                                            }
                                            {/* {props?.trip_type} */}
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
