import React, { useEffect, useState } from 'react';
import { NavLink, Link, useNavigate } from 'react-router-dom';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { Close, PersonPin, PowerSettingsNew, Sort } from '@material-ui/icons';
import { AirplaneTicket, ManageAccounts, Password } from '@mui/icons-material';
const Header = () => {
    const [MobileMenuToggol, setMobileMenuToggol] = useState(0);

    const [UseName, setUseName] = useState(null);
    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);


    useEffect(() => {
        let userData = JSON.parse(sessionStorage.getItem('LoginUserDetails'));
        // console.log(userData.user.first_name);
        if (sessionStorage.getItem('islogin') === 'true') {
            setUseName(userData?.user?.first_name);
        }
    }, []);
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    const logOut = () => {
        sessionStorage.removeItem('LoginUserDetails');
        sessionStorage.removeItem('islogin');
        // window.location = '/';
        window.location.reload();
    }
    return (
        <header className='main-header'>

            <div className="container">
                <div className="row align-items-center">
                    <div className="col-auto me-auto logo">
                        <NavLink to='/'><img src="/img/logo.svg" alt="" /></NavLink>
                    </div>


                    <div className={`col-auto mobile-menu-wrap ${MobileMenuToggol === 1 && 'active'}`}>
                        <div className="monMenuBAckdrop d-md-none" onClick={() => setMobileMenuToggol(0)}></div>
                        <div className="mob-menu">
                            <div className="text d-flex d-md-none p-3 w-100 justify-content-between d-flex align-items-center">
                                <b>
                                    MENU
                                </b>

                                <button className='btn text-white' onClick={() => setMobileMenuToggol(0)}>
                                    <Close />
                                </button>
                            </div>
                            <hr className='mt-0 d-md-none' />
                            <nav>
                                <ul className="list-unstyled d-md-flex align-items-center m-0" onClick={() => setMobileMenuToggol(0)}>
                                    <li><NavLink to='/'  >Flight</NavLink></li>
                                    <li><NavLink to='/news'>news</NavLink></li>
                                    <li><NavLink to='/about'>about us</NavLink></li>
                                    <li><NavLink to='/contact'>contact us</NavLink></li>
                                </ul>
                            </nav>
                        </div>
                        <div className="d-md-none">
                            <button className="btn text-white " onClick={() => setMobileMenuToggol(1)}>
                                <Sort />
                            </button>
                        </div>
                    </div>
                    <div className="col-auto">
                        {UseName === null ?
                           <>

                            <Link to={'/login'} className="cust_btn_one">Login</Link>
                            </>
                            :
                            <>  <span id="basic-button"
                                aria-controls={open ? 'basic-menu' : undefined}
                                aria-haspopup="true"
                                aria-expanded={open ? 'true' : undefined}
                                onClick={handleClick}
                                className="cp"><PersonPin />  Hi, {UseName}</span>

                                <Menu
                                    id="basic-menu"
                                    anchorEl={anchorEl}
                                    open={open}
                                    onClose={handleClose}
                                    MenuListProps={{
                                        'aria-labelledby': 'basic-button',
                                    }}
                                    className='clickMenu'
                                >
                                    {/* <MenuItem onClick={handleClose}>Profile</MenuItem> */}
                                    <MenuItem onClick={handleClose}><Link to={`/my-account/profile`}><ManageAccounts /> My account</Link></MenuItem>
                                    <MenuItem onClick={handleClose}><Link to={`/my-account/tickets`}><AirplaneTicket /> My Tickets</Link></MenuItem>
                                    <MenuItem onClick={handleClose}><Link to={`/my-account/change-password`}><Password /> Change Password</Link></MenuItem>
                                    <MenuItem onClick={(e) => { handleClose(); logOut() }}><b className='text-danger'><PowerSettingsNew /> Logout</b></MenuItem>
                                </Menu>
                            </>
                        }

                    </div>
                    {/* <div className="col-auto">
                        <div className="mode-day-night">
                            <LightMode />
                            <DarkMode />
                        </div>
                    </div> */}
                </div>
            </div>

            <CheckInterNet />
        </header>
    );
}

export default Header;


const CheckInterNet = () => {
    const navigator = useNavigate();
    const [isOnline, setIsOnline] = useState(window.navigator.onLine);

    useEffect(() => {
        const handleOnlineStatus = () => {
            setIsOnline(window.navigator.onLine);
            if (window.navigator.onLine === false) {
                navigator('/not-internet')
            } else {
                navigator(-1)
            }
        };

        window.addEventListener('online', handleOnlineStatus);
        window.addEventListener('offline', handleOnlineStatus);

        return () => {
            window.removeEventListener('online', handleOnlineStatus);
            window.removeEventListener('offline', handleOnlineStatus);
        };
    }, []);


}