
import { AccountCircleRounded, PowerSettingsNew, VerifiedUser } from '@material-ui/icons';
import { AirplaneTicket, Edit, Password, PowerRounded } from '@mui/icons-material';
import axios from 'axios';
import React, { useEffect, useState } from 'react'
import { Link, useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { API_BASE_LINK } from '../API_BASE_PATH';
import { format, compareAsc, subDays } from 'date-fns';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import { FormControl, MenuItem, Select, Snackbar, TextField } from '@mui/material';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import STATE from '../DATA_SET/IndianStates.json';
import MuiAlert from '@mui/material/Alert';
import ChangePassword from '../Slices/ChangePassword';
import MyTicket from '../Slices/MyTicket';

const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});
const darkTheme = createTheme({
    palette: {
        mode: 'dark',
    },
});
export default function MyAccountPage() {
    const PageParamiter = useParams();
    const navigate = useNavigate();
    const [FilterMenu, setFilterMenu] = useState(0);
    const [UserInfo, setUserInfo] = useState(null);
    const [PageCategory, setPageCategory] = useState('');
    const [ErrorHandel, setErrorHandel] = useState(null);
    const [TITLE, setTITLE] = React.useState(0);
    const [TITLE_TEXT, setTITLE_TEXT] = React.useState('Mr');
    const [GENDER, setGENDER] = React.useState(1);
    const [GENDER_TEXT, setGENDER_Text] = React.useState('M');
    const [SET_STATE, setSET_STATE] = React.useState(0);
    const [PASSANGER_DETAILS, setPASSANGER_DETAILS] = React.useState({

        "first_name": "",
        "last_name": "",
        "prefix": TITLE_TEXT,
        "gender": GENDER_TEXT,
        "date_of_birth": null,
        "mobile_number": "",
        "email_id": "",
        "password": "",
        "c_password": "",
        'defence_id_number': '',
    });

    const [ADDRESS_DETAILS, setADDRESS_DETAILS] = React.useState({
        "full_address": "",
        "city": "",
        "state": '',
        "country": "India",
        "pincode": ""
    });


    const StateHandleChange = (event) => {
        setSET_STATE(event.target.value);
        setADDRESS_DETAILS({ ...ADDRESS_DETAILS, state: STATE[event.target.value].name })
    };


    useEffect(() => {
        console.log(PageParamiter.id);
        if (PageParamiter.id === 'change-password') {
            setPageCategory('change-password')
        } else if (PageParamiter.id === 'tickets') {
            setPageCategory('tickets')

        } else if (PageParamiter.id === 'profile') {
            setPageCategory('profile')
        }

    }, [PageParamiter]);


    useEffect(() => {
        setPageCategory(PageParamiter.id);
        if (sessionStorage.getItem("islogin") !== 'true') {
            navigate('/')
        } else {
            let userData = JSON.parse(sessionStorage.getItem('LoginUserDetails'));
            axios.get(`${API_BASE_LINK}users/profile`, {
                headers: {
                    'Authorization': userData?.session?.session_token
                }
            }).then(function (response) {
                console.log(response);
                if (response?.status === 200) {
                    setUserInfo(response?.data?.response);
                    setTITLE(response?.data?.response?.prefix === 'Mr' ? 0 : response?.data?.response?.prefix === 'Master' ? 1 : response?.data?.response?.prefix === 'Mrs' ? 2 : 3)
                    setGENDER(response?.data?.response?.gender === 'F' ? 2 : 1);
                    setPASSANGER_DETAILS({
                        "first_name": response?.data?.response?.first_name,
                        "last_name": response?.data?.response?.last_name,
                        "prefix": response?.data?.response?.prefix,
                        "gender": response?.data?.response?.gender,
                        "date_of_birth": response?.data?.response?.date_of_birth,
                        "mobile_number": response?.data?.response?.mobile_number,
                        "email_id": response?.data?.response?.email_id,
                        'defence_id_number': response?.data?.response?.defence_id_number,
                    });

                    setADDRESS_DETAILS(
                        {
                            "full_address": response?.data?.response?.Address?.full_address,
                            "city": response?.data?.response?.Address?.city,
                            // "state": response?.data?.response?.Address?.state,
                            // "state": response?.data?.response?.Address?.state,
                            "country": response?.data?.response?.Address?.country || 'India',
                            "pincode": response?.data?.response?.Address?.pincode,
                        }
                    )
                    setSET_STATE(STATE.findIndex(obj => obj.name === response?.data?.response?.Address?.state))

 
                     


                } else {
                    alert('Network Error')
                }

            }).catch(function (error) {
                console.log(error);
            });
        }





    }, []);

    const TitleHandleChange = (event) => {
        setTITLE(event.target.value);
        switch (event.target.value) {
            case 0:
                setPASSANGER_DETAILS({ ...PASSANGER_DETAILS, prefix: 'Mr.' });
                setGENDER(1);
                break;
            case 1:
                setPASSANGER_DETAILS({ ...PASSANGER_DETAILS, prefix: 'Master' });
                setGENDER(1);
                break;
            case 2:
                setPASSANGER_DETAILS({ ...PASSANGER_DETAILS, prefix: 'Mrs.' });
                setGENDER(2);
                break;
            case 3:
                setPASSANGER_DETAILS({ ...PASSANGER_DETAILS, prefix: 'Miss' });
                setGENDER(2);
                break;
            default:
        }
    };

    const GenderHandleChange = (event) => {
        setGENDER(event.target.value);
        switch (event.target.value) {
            case 1:
                setPASSANGER_DETAILS({ ...PASSANGER_DETAILS, gender: 'M' });
                setTITLE(0)
                break;
            case 2:
                setPASSANGER_DETAILS({ ...PASSANGER_DETAILS, gender: 'F' });
                setTITLE(2)
                break;

            default:
        }
    };

    const profile_update_api_call = (data) => {
        let userData = JSON.parse(sessionStorage.getItem('LoginUserDetails'));
        axios.put(`${API_BASE_LINK}users/profile`, data, {
            headers: {
                'Authorization': userData?.session?.session_token
            }
        })
            .then((response) => {
                console.log(response);
                
                handleClick();
            })
            .catch((error) => {
                console.log(error);
                if(error?.response?.status === 400){
                    alert(JSON.stringify(error?.response?.data))
                }
            });

    }

    const FinalLoginPayload = () => {

        let ProfileUpdatePayload = {
            "first_name": PASSANGER_DETAILS.first_name,
            "last_name": PASSANGER_DETAILS.last_name,
            "prefix": PASSANGER_DETAILS.prefix,
            "gender": PASSANGER_DETAILS.gender,
            "date_of_birth": PASSANGER_DETAILS?.date_of_birth?.["$d"]
                ? format(PASSANGER_DETAILS.date_of_birth["$d"], 'MM-dd-yyyy')
                : null,
            "defence_id_number": PASSANGER_DETAILS.defence_id_number,
            "address": {
                "full_address": ADDRESS_DETAILS.full_address,
                "city": ADDRESS_DETAILS.city,
                "state": ADDRESS_DETAILS.state,
                "pincode": ADDRESS_DETAILS.pincode
            }
        }

        profile_update_api_call(ProfileUpdatePayload);




    }

    const [open, setOpen] = React.useState(false);

    const handleClick = () => {
        setOpen(true);
    };

    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }

        setOpen(false);
    };

    const logOut = () => {
        sessionStorage.removeItem('LoginUserDetails');
        sessionStorage.removeItem('islogin');
        window.location.reload();
    }
    return (
        <>
            <main className='BOOKING_PAGE_MAIN_WRAP'>
                {/* <div className="main_round_one">
                    <img src="/img/round1.svg" alt="" />
                </div>
                <div className="main_round_two small">
                    <img src="/img/round2.svg" alt="" />
                </div> */}


                <div className="common_banner mb-5">
                    <div className="container">
                        <div className="row">
                            <div className="col-auto">
                                <div className="iconBox"><span><img src="/img/ico13.svg" alt="" /></span></div>
                            </div>
                            <div className="col">
                                <h4>My Account</h4>
                                <p className="mb-0">
                                    {UserInfo?.prefix}.  {UserInfo?.first_name} {UserInfo?.last_name}, {UserInfo?.email_id} · {UserInfo?.Address?.city},   {UserInfo?.Address?.country}
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="pt-4"></div>

                <section className='my_account_page'>
                    <div className="container">
                        {/* {JSON.stringify(UserInfo?.Bookings)} */}

                        <div className="LayerOne mb-5">
                            <ul className="list-unstyled d-md-flex justify-content-center ">
                                <li><Link className={`btn ${PageCategory === 'profile' && 'active'}`} to={`/my-account/profile`} onClick={() => setPageCategory('profile')}><AccountCircleRounded /> Account info</Link></li>
                                <li><Link className={`btn ${PageCategory === 'tickets' && 'active'}`} to={`/my-account/tickets`} onClick={() => setPageCategory('tickets')}><AirplaneTicket /> My ticket</Link></li>
                                <li><Link className={`btn ${PageCategory === 'change-password' && 'active'}`} to={`/my-account/change-password`} onClick={() => setPageCategory('change-password')}><Password /> Change Password</Link></li>
                                <li><Link to={`/`} className="btn logOut" onClick={(e) => { logOut() }}><PowerSettingsNew /> <b>Logout</b></Link></li>

                            </ul>
                        </div>


                        {PageCategory === 'profile' &&
                            <div className="Account_info_card">
                                <div className="row">
                                    <div className="col-md-9 mx-auto">
                                        <div className="Account_info_card_inner">
                                            <ThemeProvider theme={darkTheme}>
                                                {/* {ValiDate !== '' && <div class="alert alert-danger" role="alert">
                                            <div>   {ValiDate}</div>
                                        </div>} */}
                                                <div className="row">
                                                    <div className="col-md-12">
                                                        <div className="row">
                                                            <div className="col-md-2 mb-3">
                                                                <FormControl fullWidth>
                                                                    <div className='label' >Title</div>
                                                                    <Select
                                                                        value={TITLE}
                                                                        onChange={(e) => { TitleHandleChange(e); }}
                                                                    >
                                                                        <MenuItem value={0}>Mr.</MenuItem>
                                                                        <MenuItem value={1}>Master</MenuItem>
                                                                        <MenuItem value={2}>Mrs.</MenuItem>
                                                                        <MenuItem value={3}>Miss</MenuItem>
                                                                    </Select>
                                                                </FormControl>
                                                            </div>
                                                            <div className="col-md-5 mb-3">
                                                                <FormControl fullWidth>
                                                                    <div className='label' >First name</div>
                                                                    <TextField variant="outlined" value={PASSANGER_DETAILS.first_name} onChange={(e) => { setPASSANGER_DETAILS({ ...PASSANGER_DETAILS, first_name: e.target.value }) }} />
                                                                </FormControl>

                                                            </div>
                                                            <div className="col-md-5 mb-3">
                                                                <FormControl fullWidth>
                                                                    <div className='label' >Last name</div>
                                                                    <TextField variant="outlined" value={PASSANGER_DETAILS.last_name} onChange={(e) => { setPASSANGER_DETAILS({ ...PASSANGER_DETAILS, last_name: e.target.value }) }} />
                                                                </FormControl>
                                                            </div>
                                                            <div className="col-md-2 mb-3">
                                                                <FormControl fullWidth>
                                                                    <div className='label' >Gender</div>
                                                                    <Select
                                                                        value={GENDER}
                                                                        onChange={(e) => { GenderHandleChange(e); }}
                                                                    >
                                                                        <MenuItem value={1}>Male</MenuItem>
                                                                        <MenuItem value={2}>Female</MenuItem>

                                                                    </Select>
                                                                </FormControl>
                                                            </div>
                                                            <div className="col-md-5 mb-3">
                                                                <FormControl fullWidth>
                                                                    <div className='label' >Date of birth <small>( Min Age 18 year )</small></div>
                                                                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                                                                        <DatePicker
                                                                            value={PASSANGER_DETAILS.date_of_birth}

                                                                            maxDate={subDays(new Date(), ((365 * 18) - 1))}
                                                                            onChange={(newValue) => {
                                                                                setPASSANGER_DETAILS({ ...PASSANGER_DETAILS, date_of_birth: newValue })
                                                                                // setPASSANGER_DETAILS({ ...PASSANGER_DETAILS, date_of_birth: format(newValue["$d"], 'dd-MM-yyyy') })

                                                                            }}
                                                                            renderInput={(params) => <TextField {...params} />}
                                                                            format="MM-dd-yyyy"
                                                                            inputFormat="MM-DD-YYYY"
                                                                        />
                                                                    </LocalizationProvider>
                                                                </FormControl>
                                                            </div>
                                                            <div className="col-md-5 mb-3">
                                                                <FormControl fullWidth>
                                                                    <div className='label' >Phone Number</div>
                                                                    <TextField variant="outlined" aria-readonly disabled value={PASSANGER_DETAILS.mobile_number}
                                                                    // onChange={(e) => { setPASSANGER_DETAILS({ ...PASSANGER_DETAILS, mobile_number: e.target.value }) }}
                                                                    />
                                                                </FormControl>
                                                            </div>
                                                            <div className="col-md-12 mb-3">
                                                                <FormControl fullWidth>
                                                                    <div className='label' >Email Address</div>
                                                                    <TextField variant="outlined" disabled value={PASSANGER_DETAILS.email_id}
                                                                    // onChange={(e) => { setPASSANGER_DETAILS({ ...PASSANGER_DETAILS, email_id: e.target.value }) }}
                                                                    />
                                                                </FormControl>
                                                            </div>
                                                            {/* <div className="col-md-5 mb-3">
                                                                <FormControl fullWidth>
                                                                    <div className='label' >Defense ID Card Number</div>
                                                                    <TextField variant="outlined" value={PASSANGER_DETAILS.defence_id_number} onChange={(e) => { setPASSANGER_DETAILS({ ...PASSANGER_DETAILS, defence_id_number: e.target.value }) }} />
                                                                </FormControl>
                                                            </div> */}








                                                            <div className="col-md-12 mb-3">
                                                                <FormControl fullWidth>
                                                                    <div className='label' >Address Line </div>
                                                                    <TextField variant="outlined" value={ADDRESS_DETAILS.full_address} onChange={(e) => { setADDRESS_DETAILS({ ...ADDRESS_DETAILS, full_address: e.target.value }) }} />
                                                                </FormControl>
                                                            </div>
                                                            <div className="col-md-6 mb-3">
                                                                <FormControl fullWidth>
                                                                    <div className='label' >City</div>
                                                                    <TextField variant="outlined" value={ADDRESS_DETAILS.city} onChange={(e) => { setADDRESS_DETAILS({ ...ADDRESS_DETAILS, city: e.target.value }) }} />
                                                                </FormControl>
                                                            </div>
                                                            <div className="col-md-6 mb-3">
                                                                <FormControl fullWidth>
                                                                    <div className='label' >State</div>
                                                                    <Select
                                                                        value={SET_STATE}
                                                                        onChange={(e) => { StateHandleChange(e) }}
                                                                    >
                                                                        {STATE.map((data, ind) => (
                                                                            <MenuItem value={ind} key={ind}>{data?.name}</MenuItem>
                                                                        ))}

                                                                    </Select>
                                                                </FormControl>
                                                            </div>
                                                            <div className="col-md-6 mb-3">
                                                                <FormControl fullWidth>
                                                                    <div className='label'  >Country</div>
                                                                    <TextField variant="outlined" value={ADDRESS_DETAILS.country} disabled onChange={(e) => { setADDRESS_DETAILS({ ...ADDRESS_DETAILS, country: e.target.value }) }} />
                                                                </FormControl>
                                                            </div>
                                                            <div className="col-md-6 mb-3">
                                                                <FormControl fullWidth>
                                                                    <div className='label' >Pincode/ Zip Code</div>
                                                                    <TextField variant="outlined" value={ADDRESS_DETAILS.pincode} onChange={(e) => { setADDRESS_DETAILS({ ...ADDRESS_DETAILS, pincode: e.target.value }) }} />
                                                                </FormControl>
                                                            </div>

                                                        </div>
                                                    </div>

                                                    <div className="col-12 pt-3">
                                                        <div className="row justify-content-between align-items-center">
                                                            <div className="col-auto">
                                                                <div className="loginBtn">
                                                                    <button className="btn cust_btn_one border-0" onClick={FinalLoginPayload}>
                                                                        <Edit /> &#160; Update account
                                                                    </button>
                                                                </div>


                                                                {/* <button onClick={()=>setIsOpen(true)}> ontest</button> */}
                                                            </div>
                                                            {/* <div className="col-auto">
                                                        Forgot your
                                                        <Link to="/forgot-password"> <u>password ?</u></Link>
                                                    </div> */}
                                                        </div>
                                                        <div className="pt-4"></div>

                                                        {ErrorHandel !== null && <Alert severity="error">{ErrorHandel}</Alert>}
                                                        {/* <div className='alert alert-red'>
                                                    <p className='m-0'>{ErrorHandel}</p>
                                                </div> */}
                                                    </div>

                                                </div>

                                            </ThemeProvider>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        }

                        <div className="row">
                            <div className="col-md-12">



                                {/* <ChangePassword  /> */}
                                {PageCategory === 'tickets' &&
                                    <MyTicket SendTicketData={UserInfo?.Bookings} UseMobileNumber={UserInfo?.mobile_number} />
                                }

                                {PageCategory === 'change-password' &&
                                    <ChangePassword SendPassword={UserInfo?.password} UseMobileNumber={UserInfo?.mobile_number} />
                                }

                            </div>
                        </div>
                    </div>
                </section>
            </main>
            <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
                <Alert onClose={handleClose} severity="success" sx={{ width: '100%' }}>
                    Your account is successfully updated!
                </Alert>
            </Snackbar>
        </>
    )
}
