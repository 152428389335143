import React from 'react';
import DirectorIntro from '../Slices/DirectorIntro';

export default function AboutUs() {
    return (
        <div>
            <section className="aboutUsPAge animate__animated animate__fadeIn ">
                <div className="container py-5">
                    <div className="card">
                        <div className="row align-items-center">
                            <div className="col-md-12 text-center">
                                {/* <img src="/images/navC-c.png" alt="" width='100' /> */}
                                <h1>A Company of <b className=''>Army</b></h1>
                                <h5 className='font-weight-normal  '><i>Classify about our duty, your satisfaction is our moto.</i></h5>

                            </div>
                            <div className="col-md-6 mx-auto my-4"><hr /></div>
                            <div className="col-md-10 text-center mx-auto">
                                <p>
                                    We are a fighting forces of Army with a loving for travel. Having usual the casual to travel all over the state due to our all duty
                                    we now want to give back the same joy to the defence association partying both our loves the Army and Travel. We are planers,
                                    experts, designers and developers a group of other things but most of all, we are Army of one heart.</p>
                                <p>
                                    <b>Navchr</b> Online just looked to ensue as the next growth in our lives. All Defence Persons children we have constantly thought a
                                    solid empathy for the armies. We acutely respect their hard study, devotion &amp; expense. We understood that the thing we
                                    actually needed to be related with a part from the fighting forces and all other defence persons has been travelling. During our
                                    knowledge in and about the Army we have educated that the defence forces expression a countless of themes when it comes to
                                    travel last minute leaves lack of confirm airlines reservation and most very unaffordable air coupons. As a result, to the suction
                                    <b>navchr</b> online is lunched this program. </p>
                                <p>
                                    The Navchr Online we wish to grow the lives of the all fighting forces and other forces staffs and their dependence, and facility
                                    them from the time of their deal to the time after their leaving.
                                    <b>Navchr</b> online we are observing to generate a unified travel knowledge for our all users. <b>Navchr</b> online want to take
                                    proprietorship of your airlines travel plans, keep it private and help you every stage of the mode. We wish to afford the fighting
                                    forces and other persons with a facility devoted only for them, which will support them finished thick and tinny, while moving
                                    each feature of their lives. And that a dream we feel is worthy of anybody life work. At <b>navchr</b> online we start each day with a
                                    single ready focus on providing a complete and cost real Ticketing service to our Defence Society done our gateway. <br /> <br />
                                    <i> <b>Navchr</b> Online is a discounted travel platform for the all defence person, EXAM/ Veterans and their Dependents and all others Person.</i>
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className='mb-5'>
                <DirectorIntro />
            </section>
        </div>
    )
}
