import React, { useState, useEffect } from 'react';

function Timer({ getDate }) {



    const [timeLeft, setTimeLeft] = useState(900); // 900 seconds = 15 minutes
    useEffect(() => {
        
        setTimeLeft(getDate);
    }, [getDate]);
    useEffect(() => {

        // if (getDate > 900) {
        //     setTimeLeft(0);
        // } else {
        //     setTimeLeft(900 - getDate);
        // }



        const countdownTimer = setInterval(() => {
            setTimeLeft(prevTime => prevTime - 1);
        }, 1000);

        // Clear the interval when the countdown reaches zero
        if (timeLeft < 1) {
            clearInterval(countdownTimer);
            console.log('Time over');
            // window.location.reload();
        }

        return () => clearInterval(countdownTimer);
    }, [timeLeft]);

    const minutes = Math.floor(timeLeft / 60);
    const seconds = timeLeft % 60;

    return (
        <>
            {minutes}:{seconds < 10 ? `0${seconds}` : seconds}min
        </>
    );
}

export default Timer;
