import { Flight, FlightTakeoff, Today } from '@material-ui/icons';
import { Airlines, FlightClass, Luggage } from '@mui/icons-material';
import { format } from 'date-fns';
import moment from 'moment'
import React  from 'react'
import { DDMMMYYYYHHMM } from '../Utiils/DateForMate';

export default function FlightSummery(props) {
  
    return (
        <>
            <div className="Layer_Box_One mb-3">
                <div className="nintyDigre">
                    <span>{props?.type}</span>
                </div>
                <div className="box ps-5">
                    <div className="row justify-content-between">
                        <div className="col-md-7">
                            <h4 className='mb-2'>{props?.flightSegments[0]?.origin_city_name}({props?.flightSegments[0]?.origin_airport_code}) → {props?.flightSegments[props?.flightSegments.length - 1]?.destination_city_name}({props?.flightSegments[props?.flightSegments?.length - 1]?.destination_city_code})</h4>
                            <p className="mb-2">
                                {/* 2023-January-29 7:30 → 2023-January-29 9:50 */}

                                <Today /> {DDMMMYYYYHHMM(props?.flightSegments[0]?.dep_time)} &#160; → &#160; {DDMMMYYYYHHMM(props?.flightSegments[props?.flightSegments?.length - 1]?.arr_time)}  
                            </p>

                            <p className='mb-0'>
                                <Flight />
                                {props?.flightSegments.length === 1 && 'Non stop'}
                                {props?.flightSegments.length > 1 && <>
                                    {props?.flightSegments?.map((data, ind) =>
                                        <span key={ind}>
                                            {props?.flightSegments[ind + 1]?.origin_city_name &&
                                                <> {ind + 1} stop  <br />
                                                </>}
                                        </span>
                                    )}
                                </>}
                            </p>

                            {/* {
                                props?.flightSegments?.map((data, ind) => <>
                                    <p>
                                        <FlightTakeoff />  Departure:    {data?.origin_airport_name} {ind}
                                    </p>
                                </>)
                            } */}
                        </div>
                        <div className="col-md-auto">
                            <div className="d-flex flight_d">
                                <figure className={`d-inline-flex m-0 me-2 ${props?.flightSegments[0]?.airline_name}`}>
                                    <img
                                        src={`/img/AirlineLogo/${props?.flightSegments[0]?.airline_name}.png`}
                                        alt={props?.flightSegments[0]?.airline_name}
                                    />
                                </figure>
                                <div className="text ps-3">
                                    <h5 className='mb-1'>{props?.flightSegments[0]?.airline_name} - {props?.flightSegments[0]?.craft}</h5>
                                    <p className='mb-1'><Airlines/> Airline: {props?.flightSegments[0]?.airline_code} </p>
                                    <p><FlightTakeoff /> Flight number: {props?.flightSegments[0]?.flight_number}</p>
                                    {/* <p className="mb-0">Duration: &#160;
                                        {props?.sendData?.Segments[0]?.length > 1 ?
                                            <>{Math.floor((props?.sendData?.Segments[0]?.[0]?.Duration + props?.sendData?.Segments[0]?.[props?.sendData?.Segments[0]?.length - 1]?.Duration) / 60)}h {(props?.sendData?.Segments[0]?.[0]?.Duration + props?.sendData?.Segments[0]?.[props?.sendData?.Segments[0]?.length - 1]?.Duration) % 60}m</>
                                            :
                                            <>{Math.floor(props?.sendData?.Segments[0]?.[0]?.Duration / 60)}h {props?.sendData?.Segments[0]?.[0]?.Duration % 60}m</>
                                        }
                                    </p> */}
                                </div>
                            </div>
                        </div>
                     
                    </div>
                    <hr />
                    <div className="row justify-content-between align-items-center">
                        <div className="col-md-6">
                            <h6 className='mb-4'>Flight details</h6>
                            <div className="date_space_wrap">
                                {props?.flightSegments?.map((data, ind) => (
                                    
                                        <div className="d-flex align-items-center date_space mb-3" key={ind}>
                                            <span>
                                               DATE: {DDMMMYYYYHHMM(props?.flightSegments[ind]?.dep_time)}  <br />
                                               CITY: {ind === 0 ? 'From' : 'Via'} {props?.flightSegments[ind]?.origin_city_name}
                                            </span>
                                            <div className="round"><div className="dot line"></div></div>
                                            <span> 
                                                {props?.flightSegments[ind]?.origin_airport_code} - Terminal {props?.flightSegments[ind]?.origin_terminal} <br /> {props?.flightSegments[ind]?.origin_airport_name}</span>
                                        </div>
                                    
                                ))}
                            </div>
                            {/* <div className="d-flex date_space">
                                <span>{moment.utc(props?.sendData?.Segments[0]?.[props?.sendData?.Segments[0]?.length - 1]?.Destination?.ArrTime).format("MMM DD, YYYY  hh:mm")}</span>
                                <div className="round"><div className="dot"></div></div>
                                <span>{props?.sendData?.Segments[0]?.[props?.sendData?.Segments[0]?.length - 1]?.Destination?.Airport?.AirportCode} - {props?.sendData?.Segments[0]?.[props?.sendData?.Segments[0]?.length - 1]?.Destination?.Airport?.AirportName} </span>
                            </div> */}
                        </div>
                        <div className="col-md-auto">
                            <p><small><b>Other details
                            </b></small></p>
                            <p className='mb-2'>
                                <Luggage />
                                <small>Cabin: {props?.flightSegments[0]?.cabin_baggage}, Bag: {props?.flightSegments[0]?.baggage}</small>
                            </p>
                            <p className='mb-0'>
                                <FlightClass />

                                <small>Cabin Class: &#160;
                                    {props?.flightSegments[0]?.cabin_class === 2 && 'Economy'}
                                    {props?.flightSegments[0]?.cabin_class === 3 && 'Premium Economy'}
                                    {props?.flightSegments[0]?.cabin_class === 4 && 'Business'}
                                    {props?.flightSegments[0]?.cabin_class === 6 && 'First'}
                                </small>
                            </p>

                        </div>
                      
                    </div>
                </div>

            </div>
        </>
    )
}
