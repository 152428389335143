import React, { useState, useEffect } from 'react';
import TextField from '@mui/material/TextField';
import { ThemeProvider, createTheme } from '@mui/material/styles';

const darkTheme = createTheme({
    palette: {
        mode: 'dark',
    },
});
export default function ContactUs() {
    const [MessageSuccess, setMessageSuccess] = useState(false);
    const [ErrorMessage, setErrorMessage] = useState('');
    const [ContactFormData, setContactFormData] = useState({
        full_name: '',
        phone_number: '',
        email_address: '',
        message: ''
    });

    const sendMessage = () => {

        if (ContactFormData.full_name === '') {
            setErrorMessage('Please enter your full name');
        } else if (ContactFormData.phone_number === '') {
            setErrorMessage('Please enter your phone number')
        } else if (ContactFormData.email_address === '') {
            setErrorMessage('Please enter your email address')
        } else if (ContactFormData.message === '') {
            setErrorMessage('Please enter your message')
        } else {
            setErrorMessage('');
            setMessageSuccess(true);
            setContactFormData(
                {
                    full_name: '',
                    phone_number: '',
                    email_address: '',
                    message: ''
                }
            );

            setTimeout(() => {
                setMessageSuccess(false);
            }, 5000)
        }

        // setContactFormData{ }


    }

    useEffect(() => {
        console.log(ContactFormData);
    }, [ContactFormData]);
    return (
        <>
            <section className='contactUsPageWRap py-5 mb-md-4'>
                <div className="container animate__animated animate__fadeIn">
                    <div className="text-center mb-md-5">
                        <h1 className="text-center">Contact us
                        </h1>
                        <p>
                            Your satisfaction is our moto. Queries around your airline reservation? <br />
                            navchr online are available 24x7hr to explain your requests and reply to all users for his questions.
                        </p>
                       
                    </div>
                    <div className="row justify-content-between">
                        <div className="col-md-6">
                            <div className="gridBox">
                                <h3 className='mb-0'>Get In touch</h3>
                                <hr />
                                <div className="block mb-3 mb-md-4">
                                    <h5>Phone:</h5>
                                    <p className='d-flex align-items-center flex-wrap'>
                                        <span className="material-icons align-center mr-2">
                                            call
                                        </span> <a href="tel:+919336111631"> +91 9336 111631 </a> &#160; (09:00am - 05:00pm)</p>
                                </div>
                                <div className="block mb-3 mb-md-4">
                                    <h5>Email:</h5>
                                    <p className='d-flex align-items-center flex-wrap'>
                                        <span className="material-icons align-center mr-2">
                                            mail
                                        </span> &#160; <a href="mailto:support@navchr.com">support@navchr.com</a>
                                    </p>
                                </div>
                                <div className="block mb-3 mb-md-4">
                                    <h5>Office address:</h5>
                                    <p className='d-flex  flex-wrap'>
                                        <span className="material-icons align-center mr-2">
                                            location_on
                                        </span>
                                        Navchr Online <br />
                                        Plot No - 10, Baniya ka Bagh <br />
                                        Jaipur Bye Paas, Alwar <br />
                                        Rajasthan-301001
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-5 pt-5 pt-md-0">
                            <div className="gridBox">


                                <h3 className='mb-4'>Let's send a message</h3>
                                <hr />
                                <ThemeProvider theme={darkTheme}>
                                    <div className="contForm">
                                        <div className="row">
                                            <div className="col-md-12 mb-3">
                                                <div className="form-group">
                                                    <TextField
                                                        className='w-100'
                                                        label="Full name *"
                                                        variant="standard"
                                                        value={ContactFormData.full_name}
                                                        onChange={(e) => (setContactFormData({ ...ContactFormData, full_name: e.target.value }), setErrorMessage(''))}
                                                    />
                                                </div>
                                            </div>
                                            <div className="col-md-12 mb-3">
                                                <div className="form-group">
                                                    <TextField
                                                        className='w-100'
                                                        label="Phone number *"
                                                        variant="standard"
                                                        value={ContactFormData.phone_number}
                                                        onChange={(e) => (setContactFormData({ ...ContactFormData, phone_number: e.target.value }), setErrorMessage(''))}
                                                    />
                                                </div>
                                            </div>
                                            <div className="col-md-12 mb-3">
                                                <div className="form-group">
                                                    <TextField
                                                        className='w-100'
                                                        label="Email address *"
                                                        variant="standard"
                                                        value={ContactFormData.email_address}
                                                        onChange={(e) => (setContactFormData({ ...ContactFormData, email_address: e.target.value }), setErrorMessage(''))}
                                                    />
                                                </div>
                                            </div>
                                            <div className="col-md-12 mb-3">
                                                <div className="form-group">
                                                    <TextField
                                                        className='w-100'
                                                        label="Message *"
                                                        variant="standard"
                                                        multiline
                                                        rows={4}
                                                        value={ContactFormData.message}
                                                        onChange={(e) => (setContactFormData({ ...ContactFormData, message: e.target.value }), setErrorMessage(''))}
                                                    />
                                                </div>
                                            </div>
                                            <div className="col-md-12">


                                                <div className="form-group">
                                                    <button
                                                        onClick={sendMessage}
                                                        className='   btn cust_btn_one' >
                                                        <span className="material-icons mr-3">
                                                            send
                                                        </span>
                                                        Send</button>
                                                </div>
                                            </div>
                                            {ErrorMessage !== '' && <p>
                                                <span className="material-icons align-middle mr-2">
                                                    warning
                                                </span>   {ErrorMessage} </p>
                                            }
                                            {MessageSuccess && <p>Thank you ! Message was sent successfully  </p>
                                            }
                                        </div>
                                    </div>
                                </ThemeProvider>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}
