import { getOptionsFromChildren } from '@mui/base';
import React, { useEffect, useState } from 'react';
import { json, useNavigate, useParams } from 'react-router-dom';
import AddressForm from '../Slices/AddressForm';
import BookingForm from '../Slices/BookingForm';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import { FormControl, FormControlLabel, Radio, RadioGroup } from '@mui/material';
import { Check, Luggage } from '@mui/icons-material';
import Checkbox from '@mui/material/Checkbox';
import axios from 'axios';
import { differenceInYears, format, formatDistanceStrict, parseISO } from 'date-fns';
import { API_BASE_LINK } from '../API_BASE_PATH';
import { useLocation } from 'react-router-dom';
import { ArrowRightAlt, Language, Schedule, TimeToLeave } from '@material-ui/icons';
const darkTheme = createTheme({
    palette: {
        mode: 'dark',
    },
});
const CartPage = () => {
    const location = useLocation();
    const navigate = useNavigate();
    const pageParams = useParams();
    const currentPath = window.location.origin;
    const [ORDER_ATTR, setORDER_ATTR] = useState({
        order_id: '',
        amount: '',
        return_url: ''
    });

    const [FlightDetails, setFlightDetails] = useState(null);

    // const [FlightDetails, setFlightDetails] = useState(null);

    useEffect(() => {
        //  axios.post(`${API_BASE_LINK}flight/ticket`, { booking_id: sessionStorage.getItem('booking_id') })
        //     .then(function (response) {
        //         // console.log(response.data)
        //         if (response.status === 200) {
        //             console.log(response.data);
        //             setFlightDetails(response.data)
        //          }
        //     }).catch(function (error) {
        //         console.log(error);
        //     });


        axios.get(`${API_BASE_LINK}flight/details/${pageParams.id}`)
            .then(function (response) {
                console.log(response?.data);
                setFlightDetails(response?.data);

                if(response?.data?.is_expired === true){
                    // navigate('/')
                    alert('Session is expired. Please search again.');
                    navigate('/')
                }
            }).catch(function (error) {
                console.log(error);
            });
    }, []);

    useEffect(() => {
        setORDER_ATTR({
            order_id: format(new Date(), 'hhmmssMMddyyyy') + pageParams.id,
            amount: ((FlightDetails?.Fares?.reduce((data, ind) => (data + ind.published_fare), 0) + 128) * 100),
            return_url: currentPath + '/booking-successfull/19'
        });
    }, [FlightDetails]);

    useEffect(() => {
        if (sessionStorage.getItem("islogin") !== 'true') {
            navigate('/');

            // history.goBack();
        }
    }, []);
    return (
        <main className='BOOKING_PAGE_MAIN_WRAP'>
            {/* <div className="main_round_one">
                <img src="/img/round1.svg" alt="" />
            </div>
            <div className="main_round_two small">
                <img src="/img/round2.svg" alt="" />
            </div> */}
            {/* <div className="main_round_three">
                <img src="/img/round3.svg" alt="" />
            </div>
            <div className="main_round_four">
                <img src="/img/round4.svg" alt="" />
            </div> */}

            <div className="common_banner mb-5">
                <div className="container">
                    <div className="row">
                        <div className="col-auto">
                            <div className="iconBox"><span><img src="/img/ico1.svg" alt="" /></span></div>
                        </div>
                        <div className="col">
                            <h4>Booking Summary</h4>
                            <p className="mb-0">
                                Still unsure? Drop your requirements and we will let you know if the price changes.
                            </p>
                        </div>
                    </div>
                </div>
            </div>
            <div className="pt-4"></div>
            {FlightDetails === null ?
                <p className='text-center'>Loading ...</p>
                :
                <>
                    <section className="cartPageMainWrap">
                        <div className="container">
                            <div className="row">
                                <div className="col-md-8">
                                    <div className='cart_layer'>
                                        <div className="cart_layer_inner">
                                            {FlightDetails?.flight_index !== null &&
                                                <div className='flight-segment'>
                                                    <div className="alart">
                                                        <h4> {FlightDetails?.from} <ArrowRightAlt />  {FlightDetails?.to}</h4>

                                                        <p>
                                                            {FlightDetails?.booking_type === 2 && 'Economy'}
                                                            {FlightDetails?.booking_type === 3 && 'Premium Economy'}
                                                            {FlightDetails?.booking_type === 4 && 'Business'}
                                                            {FlightDetails?.booking_type === 6 && 'First'}

                                                            <span className="dot"></span>
                                                            {/* {FlightDetails?.Segments?.length > 1 ? <>{FlightDetails?.Segments?.length - 1} stop</> : 'Non stop'} */}


                                                            {FlightDetails?.Segments?.filter((data) => { return data?.result_index === FlightDetails?.flight_index })?.length > 1 ?
                                                                <>{FlightDetails?.Segments?.filter((data) => { return data?.result_index === FlightDetails?.flight_index })?.length - 1} stop</> : 'Non stop'}

                                                            {/* <span className="dot"></span>

                                                            100h 30m */}

                                                            <span className="dot"></span>
                                                            {format(parseISO(FlightDetails.journey_date), 'E, dd MMM yyyy')}
                                                        </p>
                                                    </div>
                                                    {FlightDetails?.Segments?.map((data, ind) =>

                                                        <>
                                                            {FlightDetails?.flight_index === data?.result_index &&
                                                                <div key={ind + 'O'}>
                                                                    <div className="row flight_card_new align-items-center"  >
                                                                        <div className="col-md-12">
                                                                            <div className="d-flex align-items-center flight">
                                                                                <figure className={`d-inline-flex m-0 me-2 ${data?.airline_name}`}>
                                                                                    <img
                                                                                        src={`/img/AirlineLogo/${data?.airline_name}.png`}
                                                                                        alt={data?.airline_name}
                                                                                    />
                                                                                </figure>
                                                                                <span>{data.airline_name} {data.airline_code} {data.craft} </span>
                                                                            </div>
                                                                            <hr />
                                                                        </div>
                                                                        <div className="col-auto">
                                                                            <div className="tire1 mb-2">
                                                                                <p>{data.origin_city_name} </p>
                                                                                <h5 className='m-0'>{data.origin_city_code} <b>{format(parseISO(data.dep_time), 'hh:mm a')}</b></h5>
                                                                                <p>  {format(parseISO(data.dep_time), 'E, dd MMM')}  </p>
                                                                                <p>{data.origin_airport_name} </p>
                                                                                <p>Terminal {data.origin_terminal ? data.origin_terminal : '-'} </p>
                                                                            </div>
                                                                        </div>
                                                                        <div className="col-auto mx-auto text-center">
                                                                            <Schedule /> <br /> {Math.floor(data.duration / 60)}h {data.duration % 60}m

                                                                        </div>
                                                                        <div className="col-auto">
                                                                            <div className="tire1 mb-2">
                                                                                <p>{data.destination_city_name} </p>
                                                                                <h5 className='m-0'>{data.destination_city_code} <b>{format(parseISO(data.arr_time), 'hh:mm a')}</b></h5>
                                                                                <p>  {format(parseISO(data.arr_time), 'E, dd MMM')}  </p>
                                                                                <p>{data.destination_airport_name} </p>
                                                                                <p>Terminal {data.destination_terminal ? data.destination_terminal : '-'} </p>
                                                                            </div>
                                                                        </div>

                                                                        <div className="col-12 bag">
                                                                            <p><Luggage /> Cabin: {data.cabin_baggage}  <span className='dot'></span> Check-in: {data.baggage}  </p>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            }
                                                        </>


                                                    )}
                                                </div>
                                            }

                                            {FlightDetails?.return_flight_index !== null &&

                                                <div className='flight-segment'>
                                                    <div className="alart">
                                                       <h4> {FlightDetails?.to} <ArrowRightAlt />  {FlightDetails?.from}</h4>

                                                        <p>
                                                            {FlightDetails?.booking_type === 2 && 'Economy'}
                                                            {FlightDetails?.booking_type === 3 && 'Premium Economy'}
                                                            {FlightDetails?.booking_type === 4 && 'Business'}
                                                            {FlightDetails?.booking_type === 6 && 'First'}

                                                            <span className="dot"></span>
                                                            {FlightDetails?.Segments?.filter((data) => { return data?.result_index === FlightDetails?.return_flight_index })?.length > 1 ?
                                                                <>{FlightDetails?.Segments?.filter((data) => { return data?.result_index === FlightDetails?.return_flight_index })?.length - 1} stop</> : 'Non stop'}
                                                            {/* <span className="dot"></span>

                                                            100h 30m */}

                                                            <span className="dot"></span>
                                                            {format(parseISO(FlightDetails.journey_date), 'E, dd MMM yyyy')}
                                                        </p>
                                                    </div>
                                                    {FlightDetails?.Segments?.map((data, ind) =>

                                                        <>
                                                            {FlightDetails?.return_flight_index === data?.result_index &&
                                                                <div key={ind + 'O'}>
                                                                    <div className="row flight_card_new align-items-center"  >
                                                                        <div className="col-md-12">
                                                                            <div className="d-flex align-items-center flight">
                                                                                <figure className={`d-inline-flex m-0 me-2 ${data?.airline_name}`}>
                                                                                    <img
                                                                                        src={`/img/AirlineLogo/${data?.airline_name}.png`}
                                                                                        alt={data?.airline_name}
                                                                                    />
                                                                                </figure>
                                                                                <span>{data.airline_name} {data.airline_code} {data.craft} </span>
                                                                            </div>
                                                                            <hr />
                                                                        </div>
                                                                        <div className="col-auto">
                                                                            <div className="tire1 mb-2">
                                                                                <p>{data.origin_city_name} </p>
                                                                                <h5 className='m-0'>{data.origin_city_code} <b>{format(parseISO(data.dep_time), 'hh:mm a')}</b></h5>
                                                                                <p>  {format(parseISO(data.dep_time), 'E, dd MMM')}  </p>
                                                                                <p>{data.origin_airport_name} </p>
                                                                                <p>Terminal {data.origin_terminal ? data.origin_terminal : 'NA'} </p>
                                                                            </div>
                                                                        </div>
                                                                        <div className="col-auto mx-auto">
                                                                            <Schedule /> {Math.floor(data.duration / 60)}h {data.duration % 60}m

                                                                        </div>
                                                                        <div className="col-auto">
                                                                            <div className="tire1 mb-2">
                                                                                <p>{data.destination_city_name} </p>
                                                                                <h5 className='m-0'>{data.destination_city_code} <b>{format(parseISO(data.arr_time), 'hh:mm a')}</b></h5>
                                                                                <p>  {format(parseISO(data.arr_time), 'E, dd MMM')}  </p>
                                                                                <p>{data.destination_airport_name} </p>
                                                                                <p>Terminal {data.destination_terminal ? data.destination_terminal : '-'} </p>
                                                                            </div>
                                                                        </div>

                                                                        <div className="col-12 bag">
                                                                            <p><Luggage /> Cabin: {data.cabin_baggage}  <span className='dot'></span> Check-in: {data.baggage}  </p>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            }
                                                        </>


                                                    )}
                                                </div>

                                            }


                                            <div className="summery_box">
                                                <h4>Passengers Details</h4>
                                                <table className="table">
                                                    <tbody>
                                                        {FlightDetails?.Passangers?.map((data, id) => (
                                                            <tr key={id}>
                                                                <td>
                                                                    Passenger {id + 1} - {data?.title} {data?.first_name} {data?.last_name}
                                                                </td>
                                                                <td>
                                                                    {formatDistanceStrict(new Date(data?.date_of_birth), new Date(), { unit: 'year' })}
                                                                    | {data?.gender === 1 ? 'Male' : 'Female'} | {data?.country_name}
                                                                </td>
                                                            </tr>
                                                        ))}
                                                    </tbody>
                                                </table>

                                                <hr />

                                                <p>Your ticket will be sent to <b>{FlightDetails?.Passangers?.[0]?.email} / {FlightDetails?.Passangers?.[0]?.contact_no}</b></p>
                                            </div>

                                        </div>
                                    </div>




                                </div>
                                <div className="col-md-4">
                                    <div className="Layer_Box_Two">
                                        <div className="box px-3">


                                            <div className="layer_two">
                                                <h4 className='mb-0 lh-n'>Fare Summary</h4>
                                                <hr />
                                                <div className="passanger_group">
                                                    <table className='table  text-white rounded-3'>
                                                        <tbody>
                                                            <tr>
                                                                <th>Description</th>
                                                                <th className='text-end'>Amount</th>
                                                            </tr>
                                                            <tr>
                                                                <td>Total Base Fare </td>
                                                                <td className='text-end'>₹ {Math.round(FlightDetails?.DisplayFare?.total_base_fare).toLocaleString()}</td>
                                                            </tr>
                                                            <tr>
                                                                <td>Total Taxes </td>
                                                                <td className='text-end'>₹ {(FlightDetails?.DisplayFare?.total_taxes).toLocaleString()}</td>
                                                            </tr>
                                                            <tr>
                                                                <td><b>Total Airfare</b></td>
                                                                <td className='text-end'><b>₹ {Math.round(FlightDetails?.DisplayFare?.total_airfare).toLocaleString()}</b></td>
                                                            </tr>
                                                            <tr>
                                                                <td>Convenience Fee</td>
                                                                <td className='text-end'> ₹ {(FlightDetails?.DisplayFare?.total_convenience_fee).toLocaleString()}</td>
                                                            </tr>





                                                        </tbody>
                                                        {/* <tfoot>
                                                <tr className='h5'>
                                                    <th>Grand Total</th>
                                                    <th className='text-end'>₹ {(FlightDetails?.DisplayFare?.grand_total).toLocaleString()}</th>
                                                </tr>
                                            </tfoot> */}
                                                    </table>
                                                </div>


                                                <div className="final_pay_layer border-bottom-0">
                                                    <div className="d-flex justify-content-between align-items-center">
                                                        <div className="left">
                                                            Grand Total
                                                        </div>
                                                        <div className="right">
                                                            <td>₹ {Math.round(FlightDetails?.DisplayFare?.grand_total).toLocaleString()}</td>

                                                        </div>
                                                    </div>
                                                </div>


                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="col-md-8 pb-5 text-end">
                                    <div>


                                        <form action={`${API_BASE_LINK}transact`} method='post' className="">
                                            <input type='hidden' value={window.location.host} name='request_origin' />
                                            <input type='hidden' value={window.location.protocol} name='request_protocol' />
                                            <table className='justHide'>
                                                <tbody>
                                                    <tr>
                                                        <td colSpan="2" align="center" valign="middle"></td>
                                                    </tr>
                                                    <tr>
                                                        <td align="right" valign="middle">Merchant Identifier</td>
                                                        <td align="center" valign="middle"><input type="text" name="merchantIdentifier" value="b429f75c45f14c56b2c99f321e9f8304" /></td>
                                                    </tr>
                                                    <tr>
                                                        <td align="right" valign="middle">Order Id</td>
                                                        <td align="center" valign="middle"><input type="text" id="orderId" name="orderId" value={ORDER_ATTR.order_id} /></td>
                                                    </tr>
                                                    <tr>
                                                        <td align="right" valign="middle">Return Url</td>
                                                        <td align="center" valign="middle"><input type="text" name="returnUrl" value={`${API_BASE_LINK}response`}   /></td>
                                                    </tr>
                                                    <tr>
                                                        <td align="right" valign="middle">Amount In Rupess</td>
                                                        <td align="center" valign="middle"><input type="text" name="amount" id="amount" value={ORDER_ATTR.amount} /> </td>
                                                    </tr>
                                                    <tr>
                                                        <td align="right" valign="middle">Buyer Email</td>
                                                        <td align="center" valign="middle"><input type="text" name="buyerEmail" value={FlightDetails?.Passangers?.[0]?.email} /> </td>
                                                    </tr>
                                                    <tr>
                                                        <td align="right" valign="middle">Buyer First Name</td>
                                                        <td align="center" valign="middle"><input type="text" name="buyerFirstName" value="" /> </td>
                                                    </tr>
                                                    <tr>
                                                        <td align="right" valign="middle">Buyer Last Name</td>
                                                        <td align="center" valign="middle"><input type="text" name="buyerLastName" value="" /> </td>
                                                    </tr>
                                                    <tr>
                                                        <td align="right" valign="middle">Buyer Address</td>
                                                        <td align="center" valign="middle"><input type="text" name="buyerAddress" value="" /> </td>
                                                    </tr>
                                                    <tr>
                                                        <td align="right" valign="middle">Buyer City</td>
                                                        <td align="center" valign="middle"><input type="text" name="buyerCity" value="" /></td>
                                                    </tr>
                                                    <tr>
                                                        <td align="right" valign="middle">Buyer State</td>
                                                        <td align="center" valign="middle"><input type="text" name="buyerState" value="" /></td>
                                                    </tr>
                                                    <tr>
                                                        <td align="right" valign="middle">Buyer Country</td>
                                                        <td align="center" valign="middle"><input type="text" name="buyerCountry" value="" /> </td>
                                                    </tr>
                                                    <tr>
                                                        <td align="right" valign="middle">Buyer Pincode</td>
                                                        <td align="center" valign="middle"><input type="text" name="buyerPincode" value="" /> </td>
                                                    </tr>
                                                    <tr>
                                                        <td align="right" valign="middle">Buyer Phone No</td>
                                                        <td align="center" valign="middle"><input type="text" name="buyerPhoneNumber" value="" /></td>
                                                    </tr>
                                                    <tr>
                                                        <td align="right" valign="middle">Txntype</td>
                                                        <td align="center" valign="middle"><input type="text" name="txnType" value="" /></td>
                                                    </tr>
                                                    <tr>
                                                        <td align="right" valign="middle">Zppayoption</td>
                                                        <td align="center" valign="middle"><input type="text" name="zpPayOption" value="" /></td>
                                                    </tr>
                                                    <tr>
                                                        <td align="right" valign="middle">Mode</td>
                                                        <td align="center" valign="middle"><input type="text" name="mode" value="" /> </td>
                                                    </tr>
                                                    <tr>
                                                        <td align="right" valign="middle">Currency</td>
                                                        <td align="center" valign="middle"><input type="text" name="currency" value="INR" /></td>
                                                    </tr>
                                                    <tr>
                                                        <td align="right" valign="middle">IPaddress</td>
                                                        <td align="center" valign="middle"><input type="text" name="merchantIpAddress" value="" /> </td>
                                                    </tr>
                                                    <tr>
                                                        <td align="right" valign="middle">Purpose</td>
                                                        <td align="center" valign="middle"><input type="text" name="purpose" value="" /></td>
                                                    </tr>
                                                    <tr>
                                                        <td align="right" valign="middle">Product Description</td>
                                                        <td align="center" valign="middle"><input type="text" name="productDescription" value={pageParams?.id} /> </td>
                                                    </tr>


                                                </tbody>
                                            </table>
                                            <div className="pt-4 pt-md-0 pe-3" >
                                            <button className='btn cust_btn_one ' type='submit'>proceed to pay</button>
                                            </div>
                                            {/* <a className='btn cust_btn_one' href='/booking-successfull'>proceed to pay</a> */}
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                </>
            }





        </main>
    );
}

export default CartPage;
