import React, { useEffect, useState } from 'react'
import { FormControl, IconButton, InputAdornment, InputLabel, TextField } from "@mui/material";
import { useNavigate, Link, useParams } from 'react-router-dom';
import AttachEmail from '@mui/icons-material/AttachEmail';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import { API_BASE_LINK } from "../API_BASE_PATH";
import axios from "axios";
import { Close, Done, Lock } from '@material-ui/icons';

const darkTheme = createTheme({
    palette: {
        mode: 'dark',
    },
});
const isEmail = (email) =>
    /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(email);


export default function ResetPassWord() {
    const pageParams = useParams();
    const [SendEmail, setSendEmail] = useState(false);
    const [SetEmail, setSetEmail] = useState('');
    const [EnterValidEmail, setEnterValidEmail] = useState('');
    const [NewPassword, setNewPassword] = useState('');
    const [ReEnterPassword, setReEnterPassword] = useState('');



    const navigate = useNavigate();
    const handelEmailSuccessfully = () => {
        // const regex = /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
        console.log(SetEmail);
        if (ReEnterPassword !== '' && ReEnterPassword === NewPassword) {
            const url = new URL(window.location.href);
            const token = url.searchParams.get('token');
            console.log(token);


            axios.post(`${API_BASE_LINK}users/resetPassword`, {
                "token": token,
                "new_password": ReEnterPassword
            }).then(function (response) {
                console.log('----------------------', response);
                if (response.status === 200) {
                    setSendEmail(true);
                }
            }).catch(function (error) {
                console.log(error?.response);
                alert('Request Time Out. Your link is expired. Try again.');
                navigate('/forgot-password')
                // alert('Request Time Out. Try again' JSON.stringify(error?.response?.data?.error))
            });
        }
    }

    useEffect(() => {
        if (sessionStorage.getItem("islogin") === 'true') {
            navigate('/')
        }
        // console.log(pageParams)



    }, []);
    return (

        <main className='BOOKING_PAGE_MAIN_WRAP'>

            <ThemeProvider theme={darkTheme}>

                <div className="common_banner  ">
                    <div className="container">
                        <div className="row">
                            <div className="col-auto">
                                <div className="iconBox"><span><img src="/img/ico13.svg" alt="" /></span></div>
                            </div>
                            <div className="col">
                                <h3>Reset Password</h3>
                                <p className="mb-0">
                                    To reset your password, please enter your navchr.com registered email ID.
                                    navchr.com will send the password reset instructions to the email address for this account.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="login_wraper py-5">


                    <div className="container py-5">


                        <div className="row">
                            <div className="col-md-7 mx-auto">
                                <div className="login_inner">
                                    <div className="text-center mb-5">
                                        <h3>Set New Password</h3>
                                        <p>Enter your new password below and click "Set Password" button. <br />    we'll set your new password.</p>
                                    </div>

                                    {SendEmail ?
                                        <div className="row">
                                            <div className="col-12">
                                                <h4 className='text-center mb-5'>We have success set your <b className="text-dark">New Password</b>. <br /> Back to login page and try to login with new password. </h4>
                                            </div>
                                            <div className="col-12">

                                                <div className="row justify-content-between align-items-center">
                                                    <div className="col-auto">
                                                        <div className="loginBtn">
                                                            <Link to='/login' className="btn cust_btn_one border-0">
                                                                <Lock /> &#160; Login Now
                                                            </Link>
                                                        </div>
                                                    </div>
                                                    <div className="col-auto">
                                                        Back to <Link to="/login"> <b>Login</b></Link>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        :
                                        <div className="row">
                                            <div className="col-md-12 mb-4">
                                                <TextField id="outlined-basic" label="Enter password"
                                                    autoComplete='false'
                                                    type="password"
                                                    required
                                                    variant="outlined" className="w-100" onChange={(e) => setNewPassword(e.target.value)} />

                                                {NewPassword !== '' && NewPassword.length < 6 && <p className='text-error pt-2 mb-0'>
                                                    New Password required minimum 6 characters.
                                                </p>}
                                            </div>
                                            <div className="col-md-12 mb-4">
                                                <TextField id="outlined-basic" label="Re-enter password"
                                                    autoComplete='false'
                                                    type="password"
                                                    required
                                                    variant="outlined" className="w-100" onChange={(e) => setReEnterPassword(e.target.value)} />

                                                {ReEnterPassword !== '' && ReEnterPassword !== NewPassword && <p className='text-error pt-2 mb-0'>
                                                    <Close /> New Password and Re-enter password not match.
                                                </p>}
                                                {ReEnterPassword !== '' && ReEnterPassword === NewPassword && <p className='text-error pt-2 mb-0'>
                                                    <Done /> New Password and Re-enter password are matched.
                                                </p>}
                                            </div>


                                            <div className="col-12">
                                                <div className="row justify-content-between align-items-center">
                                                    <div className="col-auto">
                                                        <div className="loginBtn">
                                                            <button className="btn cust_btn_one border-0"
                                                                onClick={() => handelEmailSuccessfully()}>
                                                                <Lock /> &#160; Set Password
                                                            </button>
                                                        </div>
                                                    </div>
                                                    <div className="col-auto">
                                                        Remember your <Link to="/login"> <b>password ?</b></Link>
                                                    </div>
                                                </div>
                                            </div>

                                        </div>
                                    }
                                    <div className="col-12 text-center pt-5">
                                        <hr />
                                        Don’t have an account? <Link to='/register'><b>Join navChr</b></Link>
                                    </div>
                                </div>
                            </div>
                        </div>


                    </div>
                </div>

            </ThemeProvider>
        </main >
    )
}
