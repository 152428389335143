import React from 'react';
import { Link, NavLink } from 'react-router-dom';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Slide from '@mui/material/Slide';
import { FormControl, TextField } from '@mui/material';
import axios from 'axios';
import { API_BASE_LINK } from '../API_BASE_PATH';
import { format } from 'date-fns/esm';
import { Nightlight } from '@mui/icons-material';
const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});
const ALL_NAV_LINKS = [
    { name: 'Login', link: '/login' },
    { name: 'Booking Policy', link: '/booking-policy' },
    { name: 'About Us', link: '/about' },
    { name: 'Cancel Policy', link: '/cancellation-policy' },
    { name: 'Privacy Policy', link: '/privacy-policy' },
    { name: 'Refund Policy', link: '/refund-policy' },
    { name: 'Cookies Policy', link: '/cookie-policy' },
    { name: 'Terms & Conditions', link: '/terms-and-conditions' },
    { name: 'Help & Support ', link: '/contact' },
    { name: 'User Agreement', link: '/user-agreement' },
    { name: 'Contact Us', link: '/contact' },
    { name: 'News', link: '/news' },
]
const Footer = () => {
    const [open, setOpen] = React.useState(false);
    const [IsVerifyed, setIsVerifyed] = React.useState(false);
    const [OtpValidation, setOtpValidation] = React.useState(false);
    const [OtpValidationData, setOtpValidationData] = React.useState({
        mobile_number: null,
        otp: null
    });


    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const verify_otp = () => {
        console.log(OtpValidationData);
        const otp_regex = /^\D*([2-9]\d{2})\D*\d{3}\D*\d{4}\D*$/;

        if (OtpValidationData.mobile_number === null) {
            setOtpValidation('Please enter 10 digit mobile number and 6 digit OTP');
        } else if (!otp_regex.test(OtpValidationData.mobile_number)) {
            setOtpValidation('Please enter valid mobile number and OTP.  ');
        } else {
            setOtpValidation('');
            axios.post(`${API_BASE_LINK}users/verifyPhone`, {
                "phone_number": OtpValidationData.mobile_number,
                "otp": OtpValidationData.otp
            })
                .then(function (response) {
                    console.log(response);
                    if (response.status === 200) {
                        setIsVerifyed(true)
                    }
                })
                .catch(function (error) {
                    console.log(error);
                    setOtpValidation('Please enter valid OTP');
                });
        }
    }

    const tryDark = () => {
        document.body.classList.add('dark2')
    }
    return (
        <>
            <footer className='MAIN_FOOTER'>
                <section className='top_footer'>
                    <div className="container">
                        <div className="row  ">
                            <div className="col-lg-3 align-self-center text-center text-lg-start">
                                <div className="col-4 col-sm-4 col-lg-8  mx-auto mx-lg-0">
                                    <Link to='/' >
                                        <img src="/img/logo.svg" alt="" className='w-100' />

                                    </Link>
                                </div>
                                <div className="pt-3">
                                    <p>
                                        Your satisfaction is our moto. We bring to you all defence person air fares on one platforms.
                                    </p>
                                </div>
                                <hr className='d-lg-none mb-0' />
                            </div>
                            <div className="col-lg-4 py-5 py-lg-0" onClick={() => window.scrollTo(0, 0)}>
                                <h3>Quick Links</h3>
                                <ul className="list-unstyled row mb-0">
                                    {ALL_NAV_LINKS.map((data, ind) => (
                                        <li key={ind} className="col-6">
                                            <NavLink to={data.link}>{data.name}</NavLink>
                                        </li>
                                    ))}

                                    {/* <li className="col-md-6">
                                        <a className='cp' onClick={handleClickOpen}>Verify your account</a>
                                    </li> */}
                                    <li>
                                        <a className='cp' onClick={() => tryDark()}>Try Dark <Nightlight /></a>
                                    </li>
                                </ul>
                            </div>
                            <div className="col-sm col-lg-3">
                                <h3>Office Address</h3>
                                <p>
                                    Navchr Online <br />
                                    Plot No - 10, Baniya ka Bagh <br />
                                    Jaipur Bye Paas, Alwar <br />
                                    Rajasthan-301001
                                </p>

                                <p className='mb-0'>

                                    Email : support@navchr.com <br />
                                    Mob : +91 9336111631 <br />(09:00am - 05:00pm)
                                </p>
                            </div>
                            <div className="col-sm-auto col-lg-2 pt-5 pt-sm-0">
                                <h3>Payment & Security</h3>
                                <figure className='d-block'>
                                    <img src="/img/10.svg" alt="" />
                                </figure>
                                <figure className='col-7 col-sm-12 d-block px-0'>
                                    <img src="/img/iata-preview.png" alt="" className='w-100 darkImg' />
                                </figure>
                            </div>
                        </div>
                    </div>
                </section>
                <section className='last_footer'>
                    <div className="container  ">
                        <div className="row justify-content-between align-items-center">
                            <div className="col-sm-auto">
                                <p className='mb-3 mb-sm-0 text-center text-sm-start '>All Rights Reserved navChr.com  {format(new Date(), 'yyyy')}</p>
                            </div>
                            <div className="col-sm-auto">
                                <ul className="row justify-content-center list-unstyled m-0">
                                    <li className='col-auto'>
                                        <a href="https://www.youtube.com/channel/UCZkcKrcOTM0BI0dGZ4mAoGg" target='_blank'>
                                            <img src="/img/i1.svg" alt="" />
                                        </a>
                                    </li>
                                    <li className='col-auto'>
                                        <a href="https://www.facebook.com/?sk=welcome" target='_blank'>
                                            <img src="/img/i2.svg" alt="" />
                                        </a>
                                    </li>
                                    <li className='col-auto'>
                                        <a href="https://twitter.com/navchr" target='_blank'>
                                            <img src="/img/i3.svg" alt="" />
                                        </a>
                                    </li>
                                    <li className='col-auto'>
                                        <a href="https://www.linkedin.com/in/navchr-online-489196233/" target='_blank'>
                                            <img src="/img/i4.svg" alt="" />
                                        </a>
                                    </li>
                                    <li className='col-auto'>
                                        <a href="https://www.instagram.com/navchr/" target='_blank'>
                                            <img src="/img/i5.svg" alt="" />
                                        </a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </section>
            </footer>

            <Dialog
                open={open}
                TransitionComponent={Transition}
                keepMounted
                onClose={handleClose}
                aria-describedby="alert-dialog-slide-description"
            >

                <div className="py-5">
                    <div className="text-center">
                        {IsVerifyed === true ?
                            <h4 className="text-center">Congratulations! <br /> Your account is <span className='  ' style={{ color: '#1400ff' }}>verified</span>.</h4>
                            :
                            <h4>Verify your account.</h4>
                        }
                    </div>

                    <DialogContent>
                        {/* <DialogContentText id="alert-dialog-slide-description"> */}
                        <div className='pt-4'>
                            {IsVerifyed === false && <><p className='text-center'>Please enter OTP for verify your account. <br /> You will get 6 digit OTP in this <b>{OtpValidationData.mobile_number}</b> mobile number.</p>
                                <div className="row mx-0 pt-4" >
                                    <div className="col-md-6">
                                        <FormControl fullWidth>
                                            {/* <div className='label' >City</div> */}
                                            <TextField placeholder='Enter mobile number' variant="outlined" onChange={(e) => { setOtpValidationData({ ...OtpValidationData, mobile_number: e.target.value }) }} />
                                        </FormControl>
                                    </div>
                                    <div className="col-md-6">
                                        <FormControl fullWidth>
                                            {/* <div className='label' >City</div> */}
                                            <TextField placeholder='Enter 6 digit OTP' variant="outlined" onChange={(e) => { setOtpValidationData({ ...OtpValidationData, otp: e.target.value }) }} />
                                        </FormControl>
                                    </div>
                                    <div className="col-auto mx-auto pt-4">
                                        <button className="cust_btn_one" onClick={verify_otp}>
                                            Verify Otp
                                        </button>
                                    </div>
                                </div></>}

                            {OtpValidation !== '' && <p className='text-start ps-3 pt-2 text-danger'>{OtpValidation}</p>}
                            {IsVerifyed === true &&
                                <>
                                    <div className="text-center">
                                        <figure><img src="/img/1.gif" alt="" style={{ filter: 'hue-rotate(82deg)' }} className='w-50' /></figure>
                                        <h5 className='text-success mb-0'>✓ Phone number  successfully verified.</h5>
                                    </div>
                                </>
                            }
                        </div>
                        {/* </DialogContentText> */}
                    </DialogContent>
                </div>

            </Dialog>

        </>
    );
}

export default Footer;
