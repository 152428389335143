import {
  BrowserRouter,
  Route,
  Routes,
  useNavigate,
} from "react-router-dom";
import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import BookingPage from "./Pages/BookingPage";
import BookingSuccessfullPage from "./Pages/BookingSuccessfullPage";
import CartPage from "./Pages/CartPage";
import FlightSearchPage from "./Pages/FlightSearchPage";
import HomePage from './Pages/HomePage';
import LoginPage from "./Pages/LoginPage";
import MyAccountPage from "./Pages/MyAccountPage";
import NotFoundPage from "./Pages/NotFoundPage";
import PaymentProcessPage from "./Pages/PaymentProcessPage";
import RegisterPage from "./Pages/RegisterPage";
// import TemplateOne from "./Pages/TemplateOne";
import Footer from "./Slices/Footer";
import Header from "./Slices/Header";
import TermsAndConditions from "./Pages/TermsAndConditions";
import BookingPolicy from "./Pages/BookingPolicy";
import Cancellation_Policy from "./Pages/Cancellation_Policy";
import PrivacyPolicy from "./Pages/PrivacyPolicy";
import Refunds_Policy from "./Pages/Refunds_Policy";
import UserAgreement from "./Pages/UserAgreement";
import AboutUs from "./Pages/AboutUs";
import ContactUs from "./Pages/ContactUs";
import TicketDetails from "./Slices/TicketDetails";
import CookiePolicy from "./Pages/CookiePolicy";
import { ExpandLess } from "@material-ui/icons";
import NewsPage from "./Pages/NewsPage";
import OfflinePage from "./Pages/OfflinePage";
import ForgotPassWord from "./Pages/ForgotPassWord";
import ResetPassWord from "./Pages/reset";

function App() {


  return (
    <div className="App">
       <BrowserRouter>
        <ScrollToTop />
        <Header />
        <Routes>
          <Route path="/" element={<HomePage />} />
          <Route path="/search/:id" element={<FlightSearchPage />} />
          <Route path="/booking/:id" element={<BookingPage />} />
          <Route path="/booking-cart/:id" element={<CartPage />} />
          <Route path="/payment-status/:id" element={<PaymentProcessPage />} />
          <Route path="/booking-successfull/:id" element={<BookingSuccessfullPage />} />
          <Route path="/login" element={<LoginPage />} />
          <Route path="/forgot-password" element={<ForgotPassWord />} />
          <Route path="/register" element={<RegisterPage />} />
          <Route path="/reset" element={<ResetPassWord />} />
          <Route path="/my-account/:id" element={<MyAccountPage />} />
          <Route path="/ticket-details/:id" element={<TicketDetails />} />

          <Route path="/terms-and-conditions" element={<TermsAndConditions />} />
          <Route path="/cookie-policy" element={<CookiePolicy />} />
          <Route path="/booking-policy" element={<BookingPolicy />} />
          <Route path="/cancellation-policy" element={<Cancellation_Policy />} />
          <Route path="/privacy-policy" element={<PrivacyPolicy />} />
          <Route path="/refund-policy" element={<Refunds_Policy />} />
          <Route path="/user-agreement" element={<UserAgreement />} />
          <Route path="/about" element={<AboutUs />} />
          <Route path="/contact" element={<ContactUs />} />
          <Route path="/news" element={<NewsPage />} />
          <Route path="/not-internet" element={<OfflinePage />} />


          <Route path="*" element={<NotFoundPage />} />
        </Routes>
        <Footer />
      </BrowserRouter>
      <BtnScrollToTop />
    </div>

  );
}

export default App;


const ScrollToTop = () => {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return null;
};




const BtnScrollToTop = () => {
  const [S_Top_Val, setS_Top_Val] = useState(0);
  useEffect(() => {
    window.onscroll = () => {
      setS_Top_Val(window.scrollY);
    }
  })

  return (
    <>
      {S_Top_Val > 100 &&
        <div className="scrollTopBtn shadow-lg fadeIn" onClick={()=>window.scrollTo(0,0)}>
          <ExpandLess />
        </div>
      }
    </>
  )

}