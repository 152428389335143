import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import { FormControl } from '@mui/material';

import TextField from '@mui/material/TextField';
import { IconButton, InputAdornment, OutlinedInput } from "@mui/material";
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import Login from '@mui/icons-material/Login';
import { Link } from "react-router-dom";
import axios from "axios";
import { API_BASE_LINK } from "../API_BASE_PATH";
const darkTheme = createTheme({
    palette: {
        mode: 'dark',
    },
});
const LoginPage = () => {
    const navigate = useNavigate();
    const [ValiDate, setValiDate] = useState('');
    const [LoginFormData, setLoginFormData] = useState({
        email: '',
        password: ''
    });

    const [values, setValues] = useState({
        showPassword: false,
    });
    const handleClickShowPassword = () => {
        setValues({
            ...values,
            showPassword: !values.showPassword,
        });
    };
    const handleMouseDownPassword = (event) => {
        event.preventDefault();
    };

    useEffect(() => {
        if (sessionStorage.getItem("islogin") === 'true') {
            navigate(-1);

            // history.goBack();
        }
    }, []);




    const FinalLoginPayload = () => {
        // console.log(Object.keys(LoginFormData).length )
        let data;
        if (LoginFormData.email == '') {
            setValiDate('Please enter email address');
        } else if (LoginFormData.password == '') {
            setValiDate('Please enter password');
        } else {
            setValiDate('Login...');

            axios.post(`${API_BASE_LINK}users/login`, {
                "email_id": LoginFormData.email,
                "password": LoginFormData.password
            })
                .then(function (response) {
                    console.log(response);

                    // let loginSuccessData = {
                    //     loginStatus: response.status,
                    //     prefix: response.data.user.prefix,
                    //     F_name: response.data.user.first_name,
                    //     L_name: response.data.user.last_name,
                    //     mobile: response.data.user.mobile_number,
                    //     email: response.data.user.email_id,
                    // }
                    if (response.status === 200) {
                        sessionStorage.setItem('islogin', 'true');
                        // navigate("/");
                        sessionStorage.setItem('LoginUserDetails', JSON.stringify(response.data));
                        window.location.reload();
                    }
                })
                .catch(function (error) {
                    console.log(error.response.status);
                    if (error?.response?.status === 404) {
                        setValiDate('Invalid Username or Password')
                    }else if (error?.response?.status === 403) {
                        setValiDate('Phone number not verified')
                    }  else {
                        setValiDate(error.response.message)
                    }
                });
        }

    }

    // const test = () => {
    //     navigate("/");
    // }

    return (
        <main className='BOOKING_PAGE_MAIN_WRAP'>



            <div className="common_banner mb-5">
                <div className="container">
                    <div className="row">
                        <div className="col-auto">
                            <div className="iconBox"><span><img src="/img/ico13.svg" alt="" /></span></div>
                        </div>
                        <div className="col">
                            <h4>Login</h4>
                            <p className="mb-0">
                            This login is for navchr users.
                             </p>
                        </div>
                    </div>
                </div>
            </div>
            <div className="pt-4"></div>
            <section className="login_wraper">
                <div className="container">
                    <div className="row justify-content-center" >
                        <div className="col-lg-6">
                            <div className='login_inner'>
                                <div className="custBg">
                                    <div className="text-center mb-4">
                                        <h3>Welcome Back to  navChr</h3>
                                        <p>Login with Your Email id and Password</p>
                                    </div>
                                    <hr />
                                    <div className="pt-3"></div>
                                    <ThemeProvider theme={darkTheme}>
                                        {ValiDate !== '' && <div class="alert alert-danger" role="alert">
                                            <div>   {ValiDate}</div>
                                        </div>}
                                        <div className="row">
                                            <div className="col-md-12 mb-4">
                                                <div className="label">Email ID</div>
                                                <TextField
                                                    id="email_address"
                                                    variant="outlined"
                                                    className="w-100"
                                                    InputProps={{ sx: { height: '3rem' } }}
                                                    value={LoginFormData.email}
                                                    onChange={(e) => setLoginFormData({ ...LoginFormData, email: e.target.value })}
                                                />
                                            </div>
                                            <div className="col-md-12 mb-4">

                                                <FormControl variant="outlined" className="w-100">
                                                    <div className="label">Password</div>
                                                    <OutlinedInput
                                                        // InputProps={{ sx: { height: '3rem' } }}
                                                        style={{ height: '3rem' }}
                                                        id="outlined-adornment-password"
                                                        type={values.showPassword ? 'text' : 'password'}
                                                        value={LoginFormData.password}
                                                        onChange={(e) => setLoginFormData({ ...LoginFormData, password: e.target.value })}
                                                        endAdornment={
                                                            <InputAdornment position="end">
                                                                <IconButton
                                                                    aria-label="toggle password visibility"
                                                                    onClick={handleClickShowPassword}
                                                                    onMouseDown={handleMouseDownPassword}
                                                                    edge="end"
                                                                >
                                                                    {values.showPassword ? <VisibilityOff /> : <Visibility />}
                                                                </IconButton>
                                                            </InputAdornment>
                                                        }

                                                    />
                                                </FormControl>
                                            </div>

                                            <div className="col-12">
                                                <div className="row justify-content-between align-items-center">
                                                    <div className="col-auto">
                                                        <div className="loginBtn">
                                                            <button className="btn cust_btn_one border-0" onClick={FinalLoginPayload}>
                                                                <Login /> &#160; Login
                                                            </button>
                                                        </div>
                                                    </div>
                                                    <div className="col-auto">
                                                        Forgot your
                                                        <Link to="/forgot-password"> <u>password</u> ?</Link>
                                                    </div>
                                                </div>
                                            </div>
                                          
                                            <div className="col-12 text-center pt-5">
                                            <hr />
                                                <h6> Don’t have an account? <Link to='/register'><u>Join navChr</u></Link></h6>
                                            </div>
                                        </div>

                                    </ThemeProvider>
                                </div>
                            </div>

                            <div>

                            </div>


                        </div>

                    </div>
                </div>
            </section>



        </main>
    );
}

export default LoginPage;


// https://dev.to/codebucks/form-validation-in-reactjs-by-building-reusable-custom-hook-1bg7