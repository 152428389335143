import { format } from "date-fns"

const DDMMMYYYYHHMM = (data) => {
    return format(new Date(data), 'dd MMM, yyyy  HH:mm');
}

const HHMM = (data) => {
    return format(new Date(data), 'HH:mm');
}
const DDMMM = (data) => {
    return format(new Date(data), 'dd MMM');
}
const DDMMMYYYY = (data) => {
    return format(new Date(data), 'dd MMM, yyyy');
}



export { DDMMMYYYYHHMM, HHMM, DDMMM,DDMMMYYYY };