import React from 'react'

export default function BookingPolicy() {
    return (
        <div className='tc_cms_pages_wrapper  py-5 animate__animated animate__fadeIn'>

            <div className="container">

                <div className='main'>
                    <h1><span>BOOKING POLICY</span></h1>
                    <h3>Rules :</h3>
                    <p>The total price displayed includes(18%) all applicable government taxes (GSTIN).</p>
                    <p>You are required to pay the entire amount prior to the confirmation of your booking.</p>
                    <p>There will be no refund for no-shows or any partially unused flights.</p>
                    <p>To avail infant fares, an infant must be under 02 Years throughout the entire itinerary you are booking. This includes both onward and return journeys. If the infant is 02 Years or above on the return journey, you'll need to make a separate booking using a child fare.
                        Infants must be accompanied by an adult at least 18 years of age.</p>
                    <p>All tickets/ reservations issued to the customer shall additionally be governed under the terms and conditions as laid out by the respective Airlines / Supplier.</p>
                    <p>If any causes after online payment the flight booking ticket is not create in your device and email. May I request you please wait 24 hr and after contact to customer care and mail to us.</p>
                    <p>Travel Insurance not to be provide to any traveller up to till date and travel Insurance charge not be include in any flight booking for the any user.</p>
                    <p>The passenger is carrying their luggage in the rules/guidelines from the airlines company. In the case the passenger carries his extra luggage, cost of extra luggage must be paid to airlines company for the directions of the concerned company. </p>
                    <p><b>Please note </b>: airline company may not allow rescheduling of defence fare ticket and instead require rebooking new ticket for date/ flight changes. Please contact the airline company customer care for more information and details.</p>
                    <p>Navchr online is not responsible for any delay or cancellation of flights from airline end. Customer is recommended to get their ticket copy stamped at the airport to get full refund in such cases. Convenience fees incl GSTIN not to be refundable from the navchr online.</p>

                    <h3>Applicable Booking Charges for user :</h3>
                    <p><b>Please Note</b>: All Navchr online users are informed that, convenience fees of all defence Soldiers Rs 128.00/- (Rupees One Twenty Eight only) incl 18% GSTIN and convenience fees for all others person Rs 148.00/- (Rupees One Hundred Forty Eight only) incl 18% GSTIN may be apply. This amount not to be refundable all users. Transition charge 2% may be apply when you pay to airlines reservation if you used RUPAY DEBIT Card, UPI transition charge (2%) not to be applied, if you used other payment mode transition charge (2%) may be applied, you used all type International Debit/ Cradit card transition charge (4%) may be apply.</p>

                    <h3>Check-in :</h3>
                    <p>As per the airline rules, the standard check-in time begins 2 hours before departure for domestic flights.</p>
                    <p>The passenger needs to check-in at least 2 hrs prior departure for Air India and Air India Express and all domestic flights, else will be considered as a no show.</p>
                    <p>Check-in closes airlines in airport 65 minutes before from the flight Departure.</p>
                    <p>Parents are requested Infants must have carried valid proof of age documents showing that the infant is under two years old.</p>
                    <p>Additional payment may be charged by the airline for seat allocations when you chose your prime seat.</p>
                    <p>Defence person must be carrying their defence Id Card who enter traveller details systems. All other person carries their valid Government issue Id card when he/she travel in airline. If Infants are travel in the airlines this time age prove document of the Infants may be carry.</p>
                    <p>All passengers/ users are request that unauthorised items not to be carry when he/she travel in airlines.</p>
                    <p>Passenger are informed that if you used any extra convince by the airlines, extra amount will be paid to airlines by the guide lines of airlines company. </p>
                    <p><b>Note</b>: All pregnant woman passenger are requested please follow the guideline of airlines company when she travel in the airlines. If any pregnancy cases in the airlines when she travel, navchr online not to be responsible.</p>

                    <h3>Modify of Booking :</h3>
                    <p>Every booking made on navchr online is subject to alteration charges levied by the airline, which may vary by flight and booking class. The charges levied by Airlines are Non-Refundable in case of cancellation post amendment.</p>
                    <p>Depending on the airline's policy, some booked fares may not be amended.</p>
                    <p>All bookings made through navchr online, can be amended by calling up the Customer Care Support of the airline directly.</p>
                    <p>Navchr online may charge a small fee if amendments are made through us.</p>
                    <p>If any causes after online payment the flight booking ticket is not create in your device and email. May I request you please wait 24 hr and after contact to customer care via email and mobile no.</p>
                    <p>Travel Insurance not to be provide to any traveller up to till date and travel Insurance charge not be include in any flight booking.</p>
                </div>
            </div>
        </div>
    )
}
