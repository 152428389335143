import { WifiOff } from '@material-ui/icons'
import React, { useEffect } from 'react'
import { useNavigate } from 'react-router-dom';

export default function OfflinePage() {
    const navigator = useNavigate();

    useEffect(() => {
        if (window.navigator.onLine === true) {
            navigator(-1)
        }
    }, []);
    return (
        <main>
            <section className='tc_cms_pages_wrapper  py-5  '>
                <div className="container">
                    <div className="row">
                        <div className="col-md-6 mx-auto text-center">
                            <div className="main">
                                <h1>Can't connect</h1>
                                <div className='py-4'>
                                    <WifiOff style={{ fontSize: '4rem' }} />
                                </div>
                                <p>
                                    Please check your internet connection. <br /> You need an internet connection to use this app.
                                </p>
                                <div className="pt-3"></div>
                                <button className="btn cust_btn_one" onClick={() => window.location.reload()}>
                                    Retry
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </main>
    )
}
