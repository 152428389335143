import { CircularProgress } from '@mui/material'
import React, { useEffect, useState } from 'react';
import Pusher from 'pusher-js'
import { useWebSocket } from 'react-use-websocket/dist/lib/use-websocket';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import { API_BASE_LINK } from '../API_BASE_PATH';
import { format, parseISO } from 'date-fns';
import StatusPageFlightInfo from '../Slices/StatusPageFlightInfo';
import { CheckCircle } from '@mui/icons-material';
const socket = require('react-use-websocket')

let pusher, channel;
export default function PaymentProcessPage() {
    const pageParams = useParams();
    let [BookingDetails, setBookingDetails] = useState(null);
    const [AllInfo, setAllInfo] = useState(null);

    const [IsPaymentFailed, setIsPaymentFailed] = useState(null);

    function setupPusher() {
        Pusher.logToConsole = true;
        pusher = new Pusher('5e0f8dfb7d1ab8d12d6c', {
            cluster: 'ap2'
        })
        channel = pusher.subscribe('ticket-booking')
        console.log("Subscribing to update-" + pageParams.id)
        channel.bind('update-' + pageParams.id, data => {
            processMessage(data);
        })
    }
    // async function loadBookingDetails() {
    //     // let response = axios.pos//axios get data;/booking/{id}
    //     // processMessage(response.data)
    // }




    function processMessage(data) {
        let payload = data;
        console.log('------',)
        console.log('------', payload);
        console.log('------', payload?.FlightStatuses);
        setAllInfo(payload)

        if (payload?.FlightStatuses[0]?.type === 'PAYMENT_RECEIVED') {
            setIsPaymentFailed()
        }
        for (let i = 0; i < payload?.FlightStatuses.length; i++) {
            setIsPaymentFailed(payload?.FlightStatuses[i].type);
            console.log('---setIsPayment status---', payload?.FlightStatuses);

        }
    }
    useEffect(() => {
        // loadBookingDetails();
        setupPusher()
        return () => {
            channel.unsubscribe()
        }
    }, []);


    useEffect(() => {
        axios.get(`${API_BASE_LINK}flight/details/${pageParams.id}`)
            .then(function (response) {
                console.log(response);
                if (response?.status === 200) {
                    setBookingDetails(response?.data);
                    // if (response?.data?.pnr === null) {
                    //     // setIsPaymentFailed(true);
                    //     setIsPaymentFailed('TICKET_FAIL');
                    // } else if (response?.data?.pnr !== null) {
                    //     setIsPaymentFailed("TICKET_CONFIRMED");
                    // }
                }
            })
            .catch(function (error) {
                console.log(error);
            });

    }, []);


    useEffect(() => {
        setTimeout(() => {
            axios.get(`${API_BASE_LINK}flight/details/${pageParams.id}`)
                .then(function (response) {
                    console.log(response);
                    if (response?.status === 200) {
                        if (response?.data?.pnr === null) {
                            setIsPaymentFailed('TICKET_FAIL');
                        } else if (response?.data?.pnr !== null) {
                            setIsPaymentFailed("TICKET_CONFIRMED");
                        }
                    }
                })
                .catch(function (error) {
                    // handle error
                    console.log(error);
                });

        }, 10000);
    }, []);

    return (
        <>
            <main className='BOOKING_PAGE_MAIN_WRAP'>


                <div className="common_banner mb-4">
                    <div className="container">
                        <div className="row align-items-center">
                            <div className="col-auto">
                                <div className="iconBox"><span><img src="/img/ico1.svg" alt="" /></span></div>
                            </div>
                            <div className="col">
                                <h4>Payment Status</h4>
                                {/* <p className="mb-0">
                                    Lorem ipsum dolor sit amet consectetur adipisicing elit.
                                </p> */}
                            </div>
                        </div>
                    </div>
                </div>
                <div className="pt-4"></div>

                <section className="Payment_Summery_Main_Wrap">
                    <div className="container">

                        {IsPaymentFailed === null &&
                            <>
                                {AllInfo !== null ?
                                    <div className="MainLayerOne mb-5">
                                        <div className="row">
                                            <div className="col-md-8">
                                                <div className="paymentStatusCard">
                                                    <div className="row">
                                                        <div className="col-12">
                                                            <h5>Payment Pending</h5>
                                                            <hr />
                                                        </div>

                                                        <div className="col-12 pt-3">
                                                            <div className="row">
                                                                <div className="col-auto">
                                                                    <div className="statusBox">
                                                                        <div className="l1">
                                                                            <span className='done'>
                                                                                {AllInfo?.FlightStatuses[0] ? <CheckCircle className='final' /> : <CircularProgress color="secondary" />}
                                                                            </span>
                                                                        </div>
                                                                        <div className="l2">
                                                                            Payment {AllInfo?.FlightStatuses[0] ? 'Recieved' : 'Pending'}
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className="col p-0"><hr /></div>
                                                                <div className="col-auto">
                                                                    <div className="statusBox">
                                                                        <div className="l1">
                                                                            <span className='done'>
                                                                                {AllInfo?.FlightStatuses[1] ? <CheckCircle className='final' /> : <CircularProgress color="secondary" />}
                                                                            </span>
                                                                        </div>
                                                                        <div className="l2">
                                                                            Booking  {AllInfo?.FlightStatuses[1] ? 'Confirm' : 'Pending'}
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className="col p-0"><hr /></div>

                                                                <div className="col-auto">
                                                                    <div className="statusBox">
                                                                        <div className="l1">
                                                                            <span className='done'>
                                                                                {AllInfo?.FlightStatuses[2] ? <CheckCircle className='final' /> : <CircularProgress color="secondary" />}
                                                                            </span>                                                            </div>
                                                                        <div className="l2">
                                                                            Ticket sent  {AllInfo?.FlightStatuses[2] ? 'on Email/SMS' : 'Pending'}
                                                                        </div>
                                                                    </div>
                                                                </div>

                                                            </div>
                                                        </div>

                                                        <div className="col-12">
                                                            <hr />
                                                        </div>
                                                        <div className="col-12">
                                                            <p className='m-0'>
                                                                Darn! It's taking longer than usual to confirm your payment. We'll keep trying for next 30 mins. If we are unable to confirm your payment then we'll initiate full refund shortly.
                                                            </p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-md-4">
                                                <div className="bookIdCard h-100">
                                                    <div className="l1 mb-3"><b>Booking ID:</b> {AllInfo?.booking_id}</div>
                                                    <div className="l1"><b>Booked on:</b>{AllInfo?.createdAt && format(parseISO(AllInfo?.createdAt), 'EEE, dd MMM yyyy hh:mm a')}</div>

                                                </div>
                                            </div>

                                        </div>
                                    </div>
                                    :
                                    <h5 className='pb-5 text-center'><CircularProgress color="primary" /> <br /> Payment status loading ...  <br /> Please stay with us.</h5>
                                }
                            </>
                        }

                        {IsPaymentFailed === 'NOTIFICATION_FAIL' || IsPaymentFailed === 'TICKET_FAIL' && <>
                            <div className="bg-danger p-5 text-center rounded-3 shadow-lg mb-5">
                                The ticket was generate failed. If money was deducted , money will refund in 3 working days.
                            </div>
                        </>}
                        {IsPaymentFailed === "NOTIFICATION_SENT" || IsPaymentFailed === 'TICKET_CONFIRMED' && <>
                            <div className="bg-success p-5 text-center rounded-3 shadow-lg mb-5">
                                The ticket was generate successfully. Ticket send to this <b>{BookingDetails?.Passangers[0]?.email}</b>.
                            </div>
                        </>}



                        {IsPaymentFailed !== null &&

                            <div className="MainLayerTwo   pt-5">
                                <div className="row">
                                    <div className="col-md-8">

                                        {BookingDetails !== null && <>
                                            <StatusPageFlightInfo
                                                sendBookData={{
                                                    Segments: BookingDetails?.Segments?.filter((data) => { return data?.result_index === BookingDetails?.flight_index }),
                                                    Passangers: BookingDetails?.Passangers
                                                }} />
                                            {BookingDetails?.return_date !== null &&
                                                <StatusPageFlightInfo
                                                    sendBookData={{
                                                        Segments: BookingDetails?.Segments?.filter((data) => { return data?.result_index === BookingDetails?.return_flight_index }),
                                                        Passangers: BookingDetails?.Passangers
                                                    }} />
                                            }

                                        </>}
                                    </div>
                                    <div className="col-md-4">
                                        <div className="PaymentSummery">
                                            <h5>Payment Summery</h5>
                                            <hr />

                                            <table className='table  text-white rounded-3'>
                                                <tbody>
                                                    <tr>
                                                        <th>Description</th>
                                                        <th className='text-end'>Amount</th>
                                                    </tr>
                                                    <tr>
                                                        <td>Total Base Fare </td>
                                                        <td className='text-end'>₹ {(BookingDetails?.DisplayFare?.total_base_fare).toLocaleString()}</td>
                                                    </tr>
                                                    <tr>
                                                        <td>Total Taxes </td>
                                                        <td className='text-end'>₹ {(BookingDetails?.DisplayFare?.total_taxes).toLocaleString()}</td>
                                                    </tr>
                                                    <tr>
                                                        <td><b>Total Airfare</b></td>
                                                        <td className='text-end'><b>₹ {(BookingDetails?.DisplayFare?.total_airfare).toLocaleString()}</b></td>
                                                    </tr>
                                                    <tr>
                                                        <td>Convenience Fee</td>
                                                        <td className='text-end'> ₹ {(BookingDetails?.DisplayFare?.total_convenience_fee).toLocaleString()}</td>
                                                    </tr>





                                                </tbody>
                                                <tfoot>
                                                    <tr className='h5'>
                                                        <th>Grand Total</th>
                                                        <th className='text-end'>₹ {(BookingDetails?.DisplayFare?.grand_total).toLocaleString()}</th>
                                                    </tr>
                                                </tfoot>
                                            </table>

                                        </div>
                                    </div>
                                </div>
                            </div>
                        }

                    </div>
                </section>
            </main>
        </>
    )
}
